<template>
    <b-container>
        <b-card class="trip mt-4 mb-4">
            <standard-table ref="myTable"
                            :items="items"
                            :fields="getFields()"
                            :theadTrClass="'first-2-table-cols'"
                            id="stops"
                            :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                            :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                            :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                            :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                            :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                            :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                            :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"

                            fullWidth="true"
                            noContainerPadding="true"
                            @rowInfo="showRowInfo"
                            @rowEdit="pushToEdit"
                            @rowDelete="deleteItem">

                <b-container slot="row-before-search" class="mw-100 p-0">
                    <b-row align-h="end">
                        <b-col cols="auto">
                            <b-button v-on:click="pushToEdit()" class="mb-3 b-c-blue" id="add-trip">
                                {{$t('message.add')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-per-page" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                            <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button" @click="$refs.myTable.excel($tc('message.trip', 2))">Excel</b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-search" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                            <b-button v-on:click="resetFilters()" class="b-c-green mb-2 mr-2" id="reset-filters">
                                {{$t('message.resetFilters')}}
                            </b-button>

                            <b-button @click="showCollapse = !showCollapse" :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                                <span v-show="!showCollapse" class="when-opened">{{$t('message.show')}} {{$tc('message.filter', 2).toLowerCase()}}</span>
                                <span v-show="showCollapse" class="when-closed">{{$t('message.hide')}} {{$tc('message.filter', 2).toLowerCase()}}</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="after-search" class="mw-100 p-0">
                    <b-collapse id="my-collapse" v-model="showCollapse">
                        <b-container class="mw-100 m-0 p-0">
                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                                    <b-form-group :label="`${$tc('message.tripStatus', 1)}`">
                                        <v-select :options="statusOptions"
                                                  label="text"
                                                  :reduce="obj => obj.value"
                                                  v-model="statusFilter">
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-collapse>
                </b-container>
            </standard-table>
        </b-card>

        <b-modal ref="infoModal" id="infoModal" :title="`${$tc('message.details', 1)}`">
            <b-container>
                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.name', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.nome || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.description', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.descrizione || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.enabled', 0)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.attiva ? $tc('message.yes', 1) : $tc('message.no', 1)}}</h6>
                        </div>
                    </b-col>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok </b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'

  export default {
    name: 'Trip',
    created () {
      this.fetchData()
    },
    updated () {
      this.$root.$emit('checkExcel')
    },
    watch: {
      statusFilter (obj) {
        this.$refs.myTable.resetSearch()
        this.getItems(obj)
      }
    },
    data () {
      return {
        associationOptions: [],
        associationFilter: null,
        statusOptions: [],
        statusFilter: true,
        showCollapse: false,
        items: [],
        originalItems: [],
        infoItem: {},
        users: []
      }
    },
    methods: {
      fetchData () {
        this.$root.$emit('activeLoader', true)
        this.getOptions().then(() => {
          this.getTrip()
        })
      },
      getOptions () {
        const axiosArray = []
        axiosArray.push(this.getStatusOptions())
        return Vue.myAll(axiosArray)
      },
      getStatusOptions () {
        this.statusOptions = [
          { value: true, text: this.$tc('message.enabled', 0) },
          { value: false, text: this.$tc('message.disabled', 0) }
        ]
      },
      getTrip () {
        this.items = []
        this.originalItems = []
        Vue.myGet(this, '/bus/gita?all=true', 'gite').then((trips) => {
          const editedItems = []
          trips.forEach(trip => {
            const editedTrip = trip
            editedTrip.activeText = trip.attiva ? this.$tc('message.yes', 1) : this.$tc('message.no', 1)
            editedItems.push(editedTrip)
          })
          this.items = _.orderBy(editedItems, 'nome')
          this.originalItems = _.cloneDeep(this.items)
          this.$root.$emit('activeLoader', false)
          this.items = _.filter(this.items, obj => obj.attiva === this.statusFilter)
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      },
      getFields (isInfo) {
        return [
          { key: 'edit', label: '', hideInTable: isInfo },
          { key: 'delete', label: '', hideInTable: isInfo },
          { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'descrizione', label: `${this.$tc('message.description', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'activeText', label: `${this.$tc('message.enabled', 0)}`, sortable: true, sortDirection: 'desc' }
        ]
      },
      showRowInfo (event) {
        this.infoItem = event && event.item
        if (this.$refs.infoModal) this.$refs.infoModal.show()
      },
      pushToEdit (event) {
        const item = event && event.item
        item && item.id ? this.$router.push({
          name: 'EditTrip',
          params: { id: item.id }
        }) : this.$router.push({
          name: 'EditTrip',
          params: { id: 'new' }
        })
      },
      deleteItem (event) {
        const item = event && event.item
        if (item && item.id) {
          this.$root.$emit('deleteItem', { description: item.nome, path: `/bus/gita/${item.id}`, redirect: this.$route.path })
        }
      },
      resetFilters () {
        this.statusFilter = null
        this.associationFilter = null
        this.$refs.myTable.resetSearch()
      },
      getItems (status) {
        let editedItems = _.cloneDeep(this.originalItems)
        if (status !== null) editedItems = _.filter(editedItems, obj => obj.attiva === status)
        this.items = editedItems
      }
    }
  }
</script>
