<template>
    <b-container class="mw-100 child">
        <b-card class="mt-4 mb-4">
            <standard-table ref="mainTable"
                            :items="items"
                            :fields="fields"
                            :theadTrClass="'first-2-table-cols'"

                            :before-search="{cols: 12,sm: 12,md: 12,lg: 12,xl: 12}"

                            :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                            :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                            :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                            :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                            :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                            :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                            :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"

                            fullWidth="true"
                            noContainerPadding="true"
                            @rowInfo="showRowInfo"
                            @rowEdit="pushToEdit"
                            @rowDelete="deleteItem">
                <b-container slot="before-search" class="mw-100 p-0 mb-2">
                    <b-row align-h="end">
                        <b-col cols="auto">
                            <b-button @click="showImportModal">
                                {{$tc('message.importChildren', 1)}}
                            </b-button>
                        </b-col>

                        <b-col cols="auto">
                            <b-button @click="showExportModal()">
                                {{$tc('message.exportChildren', 1)}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-before-search" class="mw-100 p-0">
                    <b-row align-h="end">
                        <b-col cols="auto">
                            <b-button v-on:click="pushToEdit()" class="mb-3 b-c-blue" id="add-child">
                                {{$t('message.add')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-per-page" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                            <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button" @click="$refs.mainTable.excel($tc('message.child', 2), getFields())">Excel</b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-search" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                            <b-button v-on:click="resetFilters()" class="b-c-green mb-2 mr-2" id="reset-filters">
                                {{$t('message.resetFilters')}}
                            </b-button>

                            <b-button @click="showCollapse = !showCollapse" :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                                <span v-show="!showCollapse" class="when-opened">{{$t('message.show')}} {{$tc('message.filter', 2).toLowerCase()}}</span>
                                <span v-show="showCollapse" class="when-closed">{{$t('message.hide')}} {{$tc('message.filter', 2).toLowerCase()}}</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="after-search" class="mw-100 p-0">
                    <b-collapse id="my-collapse" v-model="showCollapse">
                        <b-container class="mw-100 m-0 p-0">
                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                                    <!-- Structure -->
                                    <b-form-group :label="`${$tc('message.structure', 1)}`">
                                        <v-select :options="structureOptions"
                                                  :reduce="obj => obj.id"
                                                  label="den_sintetica"
                                                  @input="getSectionOptions(sectionOptions, $event, true)"
                                                  v-model="structureFilter">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                                    <!-- Section -->
                                    <b-form-group :label="`${$tc('message.section', 1)}`">
                                        <v-select :options="getSectionOptions(sectionOptions, structureFilter)"
                                                  :reduce="obj => obj.id"
                                                  ref="sectionFilter"
                                                  label="descrizione"
                                                  :disabled="!structureFilter"
                                                  v-model="sectionFilter">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                                    <b-form-group :label="`${$tc('message.discharge', 1)} ${$tc('message.from', 1).toLowerCase()}`">
                                        <b-input-group>
                                            <flat-pickr id="discharge-start"
                                                        ref="dischargeStart"
                                                        v-model="dischargeFilterStart"
                                                        :config="dischargeConfigStart">
                                            </flat-pickr>

                                            <div class="input-group-append">
                                                <b-button class="fas fa-trash"
                                                          @click="dischargeFilterStart = null"
                                                          :disabled="!dischargeFilterStart">
                                                </b-button>
                                            </div>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                                    <b-form-group :label="`${$tc('message.discharge', 1)} ${$tc('message.to', 1).toLowerCase()}`">
                                        <b-input-group>
                                            <flat-pickr id="discharge-end"
                                                        ref="dischargeEnd"
                                                        v-model="dischargeFilterEnd"
                                                        :config="dischargeConfigEnd">
                                            </flat-pickr>

                                            <div class="input-group-append">
                                                <b-button class="fas fa-trash"
                                                          @click="dischargeFilterEnd = null"
                                                          :disabled="!dischargeFilterEnd">
                                                </b-button>
                                            </div>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-collapse>
                </b-container>
            </standard-table>
        </b-card>

        <b-modal ref="infoModal" id="infoModal" :title="`${$tc('message.details', 1)}`" size="lg">
            <b-container>
                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.name', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.nome || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.surname', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.cognome || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.taxCode', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.codice_fiscale || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.birthDate', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.data_nascita || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.commonResidence', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.comune_residenza || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.provinceResidence', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.provincia_residenza || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.residenceAddress', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.indirizzo_residenza || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.parent1', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.genitore_1 || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.firstTaxCode', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.codice_fiscale_r1 || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.phone', 1)}} 1</b></h6>
                            <h6 class="ml-2">{{infoItem.telefono_principale || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.email', 1)}} 1</b></h6>
                            <h6 class="ml-2">{{infoItem.email_ufficiale || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.parent2', 1)}} 1</b></h6>
                            <h6 class="ml-2">{{infoItem.genitore_2 || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.otherTaxCode', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.codice_fiscale_r2 || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.phone', 1)}} 2</b></h6>
                            <h6 class="ml-2">{{infoItem.telefono_secondario || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.email', 1)}} 2</b></h6>
                            <h6 class="ml-2">{{infoItem.email_secondaria || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.dateStart', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.startDate || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.discharge', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.endDateText || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.section', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.section || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.year', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.year || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.structure', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.structure || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.calendar', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.calendar || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok </b-button>
            </div>
        </b-modal>

        <b-modal ref="exportModal" id="exportModal" :title="`${$tc('message.exportChildren', 1)}`" size="lg"
                 no-close-on-backdrop no-close-on-esc hide-header-close
                 @hide="checkExportModalClosing">
            <standard-table ref="exportTable"
                            :theadTrClass="'first-table-cols'"
                            :items="exportItems"
                            :fields="getFields(exportModalFields)"
                            :disableAutoUnSelection="true"

                            :before-search="{cols: 12,sm: 12,md: 12,lg: 12,xl: 12}"

                            :row-search="{cols: 12,sm: 12,md: 12,lg: 6,xl: 6}"
                            :row-after-search="{cols: 12, sm: 12, md: 12, lg: 6, xl: 6}"

                            :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                            :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 4, xl: 4}"
                            :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 3}"
                            @rowSelected="handleExportSelection"
                            @allSelected="handleExportSelection"

                            fullWidth="true"
                            noContainerPadding="true"
                            @mounted="updateExportItems">
            </standard-table>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button type="button"
                          class="m-1"
                          @click="exportChildren(exportItems)"> {{$tc('message.export', 1)}}
                </b-button>

                <b-button type="button"
                          class="m-1"
                          @click="$refs.exportModal.hide('button')"> {{$tc('message.close', 1)}}
                </b-button>
            </div>
        </b-modal>

        <b-modal ref="importModal" id="importModal" :title="`${$tc('message.importChildren', 1)}`">
            <b-container>
                <b-row>
                    <b-col>
                        <!-- Document -->
                        <b-form-group label="Excel:">
                            <b-form-file v-model="childImportFile"
                                         id="import-children-file"
                                         ref="importChildrenFile"
                                         accept=".xlsx, .xls"
                                         v-on:input="childrenFileImported"
                                         :placeholder="$tc('message.selectFile', 1)">
                            </b-form-file>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <h6 class="text-danger">{{$tc('warnings.editStructureChildImport', 1)}}</h6>
                    </b-col>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button class="b-c-green m-1"
                          v-on:click="startImport(childImportFile)"
                          :disabled="!enableImport || !childImportFile"> {{$t('message.import')}}
                </b-button>

                <b-button type="button"
                          class="m-1"
                          href="./ModelloImport.xlsx"
                          :target="'_blanck'" download> {{$tc('message.modelDownload', 1)}}
                </b-button>

                <b-button type="button"
                          class="m-1"
                          @click="$refs.importModal.hide()"> {{$tc('message.close', 1)}}
                </b-button>
            </div>
        </b-modal>

        <b-modal ref="importModalErrors" id="importModalErrors" :title="`${$t('message.caution')}!`"
                 @shown="errorsAreShown" size="lg"
                 no-close-on-backdrop no-close-on-esc hide-header-close>
            <b-container fluid>
                <b-row>
                    <b-col>
                        <h6 class="text-danger">{{$tc('warnings.editStructureChildImport', 1)}}</h6>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col>
                        <h4>{{$t('errors.errorsList')}}:</h4>
                    </b-col>
                </b-row>

                <b-row>
                    <b-container>
                        <b-list-group>
                            <b-list-group-item v-for="(item, index) in importErrors" :key="index">
                                <b-row>
                                    <b-col cols="12" sm="12" v-if="item.child">
                                        <h6 class="c-red font-weight-bold">{{$tc('message.row', 1)}}: {{item.child.index}}</h6>
                                        <h6 class="c-red font-weight-bold">{{item.child.cognome}} {{item.child.nome}} {{item.child.codice_fiscale ? `- ${item.child.codice_fiscale}` : ''}}</h6>
                                        <h6 class="c-red font-weight-bold">{{item.child.structureText ? `- ${item.child.structureText}` : ''}}</h6>
                                    </b-col>

                                    <b-col cols="12" sm="12">
                                        <div v-for="message in item.errors" :key="message">
                                            <h6 class="c-red font-italic">- {{message}} </h6>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-list-group-item>
                        </b-list-group>
                    </b-container>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button id="nw-ok-error-message" class="m-1 b-c-green" @click="hideMainErrorMessageModal()">Ok</b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import { DateTime } from 'luxon'
  import _, { isNil } from 'lodash'
  import { Italian } from 'flatpickr/dist/l10n/it.js'
  import axios from 'axios'

  export default {
    created () {
      this.fetchData()
    },
    watch: {
      structureFilter (val) {
        this.getItems(val, this.sectionFilter, this.dischargeFilterStart, this.dischargeFilterEnd)
      },
      sectionFilter (val) {
        this.getItems(this.structureFilter, val, this.dischargeFilterStart, this.dischargeFilterEnd)
      },
      dischargeFilterStart (val) {
        this.getItems(this.structureFilter, this.sectionFilter, val, this.dischargeFilterEnd)
      },
      dischargeFilterEnd (val) {
        this.getItems(this.structureFilter, this.sectionFilter, this.dischargeFilterStart, val)
      }
    },
    name: 'Child',
    data () {
      const yearId = Vue.getLocalStorage('year')
      const generalSettings = {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: null,
        maxDate: null,
        disable: []
      }
      return {
        yearId,
        originalItems: [],
        items: [],
        allStructureChildren: [],
        fields: [],
        mainTableCheckSizeFields: ['data_nascita', 'genitore_1', 'section', 'startDate', 'endDateText'],
        mainTableFields: ['info', 'edit', 'nome', 'cognome', 'telefono_principale', 'structure'],
        exportModalFields: ['select', 'nome', 'cognome', 'structure', 'section'],
        showCollapse: false,
        calendarOptions: [],
        sectionOptions: [],
        sectionFilter: null,
        structureOptions: [],
        structureFilter: null,
        yearOptions: [],
        dischargeConfigStart: _.cloneDeep(generalSettings),
        dischargeConfigEnd: _.cloneDeep(generalSettings),
        dischargeFilterStart: null,
        dischargeFilterEnd: null,
        infoItem: {},
        exportItems: [],
        childImportFile: null,
        enableImport: false,
        importErrors: []
      }
    },
    methods: {
      fetchData () {
        this.$root.$emit('activeLoader', true)
        this.getOptions().then(() => {
          this.getStructureChild(this.yearId).then(() => {
            this.$root.$emit('activeLoader', false)
          })
        })
      },
      getOptions () {
        const axiosArray = []
        this.checkFields()
        axiosArray.push(this.getYears())
        axiosArray.push(this.getStructures())
        axiosArray.push(this.getCalendars())
        axiosArray.push(this.getSections())
        return Vue.myAll(axiosArray)
      },
      getYears () {
        return Vue.myGet(this, '/anno_scolastico', 'anni').then(years => {
          this.yearOptions = years
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getStructures () {
        return Vue.myGet(this, '/struttura', 'strutture').then(structures => {
          this.structureOptions = structures
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getCalendars () {
        return Vue.myGet(this, `/calendario?anno=${this.yearId}`, 'calendari').then(calendars => {
          this.calendarOptions = calendars
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getSections () {
        return Vue.myGet(this, '/sezione', 'sezioni').then(sections => {
          this.sectionOptions = sections
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getStructureChild (yearId) {
        const editedItems = []
        return Vue.myGet(this, '/bambino', 'bambini').then(children => {
          return Vue.myGet(this, `/struttura_bambino?anno=${yearId}`, 'associazioni').then(structureChildren => {
            children.forEach(child => {
              if (!isNil(_.find(structureChildren, obj => obj.id_bambino === child.id))) {
                const editedChild = child
                const structureChild = _.filter(structureChildren, obj => obj.id_bambino === child.id)
                let activeAssociation = null

                if (structureChild && structureChild.length !== 0) {
                  structureChild.forEach(association => {
                    if (activeAssociation && activeAssociation.data_cancellazione) {
                      activeAssociation = DateTime.fromISO(association.data_iscrizione) > DateTime.fromISO(activeAssociation.data_iscrizione) ? association : activeAssociation
                    } else if (!activeAssociation || !activeAssociation.data_cancellazione) {
                      activeAssociation = association
                    }
                  })
                }

                const foundedYear = activeAssociation ? _.find(this.yearOptions, obj => obj.id === activeAssociation.id_anno_scolastico) : null
                const foundedStructure = activeAssociation ? _.find(this.structureOptions, obj => obj.id === activeAssociation.id_struttura) : null
                const foundedCalendar = activeAssociation ? _.find(this.calendarOptions, obj => obj.id === activeAssociation.id_calendario) : null
                const foundedSection = activeAssociation ? _.find(this.sectionOptions, obj => obj.id === activeAssociation.id_sezione) : null

                editedChild.id = child.id
                editedChild.structureChildId = activeAssociation ? activeAssociation.id : null
                editedChild.year = (foundedYear && foundedYear.descrizione) || null
                editedChild.structure = (foundedStructure && foundedStructure.den_sintetica) || null
                editedChild.calendar = (foundedCalendar && foundedCalendar.descrizione) || null
                editedChild.section = (foundedSection && foundedSection.descrizione) || null
                editedChild.yearId = (foundedYear && foundedYear.id) || null
                editedChild.structureId = (foundedStructure && foundedStructure.id) || null
                editedChild.calendarId = (foundedCalendar && foundedCalendar.id) || null
                editedChild.sectionId = (foundedSection && foundedSection.id) || null
                editedChild.data_nascita = DateTime.fromSQL(child.data_nascita).toFormat('dd-MM-y')
                editedChild.startDate = activeAssociation && activeAssociation.data_iscrizione
                    ? DateTime.fromISO(activeAssociation.data_iscrizione).toFormat('dd-MM-y') : null
                editedChild.endDateText = activeAssociation && activeAssociation.data_cancellazione
                    ? DateTime.fromISO(activeAssociation.data_cancellazione).toFormat('dd-MM-y') : null
                editedChild.endDate = (activeAssociation && activeAssociation.data_cancellazione) || null

                editedItems.push(editedChild)
              }
            })
            this.originalItems = editedItems
            this.items = _.cloneDeep(editedItems)
            this.allStructureChildren = structureChildren
            return true
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },

      // table functions
      checkFields () {
        this.checkMainTableFields()
        this.$root.$on('resize', () => this.checkMainTableFields())
      },
      checkMainTableFields () {
        let size = Vue.myGetWindowSize()
        const hideBySize = size === 'sm' || size === 'md' || size === 'lg'
        const fields = !hideBySize ? _.union(this.mainTableCheckSizeFields, this.mainTableFields) : this.mainTableFields
        this.fields = this.getFields(fields, true)
      },
      getFields (fieldsToShow, hideOther) {
        const fields = [
          { key: 'select', label: '' },
          { key: 'info', label: '' },
          { key: 'edit', label: '' },
          { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'cognome', label: `${this.$tc('message.surname', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'codice_fiscale', label: `${this.$tc('message.taxCode', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'data_nascita', label: `${this.$tc('message.birthDate', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'indirizzo_residenza', label: `${this.$tc('message.residenceAddress', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'comune_residenza', label: `${this.$tc('message.commonResidence', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'provincia_residenza', label: `${this.$tc('message.provinceResidence', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'telefono_principale', label: `${this.$tc('message.officialPhone', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'telefono_secondario', label: `${this.$tc('message.secondaryPhone', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'genitore_1', label: `${this.$tc('message.parent1', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'genitore_2', label: `${this.$tc('message.parent2', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'codice_fiscale_r1', label: `${this.$tc('message.firstTaxCode', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'codice_fiscale_r2', label: `${this.$tc('message.otherTaxCode', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'email_ufficiale', label: `${this.$tc('message.email', 1)} 1`, sortable: true, sortDirection: 'desc' },
          { key: 'email_secondaria', label: `${this.$tc('message.email', 1)} 2`, sortable: true, sortDirection: 'desc' },
          { key: 'year', label: `${this.$tc('message.year', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'structure', label: `${this.$tc('message.structure', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'calendar', label: `${this.$tc('message.calendar', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'section', label: `${this.$tc('message.section', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'startDate', label: `${this.$tc('message.dateStart', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'endDateText', label: `${this.$tc('message.discharge', 1)}`, sortable: true, sortDirection: 'desc' }
        ]
        if (!fieldsToShow || fieldsToShow.length === 0) return fields
        const editedFields = []
        fields.forEach(field => {
          const foundedField = _.find(fieldsToShow, obj => obj === field.key)
          if (foundedField) {
            editedFields.push(field)
          } else if (hideOther) {
            const editedField = _.cloneDeep(field)
            editedField.hideInTable = true
            editedFields.push(editedField)
          }
        })
        return editedFields
      },
      showRowInfo (event) {
        this.infoItem = event && event.item
        this.$refs.infoModal.show()
      },
      pushToEdit (event) {
        const item = event && event.item
        item && item.id ? this.$router.push({
          name: 'EditChild',
          params: { id: item.id }
        }) : this.$router.push({
          name: 'EditChild',
          params: { id: 'new' }
        })
      },
      deleteItem (event) {
        const item = event && event.item
        if (item && item.id) {
          this.$root.$emit('deleteItem', { description: `${item.cognome} ${item.nome}`, path: `/struttura_bambino/${item.id}`, redirect: this.$route.path })
        }
      },
      getSectionOptions (originalItems, structure, reset) {
        if (this.$refs.sectionFilter && reset) this.$refs.sectionFilter.clearSelection()
        return _.filter(_.cloneDeep(originalItems), obj => obj.id_struttura === structure)
      },
      getItems (structure, section, start, end) {
        let currentYear = _.find(this.yearOptions, obj => obj.id === this.yearId)
        let editedItems = _.cloneDeep(this.originalItems)

        if (structure) editedItems = _.filter(editedItems, obj => obj.structureId === structure)
        if (section) editedItems = _.filter(editedItems, obj => obj.sectionId === section)
        if (start) editedItems = _.filter(editedItems, obj => obj.endDate ? DateTime.fromISO(obj.endDate) >= DateTime.fromSQL(start) : false)
        if (end) editedItems = _.filter(editedItems, obj => obj.endDate ? DateTime.fromISO(obj.endDate) <= DateTime.fromSQL(end) : false)

        currentYear = currentYear && currentYear.anno ? parseInt(currentYear.anno) : DateTime.local().year

        this.dischargeConfigStart.minDate = `${currentYear}-09-01`
        this.dischargeConfigEnd.minDate = start || `${currentYear}-09-01`
        this.dischargeConfigStart.maxDate = end || `${currentYear + 1}-08-31`
        this.dischargeConfigEnd.maxDate = `${currentYear + 1}-08-31`

        this.items = editedItems
      },
      resetFilters () {
        this.structureFilter = null
        this.sectionFilter = null
        this.dischargeFilterStart = null
        this.dischargeFilterEnd = null
        this.$refs.mainTable.resetSearch()
      },

      // export functions
      showExportModal () {
        if (this.$refs && this.$refs.exportModal) this.$refs.exportModal.show()
      },
      checkExportModalClosing (event) {
        if (event.trigger !== 'button') {
          event.preventDefault()
          this.exportItems = []
        }
      },
      updateExportItems () {
        this.exportItems = _.cloneDeep(this.originalItems)
      },
      handleExportSelection (event) {
        const changedItems = Array.isArray(event) ? event : [event]
        changedItems.forEach(item => {
          const foundedItem = _.find(this.exportItems, obj => obj.id === item.id)
          foundedItem.selected = item.selected
        })
      },
      exportChildren (items) {
        const editedItems = []
        items.forEach(item => {
          if (item.selected) editedItems.push(item)
        })
        Vue.excel(this.getFields(), editedItems, this.$tc('message.exportChildren', 1))
      },

      // import functions
      childrenFileImported (file) {
        if (file && file.name) {
          const xls = _.endsWith(file.name, 'xls')
          const xlsx = _.endsWith(file.name, 'xlsx')
          const ods = _.endsWith(file.name, 'ods')
          if (xls || xlsx || ods) {
            this.enableImport = true
          } else {
            Vue.warning(this, this.$tc('warnings.onlyExcel', 1))
            this.enableImport = false
            if (this.$refs.importChildrenFile) this.$refs.importChildrenFile.reset()
          }
        }
      },
      startImport (file) {
        this.$root.$emit('activeLoader', true)
        new Promise((resolve) => {
          resolve(Vue.parseExcel(file))
        }).then((result) => {
          const fieldsSeed = [
            'codice_fiscale',
            'cognome',
            'nome',
            'data_nascita',
            'indirizzo_residenza',
            'comune_residenza',
            'provincia_residenza',
            'telefono_principale',
            'genitore_1',
            'codice_fiscale_r1',
            'genitore_2',
            'codice_fiscale_r2',
            'email_ufficiale',
            'email_secondaria',
            'telefono_secondario',
            'section',
            'structure',
            'startDate'
          ]
          const axiosArray = []
          const templatePage = _.first(result)
          let goAhead = true

          if (_.first(templatePage) && Object.keys(_.first(templatePage)).length !== 0) {
            const allColumns = Object.keys(_.first(templatePage))
            const fields = this.getFields(fieldsSeed, true)
            let hasCurrentLang = false
            fields.forEach(field => {
              if (!hasCurrentLang) {
                hasCurrentLang = _.find(allColumns, obj => obj === field.label || obj === field.key)
              }
            })
            goAhead = hasCurrentLang

            if (!hasCurrentLang) {
              Vue.warning(this, this.$tc('warnings.fileHasDifferentLang', 1))
            }
          } else {
            goAhead = false
            Vue.warning(this, this.$tc('warnings.fileIsEmpty', 1))
          }

          if (goAhead) {
            templatePage.forEach((item) => {
              axiosArray.push(item)
            })

            axios.all(_.map(axiosArray, child => this.checkDataToImport(child))).then((results) => {
              const errorsChildren = []
              const allChildren = []
              results.forEach((result) => {
                const child = result.child
                const errors = result.errors
                const duplicateChild = _.find(allChildren, obj => obj.codice_fiscale === child.codice_fiscale)
                const index = 2 + _.indexOf(results, result)
                const editedChild = child
                editedChild.index = index
                allChildren.push(editedChild)
                if (duplicateChild) {
                  errorsChildren.push({ errors: [`${this.$tc('message.row', 1)}: ${duplicateChild.index} ${this.$tc('errors.isADuplicateOfRow', 1).toLowerCase()}: ${editedChild.index}`] })
                }
                if (errors.length !== 0) {
                  const editedErrors = []
                  errors.forEach((error) => {
                    editedErrors.push(error)
                  })
                  errorsChildren.push({ child: editedChild, errors: editedErrors })
                }
              })
              this.closeImportModal()
              if (errorsChildren.length !== 0) {
                this.openImportModalError(errorsChildren)
              } else {
                Vue.success(this, this.$t('message.importSuccess'))
                this.$root.$emit('activeLoader', false)
                this.$router.push({ name: 'RefreshPage', params: { name: 'Child' } })
              }
            })
          } else {
            this.$root.$emit('activeLoader', false)
          }
        })
      },
      checkDataToImport (child) {
        const taxCodeRegex = /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/g
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        const editedItem = {}
        const errors = []
        let currentYear = _.find(this.yearOptions, obj => obj.id === this.yearId)
        currentYear = currentYear && currentYear.anno ? parseInt(currentYear.anno) : DateTime.local().year
        const minDate = DateTime.fromSQL(`${currentYear}-09-01`)
        const dateFormat = {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }
        const maxDate = DateTime.fromSQL(`${currentYear + 1}-08-31`)

        const taxCode = child[_.first(this.getFields(['codice_fiscale'])).label] || child['codice_fiscale']
        const surname = child[_.first(this.getFields(['cognome'])).label] || child['cognome']
        const name = child[_.first(this.getFields(['nome'])).label] || child['nome']
        const birthDate = child[_.first(this.getFields(['data_nascita'])).label] || child['data_nascita']
        const residenceAddress = child[_.first(this.getFields(['indirizzo_residenza'])).label] || child['indirizzo_residenza']
        const residenceCommon = child[_.first(this.getFields(['comune_residenza'])).label] || child['comune_residenza']
        const residenceProvince = child[_.first(this.getFields(['provincia_residenza'])).label] || child['provincia_residenza']
        const mainPhone = child[_.first(this.getFields(['telefono_principale'])).label] || child['telefono_principale']
        const parent1 = child[_.first(this.getFields(['genitore_1'])).label] || child['genitore_1']
        const taxCodeParent1 = child[_.first(this.getFields(['codice_fiscale_r1'])).label] || child['codice_fiscale_r1']
        const parent2 = child[_.first(this.getFields(['genitore_2'])).label] || child['genitore_2']
        const taxCodeParent2 = child[_.first(this.getFields(['codice_fiscale_r2'])).label] || child['codice_fiscale_r2']
        const mainEmail = child[_.first(this.getFields(['email_ufficiale'])).label] || child['email_ufficiale']
        const secondaryEmail = child[_.first(this.getFields(['email_secondaria'])).label] || child['email_secondaria']
        const secondaryPhone = child[_.first(this.getFields(['telefono_secondario'])).label] || child['telefono_secondario']
        const section = child[_.first(this.getFields(['section'])).label] || child['id_sezione']
        const structure = child[_.first(this.getFields(['structure'])).label] || child['id_struttura']
        const registrationDate = child[_.first(this.getFields(['startDate'])).label] || child['data_iscrizione']

        if (!new RegExp(taxCodeRegex).test(taxCode)) errors.push(this.$t('errors.taxCode'))
        else editedItem.codice_fiscale = taxCode.toUpperCase()

        if (!surname || surname.length > 50 || _.trim(surname).length === 0) {
          errors.push(this.$t('errors.surnameLength'))
        } else editedItem.cognome = surname

        if (!name || name.length > 50 || _.trim(name).length === 0) {
          errors.push(this.$t('errors.nameLength'))
        } else editedItem.nome = name

        if (!birthDate ||
            isNaN(birthDate) ||
            !DateTime.fromFormat(new Date(Date.UTC(0, 0, birthDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy').isValid) {
          errors.push(this.$t('errors.birthDate'))
        } else {
          const date = DateTime.fromFormat(new Date(Date.UTC(0, 0, birthDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy')
          if (date >= DateTime.local()) {
            errors.push(`${this.$tc('errors.birthDateMoreThanNow')} ${birthDate} )`)
          } else if (date >= minDate) {
            errors.push(`${this.$tc('errors.birthDateMoreThanMinDate')} ${minDate.toFormat('dd/MM/yyyy')} )`)
          } else {
            editedItem.data_nascita = date.toSQLDate()
          }
        }

        if (!residenceAddress || residenceAddress.length > 50 || _.trim(residenceAddress).length === 0) {
          errors.push(this.$t('errors.residenceAddress'))
        } else editedItem.indirizzo_residenza = residenceAddress

        if (!residenceCommon || residenceCommon.length > 30 || _.trim(residenceCommon).length === 0) {
          errors.push(this.$t('errors.residenceCommon'))
        } else editedItem.comune_residenza = residenceCommon

        if (!residenceProvince || residenceProvince.length > 30 || _.trim(residenceProvince).length === 0) {
          errors.push(this.$t('errors.residenceProvince'))
        } else editedItem.provincia_residenza = residenceProvince

        if (!mainPhone || mainPhone.length > 20 || _.trim(mainPhone).length === 0 || isNaN(mainPhone)) {
          errors.push(this.$t('errors.mainPhone'))
        } else editedItem.telefono_principale = '' + parseInt(mainPhone)

        if (!parent1 || parent1.length > 50 || _.trim(parent1).length === 0) errors.push(this.$t('errors.parent1'))
        else editedItem.genitore_1 = parent1

        if (taxCodeParent1 && !new RegExp(taxCodeRegex).test(taxCodeParent1)) errors.push(this.$t('errors.taxCodeParent1'))
        else editedItem.codice_fiscale_r1 = taxCodeParent1 ? taxCodeParent1.toUpperCase() : null

        if (parent2 && parent2.length > 50) errors.push(this.$t('errors.parent2'))
        else editedItem.genitore_2 = parent2 || null

        if (taxCodeParent2 && !new RegExp(taxCodeRegex).test(taxCodeParent2)) errors.push(this.$t('errors.taxCodeParent2'))
        else editedItem.codice_fiscale_r2 = taxCodeParent2 ? taxCodeParent2.toUpperCase() : null

        if (mainEmail && (!emailRegex.test(mainEmail) || mainEmail.length > 50)) errors.push(this.$t('errors.mainEmail'))
        else editedItem.email_ufficiale = mainEmail

        if (secondaryEmail && !emailRegex.test(secondaryEmail)) errors.push(this.$t('errors.secondaryEmail'))
        else editedItem.email_secondaria = secondaryEmail || null

        if (secondaryPhone && (secondaryPhone.length > 20 || isNaN(secondaryPhone))) errors.push(this.$t('errors.secondaryPhone'))
        else editedItem.telefono_secondario = secondaryPhone ? '' + parseInt(secondaryPhone) : null

        if (!structure) {
          errors.push(this.$t('errors.structure'))
        } else {
          const foundedStructure = _.find(this.structureOptions, obj =>
              _.trim(`${obj[isNaN(structure) ? 'den_sintetica' : 'id']}`).toLowerCase() === _.trim(`${structure}`).toLowerCase())
          if (foundedStructure) {
            editedItem.id_struttura = parseInt(foundedStructure.id)
          } else {
            errors.push(`${this.$t('errors.structureNotFound')} ${structure})`)
          }
        }

        if (section) {
          const foundedSection = _.find(this.sectionOptions, obj =>
              _.trim(`${obj[isNaN(section) ? 'descrizione' : 'id']}`).toLowerCase() === _.trim(`${section}`).toLowerCase() &&
              obj.id_struttura === editedItem.id_struttura)

          if (foundedSection) {
            editedItem.id_sezione = parseInt(foundedSection.id)
          } else {
            errors.push(`${this.$t('errors.sectionNotFound')} ${section})`)
          }
        }
        if (!registrationDate || isNaN(registrationDate) || !DateTime.fromFormat(new Date(Date.UTC(0, 0, registrationDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy').isValid) {
          errors.push(this.$t('errors.registrationDate'))
        } else {
          const date = DateTime.fromFormat(new Date(Date.UTC(0, 0, registrationDate - 1)).toLocaleDateString('it-IT', dateFormat), 'dd/MM/yyyy')

          if (minDate > date || maxDate < date) {
            const message = `${this.$t('errors.currentYear1')} ${minDate.toFormat('dd/MM/yyyy')} ${this.$t('errors.currentYear2')}
            ${maxDate.toFormat('dd/MM/yyyy')}, ${this.$tc('errors.checkRegistrationDate')}`
            errors.push(message)
          } else {
            editedItem.data_iscrizione = date.toISO()
          }
        }
        if (errors.length === 0) {
          this.updateChildData(editedItem)
        }
        return { errors, child: editedItem }
      },
      updateChildData (excelItem) {
        const foundedChild = _.find(this.originalItems, obj => obj.codice_fiscale && excelItem.codice_fiscale &&
        _.trim(obj.codice_fiscale).toLowerCase() === _.trim(excelItem.codice_fiscale).toLowerCase())
        const foundedStandardCalendar = _.find(this.calendarOptions, obj => obj.id_struttura === excelItem.id_struttura && obj.standard)
        const child = _.cloneDeep(excelItem)
        delete child.id_sezione
        delete child.id_struttura
        delete child.index
        delete child.data_iscrizione
        let newStructureChild = {
          id_sezione: excelItem.id_sezione || null,
          id_struttura: excelItem.id_struttura,
          id_calendario: (foundedStandardCalendar && foundedStandardCalendar.id) || null,
          id_anno_scolastico: this.yearId,
          id_bambino: !isNil(foundedChild) ? foundedChild.id : null,
          data_iscrizione: excelItem.data_iscrizione,
          data_cancellazione: null,
          fascia_oraria: null,
          tipo_utenza: null
        }
        if (isNil(foundedStandardCalendar)) {
          return {
            errors: [this.$tc('warnings.noCalendarForSelectedStructure', 1)],
            child: excelItem
          }
        }

        if (!isNil(foundedChild)) {
          child.id = foundedChild.id
          return this.updateChild(child, excelItem).then(() => {
            return { errors: [], child: excelItem }
          }).catch(error => {
            return { errors: [Vue.manageErrors(this, error, true)], child: excelItem }
          })
        } else {
          Vue.myGet(this, '/bambino?codice_fiscale=' + child.codice_fiscale, 'bambini').then(children => {
            if (children.length > 0) {
              const thisChild = _.first(children)
              newStructureChild.id_bambino = thisChild.id
              return this.updateStructureChild(newStructureChild).then(() => {
                return { errors: [], child: excelItem }
              }).catch(error => {
                return { errors: [Vue.manageErrors(this, error, true)], child: excelItem }
              })
            } else {
              return this.updateChild(child).then((childId) => {
                newStructureChild.id_bambino = childId
                return this.updateStructureChild(newStructureChild).then(() => {
                  return { errors: [], child: excelItem }
                }).catch(error => {
                  return { errors: [Vue.manageErrors(this, error, true)], child: excelItem }
                })
              }).catch(error => {
                return { errors: [Vue.manageErrors(this, error, true)], child: excelItem }
              })
            }
          }).catch(error => {
            console.log('Exsisting child ' + error)
          })
        }
      },
      updateChild (child) {
        return Vue.patchOrPost(!!child.id, `/bambino${child.id ? '/' + child.id : ''}`, child).then(r => {
          return r.data.id
        }).catch(error => {
          throw error
        })
      },
      updateStructureChild (structureChild) {
        return Vue.patchOrPost(!!structureChild.id, `/struttura_bambino${structureChild.id ? '/' + structureChild.id : ''}`, structureChild).then((r) => {
          return r.data.id
        }).catch(error => {
          throw error
        })
      },
      showImportModal () {
        this.$refs.importModal.show()
        if (this.$refs.importChildrenFile) this.$refs.importChildrenFile.reset()
      },
      closeImportModal () {
        this.$refs.importModal.hide()
        this.childImportFile = null
      },
      openImportModalError (errors) {
        this.importErrors = errors
        this.$refs.importModalErrors.show()
      },
      errorsAreShown () {
        this.$root.$emit('activeLoader', false)
      },
      hideMainErrorMessageModal () {
        if (this.$refs.importModalErrors) this.$refs.importModalErrors.hide()
        if (!_.isNil(this.$refs.exportTable)) {
          this.$refs.exportTable.refresh()
        }
      }
    }
  }
</script>
