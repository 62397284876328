// main components
import Vue from 'vue'
import Router from 'vue-router'
import routeViews from '../router-views'

// custom plugins
import MyExcelFromDate from '@/plugins/my-excel-from-table'
import MyTableFilter from '@/plugins/my-table-filter'
import MyChangeBreadcrumb from '@/plugins/my-change-breadcrumb'
import MySelectAll from '@/plugins/my-select-all'
import MyTableSorted from '@/plugins/my-table-sorted'
import MyCheckAllSelected from '@/plugins/my-check-all-selected'
import MyGetWindowSize from '@/plugins/my-get-window-size'
import MyLocalStorage from '@/plugins/my-local-storage'
import MyServerInteraction from '@/plugins/my-server-interaction'
import MyNotify from '@/plugins/my-notify'
import MyParseExcel from '@/plugins/my-parse-excel'

// Vue installations
Vue.use(Router)
Vue.use(MyExcelFromDate)
Vue.use(MyTableFilter)
Vue.use(MyChangeBreadcrumb)
Vue.use(MySelectAll)
Vue.use(MyTableSorted)
Vue.use(MyCheckAllSelected)
Vue.use(MyGetWindowSize)
Vue.use(MyLocalStorage)
Vue.use(MyServerInteraction)
Vue.use(MyNotify)
Vue.use(MyParseExcel)

const route = new Router({
  routes: routeViews.routes
})

export default route
