<template>
 <span v-show="show" :style="`background-color: ${color || colorDefault}; ${!!width ? 'width: ' + width + 'px' : '' }`" class="flip-clock__piece">
    <span class="flip-clock__card flip-card">
       <b class="flip-card__top">{{current || '00'}}</b>
       <b :style="`background-color: ${color || colorDefault}; ${!!width ? 'width: ' + width + 'px' : '' }`" class="flip-card__bottom" :data-value="current || '00'"></b>
       <b :style="{'--color': color || colorDefault}" class="flip-card__back" :data-value="previous || '00'"></b>
       <b :style="`background-color: ${color || colorDefault}; ${!!width ? 'width: ' + width + 'px' : '' }`" class="flip-card__back-bottom" :data-value="previous || '00'"></b>
    </span>
  </span>
</template>

<script>
export default {
  name: 'NumberFlip',
  props: ['value', 'color', 'width'],
  data () {
    return {
      current: 0,
      previous: 0,
      show: true,
      colorDefault: '#222'
    }
  },
  watch: {
    value (newValue) {
      this.updateCurrent(newValue)
    }
  },
  mounted () {
    this.updateCurrent(this.value)
  },
  methods: {
    updateCurrent (newValue) {
      let val = newValue || '00'
      this.show = true

      if (val !== this.current) {
        this.previous = this.current
        this.current = val > 0 && val < 10 ? `0${val}` : val

        this.$el.classList.remove('flip')
        this.$el.offsetWidth()
        this.$el.classList.add('flip')
      }
    }
  }
}
</script>

<style scoped>
  @keyframes flipTop {
    0% {
      transform: rotateX(0deg);
      z-index: 2;
    }
    0%, 99% {
      opacity: 1;
    }
    100% {
      transform: rotateX(-90deg);
      opacity: 0;
    }
  }

  @keyframes flipBottom {
    0%, 50% {
      z-index: -1;
      transform: rotateX(90deg);
      opacity: 0;
    }
    51% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotateX(0deg);
      z-index: 5;
    }
  }

  @media (min-width: 1000px) {
    .flip-clock__slot { font-size: 1.2rem; }
    .flip-card { font-size: 3rem; }
  }

  .flip-clock {
    text-align: center;
    perspective: 600px;
    margin: 0 auto;
  }

  .flip-clock__card {
    text-align: center;
  }

  .flip-clock:before,
  .flip-clock:after
  { box-sizing: border-box;
    text-align: center;
    perspective: 600px;
    margin: 0 auto;
  }

  .flip-clock__piece {
    display: inline-block;
    overflow: hidden !important;
    text-align: center;
    margin: 0 0.2vw;
    border-radius: 4px;
  }

  .flip-card {
    display: block;
    position: relative;
    padding-bottom: 0.72em;
    font-size: calc(22px + 3 * ((90vw - 100px) / 1500));
    line-height: 0.95;
  }

  .flip-card__top,
  .flip-card__bottom,
  .flip-card__back-bottom,
  .flip-card__back::before,
  .flip-card__back::after {
    display: block;
    height: 0.72em;
    color: #ccc;
    padding: 0.23em 0.25em 0.4em;
    border-radius: 0.15em 0.15em 0 0;
    transform-style: preserve-3d;
  }

  .flip-card__back {
    --color: rgba(0, 0, 0, 0.5);
  }

  .flip-card__back::before,
  .flip-card__back::after {
    background: var(--color);
  }

  .flip-card__bottom,
  .flip-card__back-bottom {
    color: #FFF;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 0 0 0.15em 0.15em;
    pointer-events: none;
    overflow: hidden;
    z-index: 2;
  }

  .flip-card__back-bottom {
    z-index: 1;
  }

  .flip-card__bottom::after,
  .flip-card__back-bottom::after {
    display: block;
    margin-top: -0.72em;
  }

  .flip-card__back::before,
  .flip-card__bottom::after,
  .flip-card__back-bottom::after {
    content: attr(data-value);
  }

  .flip-card__back {
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    pointer-events: none;
  }

  .flip-card__back::before {
    position: relative;
    overflow: hidden;
    z-index: -1;
  }

  .flip .flip-card__back::before {
    z-index: 1;
    animation: flipTop 0.3s cubic-bezier(.37,.01,.94,.35);
    animation-fill-mode: both;
    transform-origin: center bottom;
  }

  .flip .flip-card__bottom {
    transform-origin: center top;
    animation-fill-mode: both;
    animation: flipBottom 0.6s cubic-bezier(.15,.45,.28,1);
  }
</style>
