<template>
  <div id="content-container" class="mw-100">
    <sidebar-menu ref="sidebar" :menu="routeViews"
                  id="my-sidebar"
                  @toggle-collapse="onCollapse"
                  v-show="logged"
                  :collapsed="collapsed"
                  :relative="true">
      <span slot="toggle-icon">
        <i class="fas fa-bars"/>
      </span>
    </sidebar-menu>

    <!-- Do not use "v-if" or "v-show" on #hide-content it will brake routing -->
    <div id="hide-content" class="w-100 flex-column" :style="`display: ${showContent ? 'flex!important' : 'none!important'}`">
      <b-breadcrumb :items="items" v-if="logged" id="my-breadcrumb"/>

      <transition :name="transition" mode="out-in" @after-enter="checkWindowWidth()" @after-leave="checkWindowWidth()">
        <router-view id="router-view-content" style="flex-grow: 1"/>
      </transition>

      <link-list refs="linkList" v-if="logged"/>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import routeViews from '../router-views'

  export default {
    name: 'MainContent',
    watch: {
      '$route' (to, from) {
        this.checkRoute(to, from)
      },
      'window' () {
        this.checkWindowWidth()
      }
    },
    data () {
      let section = Vue.getLocalStorage('session')
      return {
        logged: section && section.token,
        routeViews: [],
        size: 'cols',
        collapsed: true,
        showContent: false,
        transition: 'slide-right',
        items: [],
        to: {},
        from: {}
      }
    },
    created () {
      this.checkRoute(this.$route)
    },
    mounted () {
      this.updateRoleViews()
      this.$root.$on('change-lang', () => this.updateRoleViews())
      this.$root.$on('resize', () => this.checkWindowWidth())
      this.$nextTick(() => {
        this.checkWindowWidth()
      })
    },
    methods: {
      onCollapse (collapsed) {
        this.collapsed = collapsed
        this.checkWindowWidth()
      },
      checkWindowWidth () {
        let size = Vue.myGetWindowSize()
        this.size = size
        if (size === 'lg' || size === 'xl') {
          this.showContent = true
        } else {
          this.showContent = !this.logged || (this.logged && this.collapsed)
        }
      },
      checkRoute (to, from) {
        const section = Vue.getLocalStorage('session')
        const year = Vue.getLocalStorage('year')
        const roleId = section && section.utente && section.utente.ruolo && section.utente.ruolo.id
        const inactive = Vue.getLocalStorage('inactive')
        const tabs = Vue.getLocalStorage('tabs')

        this.logged = !!(section && section.token && (roleId && roleId !== 1 ? !!year : roleId === 1)) // super admin
        this.checkWindowWidth()

        if (from) {
          const toDepth = to.path.split('/').length
          const fromDepth = from.path.split('/').length
          this.transition = toDepth < fromDepth ? 'slide-right' : 'slide-left'
        } else if (inactive >= tabs && tabs !== null) {
          Vue.deleteLocalStorage('session')
          this.$router.push('/').catch(() => {})
        }

        if (this.logged) {
          this.updateRoleViews()
          if (to.path === '/') {
            this.$router.push({ name: 'Home' }).catch(() => {})
          }
          if (!this.showContent && this.$refs.sidebar) {
            this.collapsed = true
          }
          this.to = to
          this.from = from
          if (this.$refs.linkList) {
            /*
              TODO find better way for this, if pass "routeChanged"
                as prop in <link-list> it will randomly refresh prop generating errors
                */
            this.$refs.linkList.routeChanged = { to, from }
          } else {
            this.$root.$emit('reset-links')
          }
        } else {
          this.$router.push({ name: 'Login' }).catch(() => {})
        }
      },
      updateRoleViews () {
        const section = Vue.getLocalStorage('session')
        const roleId = section && section.utente && section.utente.ruolo && section.utente.ruolo.id
        this.routeViews = routeViews.sidebarItems(this, roleId)
        this.items = Vue.myChangeBreadcrumb(this, this.$route, roleId)
      }
    }
  }
</script>
<style>
.vsm--scroll-wrapper {
  margin-right: 0 !important;
}
</style>
