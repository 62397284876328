<template>
    <b-container class="mw-100 structure">
        <b-card class="mt-4 mb-4">
            <standard-table ref="table"
                            :items="items"
                            :fields="getFields()"
                            :theadTrClass="'first-4-table-cols'"

                            :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                            :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                            :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                            :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                            :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                            :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                            :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"

                            fullWidth="true"
                            noContainerPadding="true"
                            @rowInfo="showRowInfo"
                            @rowEdit="pushToEdit"
                            @rowDelete="deleteItem">

                <b-container slot="row-before-search" class="mw-100 p-0">
                    <b-row align-h="end">
                        <b-col cols="auto">
                            <b-button v-on:click="pushToEdit()" class="mb-3 b-c-blue" id="add-structure">
                                {{$t('message.add')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-per-page" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                            <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button" @click="$refs.table.excel($tc('message.structure', 2))">Excel</b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-search" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                        <b-button v-on:click="resetFilters()" class="b-c-green mb-2 mr-2" id="reset-filters">
                            {{$t('message.resetFilters')}}
                        </b-button>

                        <b-button @click="showCollapse = !showCollapse" :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                            <span v-show="!showCollapse" class="when-opened">{{$t('message.show')}} {{$tc('message.filter', 2).toLowerCase()}}</span>
                            <span v-show="showCollapse" class="when-closed">{{$t('message.hide')}} {{$tc('message.filter', 2).toLowerCase()}}</span>
                        </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="after-search" class="mw-100 p-0">
                    <b-collapse id="my-collapse" v-model="showCollapse">
                        <b-container class="mw-100 m-0 p-0">
                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                                    <b-form-group :label="`${$tc('message.licenceType', 1)}`">
                                        <v-select :options="licenceTypeOptions"
                                                  label="text"
                                                  :reduce="obj => obj.value"
                                                  v-model="licenceTypeFilter">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="4" xl="3">
                                    <b-form-group :label="`${$tc('message.legalPerson', 1)}`">
                                        <v-select :options="legalPersons"
                                                  id="structure-legal-person"
                                                  :reduce="obj => obj.id"
                                                  label="ragione_sociale"
                                                  v-model="legalPersonsFilter">
                                        </v-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-collapse>
                </b-container>
            </standard-table>
        </b-card>

        <b-modal ref="infoModal" id="infoModal" :title="`${$tc('message.details', 1)}`">
            <b-container>
                <b-row>
                  <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div class="d-flex ml-5">
                      <h6><b>ID</b></h6>
                      <h6 class="ml-2">{{infoItem.id || $tc('message.emptyValue')}}</h6>
                    </div>
                  </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.denomination', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.denominazione || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.syntheticName', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.den_sintetica || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.legalPerson', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.persona_giuridica || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.hourLimitAppeal', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.ora_limite_appello || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.offsetAlert', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.offset_alert || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.province', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.provincia || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.common', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.comune || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.cap', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.cap || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.address', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.indirizzo || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.phone', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.telefono || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.fax', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.fax || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.email', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.email || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.secondaryEmail', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.email_secondaria || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.pecMail', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.pec || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.administrativeCode', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.codice_amministrazione || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>

                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.licenceType', 1)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.tipo_licenza_text || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                        <div class="d-flex">
                            <h6><b>{{$tc('message.note', 2)}}</b></h6>
                            <h6 class="ml-2">{{infoItem.note || $tc('message.emptyValue')}}</h6>
                        </div>
                    </b-col>
                </b-row>
            </b-container>

            <div slot="modal-footer" class="w-100 justify-content-end d-flex">
                <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok </b-button>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'

  export default {
    name: 'Structure',
    created () {
      this.fetchData()
    },
    updated () {
      this.$root.$emit('checkExcel')
    },
    watch: {
      licenceTypeFilter (obj) {
        this.getItems(obj, this.legalPersonsFilter)
      },
      legalPersonsFilter (obj) {
        this.getItems(this.licenceTypeFilter, obj)
      }
    },
    data () {
      return {
        removedOptions: [],
        removedFilter: null,
        showCollapse: false,
        items: [],
        originalItems: [],
        legalPersons: [],
        legalPersonsFilter: null,
        infoItem: {},
        licenceTypeOptions: [],
        licenceTypeFilter: null
      }
    },
    methods: {
      fetchData () {
        this.$root.$emit('activeLoader', true)
        this.getOptions().then(() => {
          this.getStructure()
        })
      },
      getOptions () {
        const axiosArray = []
        this.getLicenceTypeOptions()
        axiosArray.push(this.getLegalPersons())
        return Vue.myAll(axiosArray)
      },
      getLicenceTypeOptions () {
        this.licenceTypeOptions = [
          { value: 0, text: this.$tc('message.licenceLight', 1) },
          { value: 1, text: this.$tc('message.licenceFull', 1) }
        ]
      },
      getLegalPersons () {
        return Vue.myGet(this, '/persona_giuridica', 'persone').then(legalPersons => {
          this.legalPersons = legalPersons
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getStructure () {
        this.items = []
        this.originalItems = []
        Vue.myGet(this, '/struttura', 'strutture').then((structures) => {
          const editedStructures = []
          structures.forEach(structure => {
            const editedStructure = structure
            if (structure.id_persona_giuridica) {
              const foundedLegalPerson = _.find(this.legalPersons, obj => obj.id === structure.id_persona_giuridica)
              if (foundedLegalPerson) editedStructure.persona_giuridica = foundedLegalPerson.ragione_sociale
            }
            editedStructure.tipo_licenza_text = structure.tipo_licenza === 0 ? this.$tc('message.licenceLight') : this.$tc('message.licenceFull')
            editedStructures.push(editedStructure)
          })
          this.items = editedStructures
          this.originalItems = _.cloneDeep(editedStructures)
          this.$root.$emit('activeLoader', false)
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      },
      getFields () {
        return [
          { key: 'info', label: '' },
          { key: 'edit', label: '' },
          { key: 'delete', label: '' },
          { key: 'id', label: 'ID', sortable: true, sortDirection: 'desc' },
          { key: 'persona_giuridica', label: `${this.$tc('message.legalPerson', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'den_sintetica', label: `${this.$tc('message.syntheticName', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'denominazione', label: `${this.$tc('message.denomination', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'provincia', label: `${this.$tc('message.province', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'comune', label: `${this.$tc('message.common', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'cap', label: `${this.$tc('message.cap', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'indirizzo', label: `${this.$tc('message.address', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'telefono', label: `${this.$tc('message.phone', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'email', label: `${this.$tc('message.email', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'email_secondaria', label: `${this.$tc('message.secondaryEmail', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'fax', label: `${this.$tc('message.fax', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'pec', label: `${this.$tc('message.pecMail', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'codice_amministrazione', label: `${this.$tc('message.administrativeCode', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'note', label: `${this.$tc('message.note', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'ora_limite_appello', label: `${this.$tc('message.hourLimitAppeal', 2)}`, sortable: true, sortDirection: 'desc' },
          { key: 'offset_alert', label: `${this.$tc('message.offsetAlert', 1)}`, sortable: true, sortDirection: 'desc', hideInTable: true },
          { key: 'tipo_licenza_text', label: `${this.$tc('message.licenceType', 1)}`, sortable: true, sortDirection: 'desc' }
        ]
      },
      showRowInfo (event) {
        this.infoItem = event && event.item
        if (this.$refs.infoModal) this.$refs.infoModal.show()
      },
      pushToEdit (event) {
        const item = event && event.item
        item && item.id ? this.$router.push({
          name: 'EditStructure',
          params: { id: item.id }
        }) : this.$router.push({
          name: 'EditStructure',
          params: { id: 'new' }
        })
      },
      deleteItem (event) {
        const item = event && event.item
        if (item && item.id) {
          this.$root.$emit('deleteItem', { description: item.ragione_sociale, path: `/struttura/${item.id}`, redirect: this.$route.path })
        }
      },
      resetFilters () {
        this.licenceTypeFilter = null
        this.legalPersonsFilter = null
        this.$refs.table.resetSearch()
      },
      getItems (licenceType, legalPerson) {
        let editedItems = _.cloneDeep(this.originalItems)
        if (licenceType) editedItems = _.filter(editedItems, obj => obj.tipo_licenza === licenceType)
        if (legalPerson) editedItems = _.filter(editedItems, obj => obj.id_persona_giuridica === legalPerson)
        this.items = editedItems
      }
    }
  }
</script>
