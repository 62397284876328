<template>
  <div class="superAdmin login-image" :style="loginImage">
    <b-container class="pb-3 mw-100" v-show="size !== 'cols' && size !== 'sm'">
      <b-row class="m-2" align-h="center">
        <b-col class="mt-5" cols="12" sm="12" md="6" lg="5" xl="5">

          <b-row class="mb-3" align-h="center">
            <i :class="`fas fa-cogs c-green ${isMobile ? 'fa-5x' : 'fa-10x'}`"/>
          </b-row>

          <b-row align-h="center">
            <b-nav vertical>
              <label class="row m-1">
                <b-link id="nw-school-year" :to="{ name: 'SchoolYear' }" class="BBB-link">
                  {{$tc('message.schoolYears')}}
                </b-link>
              </label>

              <label class="row m-1">
                <b-link id="legal-person" :to="{ name: 'LegalPerson' }" class="BBB-link">
                  {{$tc('message.registry', 1)}} {{$tc('message.legalPerson', 2).toLowerCase()}}
                </b-link>
              </label>

              <label class="row m-1">
                <b-link id="structure" :to="{ name: 'Structure'}" class="BBB-link">
                  {{$tc('message.registry', 1)}} {{$tc('message.structure', 2).toLowerCase()}}
                </b-link>
              </label>

              <label class="row m-1">
                <b-link id="section" :to="{ name: 'Section'}" class="BBB-link">
                  {{$tc('message.registry', 1)}} {{$tc('message.section', 2).toLowerCase()}}
                </b-link>
              </label>

              <label class="row m-1">
                <b-link id="operator" :to="{ name: 'Operator' }" class="BBB-link">
                  {{$tc('message.registry', 1)}} {{$tc('message.operator', 2).toLowerCase()}}
                </b-link>
              </label>

              <label class="row m-1">
                <b-link id="bus" :to="{ name: 'Bus' }" class="BBB-link">
                  {{$tc('message.registry', 1)}} {{$tc('message.bus', 2).toLowerCase()}}
                </b-link>
              </label>
            </b-nav>
          </b-row>

        </b-col>
      </b-row>
    </b-container>

    <!-- Modal Component -->
    <b-modal ref="operatorsInStructureModal"
             id="operators-in-structure-modal"
             title="Gestione strutture">
      <b-container fluid>
        <form>

          <!-- Year -->
          <b-form-group horizontal
                        :label-cols="4"
                        label="Anno (*):"
                        label-class="text-sm-right"
                        label-for="year">
            <v-select :options="yearOptions"
                      :reduce="obj => obj.id"
                      label="value"
                      v-model="yearSelected">
            </v-select>
          </b-form-group>

          <!-- Structure -->
          <b-form-group horizontal
                        :label-cols="4"
                        label="Struttura (*):"
                        label-class="text-sm-right"
                        label-for="structure">
            <v-select :options="structureOptions"
                      :reduce="obj => obj.id"
                      label="value"
                      v-model="structureSelected">
            </v-select>
          </b-form-group>
        </form>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-button id="operators-in-structure-back"
                  type="button"
                  class="float-right ml-3 mt-2"> Annulla
        </b-button>

        <b-button id="operators-in-structure-ahead"
                  type="button"
                  class="float-right ml-3 mt-2"
                  :disabled="!(yearSelected && structureSelected)">Prosegui
        </b-button>
      </div>
    </b-modal>

    <!-- Modal Component -->
    <b-modal ref="structuresOfOperatorModal"
             id="structures-of-operator-modal"
             title="Gestione operatori">
      <b-container fluid>
        <form>

          <!-- Year -->
          <b-form-group horizontal
                        :label-cols="4"
                        label="Anno (*):"
                        label-class="text-sm-right"
                        label-for="year">
            <v-select :options="yearOptions"
                      :reduce="obj => obj.id"
                      label="value"
                      v-model="yearSelected">
            </v-select>
          </b-form-group>

          <!-- Operator -->
          <b-form-group horizontal
                        :label-cols="4"
                        label="Operatore (*):"
                        label-class="text-sm-right"
                        label-for="operator">
            <v-select :options="operatorOptions"
                      :reduce="obj => obj.id"
                      label="value"
                      v-model="operatorSelected">
            </v-select>
          </b-form-group>
        </form>
      </b-container>
      <div slot="modal-footer" class="w-100">
        <b-button id="structures-of-operator-back"
                  type="button"
                  class="float-right ml-3 mt-2">Annulla
        </b-button>

        <b-button id="structures-of-operator-ahead"
                  type="button"
                  class="float-right ml-3 mt-2"
                  :disabled="!(yearSelected && operatorSelected)">Prosegui
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'SuperAdmin',
    beforeCreate () {},
    data () {
      return {
        loginImage: '',
        size: 'xl',
        isMobile: false,
        structureOptions: [],
        structureSelected: null,
        yearOptions: [],
        yearSelected: null,
        operatorOptions: [],
        operatorSelected: null
      }
    },
    created () {},
    mounted () {
      this.getLoginImage()
      this.$root.$on('resize', () => {
        this.getLoginImage()
      })
    },
    watch: {},
    methods: {
      getLoginImage () {
        const size = Vue.myGetWindowSize()
        let backGround = ''

        switch (size) {
          case 'cols':
            backGround = 'background: url("/img/bbbus-login-720x1280.jpg")'
            break

          case 'sm':
            backGround = 'background: url("/img/bbbus-login-720x1280.jpg")'
            break

          case 'md':
            backGround = 'background: url("/img/bbbus-login-1024x683.jpg")'
            break

          default:
            backGround = 'background: url("/img/bbbus-login-1920x1280.jpg")'
            break
        }
        this.loginImage = backGround
        this.isMobile = size === 'cols' || size === 'sm' || size === 'md'
        this.size = size
      }
    }
  }
</script>

<style scoped>
</style>
