<template>
    <b-container fluid>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <header class="modal-header">
                    <h5 class="my-modal-title">{{$tc('message.structure', 1)}}</h5>
                </header>

                <div class="modal-body">
                    <ValidationObserver ref="form" v-slot="{ validate, invalid, pending }">
                        <b-container>
                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Denomination -->
                                    <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.denomination', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.denomination', 1)} (*)`">
                                            <b-form-input id="denomination"
                                                          type="text"
                                                          v-model="input.denominazione"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Synthetic name -->
                                    <ValidationProvider :rules="{ required: true, max: 15 }" :name="`${$tc('message.syntheticName', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.syntheticName', 1)} (*)`">
                                            <b-form-input id="synthetic-name"
                                                          type="text"
                                                          v-model="input.den_sintetica"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Legal person -->
                                    <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.legalPerson', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.legalPerson', 1)} (*)`">
                                            <v-select :options="legalPersonsOptions"
                                                      id="structure-legal-person"
                                                      ref="legalPerson"
                                                      :reduce="obj => obj.id"
                                                      :class="checkSelectStyle('legalPerson', itemChanged, valid, errors, true)"
                                                      label="ragione_sociale"
                                                      v-model="input.id_persona_giuridica">
                                                <div slot="selected-option" slot-scope="option">
                                                    {{option && option.ragione_sociale}}
                                                </div>
                                            </v-select>

                                            <div id="legalPerson-inputLiveFeedback" v-if="errors.length !== 0" class="my-invalid-feedback">
                                                {{ lodash.first(errors) }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                                    <!-- Hour limit appeal -->
                                    <ValidationProvider :name="`${$tc('message.hourLimitAppeal', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.hourLimitAppeal', 1)}`">
                                            <flat-pickr id="filter-start"
                                                        class="form-control"
                                                        v-model="input.ora_limite_appello"
                                                        :config="hourLimitConfig">
                                            </flat-pickr>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="6" md="6" lg="3" xl="3">
                                    <!-- Offset alert -->
                                    <ValidationProvider :rules="{ min: 0, max_value: 59 }" :name="`${$tc('message.offsetAlert', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.offsetAlert', 1)}`">
                                            <b-form-input id="offset-alert"
                                                          type="number"
                                                          step="1"
                                                          min="0"
                                                          max="59"
                                                          v-model="input.offset_alert"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="6" md="6" lg="4" xl="4">
                                    <!-- Province -->
                                    <ValidationProvider :rules="{ max: 30 }" :name="`${$tc('message.province', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.province', 1)}`">
                                            <b-form-input id="province"
                                                          type="text"
                                                          v-model="input.provincia"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="6" md="6" lg="4" xl="4">
                                    <!-- Common -->
                                    <ValidationProvider :rules="{ max: 30 }" :name="`${$tc('message.common', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.common', 1)}`">
                                            <b-form-input id="common"
                                                          type="text"
                                                          v-model="input.comune"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="4" md="3" lg="4" xl="4">
                                    <!-- Cap -->
                                    <ValidationProvider :rules="{ max: 5 }" :name="`${$tc('message.cap', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.cap', 1)}`">
                                            <b-form-input id="cap"
                                                          type="number"
                                                          v-model="input.cap"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="8" md="9" lg="12" xl="12">
                                    <!-- Address -->
                                    <ValidationProvider :rules="{ max: 50 }" :name="`${$tc('message.address', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.address', 1)}`">
                                            <b-form-input id="address"
                                                          type="text"
                                                          v-model="input.indirizzo"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Phone -->
                                    <ValidationProvider :rules="{ min: 0, max: 20, required: true }" :name="`${$tc('message.phone', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.phone', 1)} (*)`">
                                            <b-form-input id="phone"
                                                          type="number"
                                                          min="0"
                                                          max="12"
                                                          v-model="input.telefono"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- FAX -->
                                    <ValidationProvider :rules="{ max: 20 }" :name="`${$tc('message.fax', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.fax', 1)}`">
                                            <b-form-input id="fax"
                                                          v-model="input.fax"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                                    <!-- Email -->
                                    <ValidationProvider :rules="{ max: 50, email: true, required: true }" :name="`${$tc('message.email', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.email', 1)} (*)`">
                                            <b-form-input id="email"
                                                          v-model="input.email"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                                    <!-- Secondary email -->
                                    <ValidationProvider :rules="{ max: 50, email: true }" :name="`${$tc('message.secondaryEmail', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.secondaryEmail', 1)}`">
                                            <b-form-input id="secondary-email"
                                                          v-model="input.email_secondaria"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                                    <!-- PEC email -->
                                    <ValidationProvider :rules="{ max: 50, email: true }" :name="`${$tc('message.pecMail', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.pecMail', 1)}`">
                                            <b-form-input id="pec-email"
                                                          v-model="input.pec"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Administrative code -->
                                    <ValidationProvider :rules="{ max: 15 }" :name="`${$tc('message.administrativeCode', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.administrativeCode', 1)}`">
                                            <b-form-input id="administrative-code"
                                                          v-model="input.codice_amministrazione"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Licence type -->
                                    <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.licenceType', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.licenceType', 1)} (*)`">
                                            <v-select :options="licenceTypeOptions"
                                                      ref="licence"
                                                      id="licence"
                                                      :class="checkSelectStyle('licence', itemChanged, valid, errors, true)"
                                                      :reduce="obj => obj.value"
                                                      label="text"
                                                      v-model="input.tipo_licenza">
                                            </v-select>

                                            <div id="licence-inputLiveFeedback" v-if="errors.length !== 0" class="my-invalid-feedback">
                                                {{ lodash.first(errors) }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <!-- Notes -->
                                    <ValidationProvider :rules="{ max: 250 }" :name="`${$tc('message.note', 2)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.note', 2)}`">
                                            <b-form-textarea
                                                    id="note"
                                                    v-model="input.note"
                                                    rows="2"
                                                    :state="lodash.first(errors) ? false : null">
                                            </b-form-textarea>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="my-border-top" align-h="end">
                                <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                    <b-button id="save"
                                              type="button"
                                              class="float-right ml-3 mt-2 b-c-green"
                                              :disabled="invalid || pending || !itemChanged"
                                              @click="save(false)"> {{$tc('message.save', 1)}}
                                    </b-button>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                    <b-button id="save-and-exit"
                                              type="button"
                                              class="float-right ml-3 mt-2 b-c-green"
                                              :disabled="invalid || pending || !itemChanged"
                                              @click="save(true)"> {{$tc('message.saveClose', 1)}}
                                    </b-button>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                    <b-button id="cancel-button"
                                              type="button"
                                              class="float-right ml-3 mt-2"
                                              @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import { DateTime } from 'luxon'

  export default {
    name: 'EditStructure',
    created () {
      this.fetchData().then(() => {
        this.addWatcher()
      })
    },
    mounted () {
      this.$root.$on('change-lang', () => {
        this.$nextTick(() => {
          if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
        })
      })
    },
    data () {
      const structureId = this.$route.params.id
      const lodash = _
      const backPath = '/registry/structure'
      return {
        backPath,
        structureId,
        lodash,
        DateTime,
        input: {},
        watcher: null,
        itemChanged: false,
        licenceTypeOptions: [],
        legalPersonsOptions: [],
        hourLimitConfig: {
          enableTime: true,
          noCalendar: true,
          dateFormat: 'H:i',
          time_24hr: true
        }
      }
    },
    methods: {
      resetWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        this.itemChanged = false
      },
      addWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcher = this.$watch('input', () => {
          this.itemChanged = true
        }, { deep: true })
      },
      fetchData () {
        this.resetWatcher()
        return this.getOptions().then(() => {
          return this.getStructure()
        })
      },
      getOptions () {
        const axiosArray = []
        this.getLicenceTypeOptions()
        axiosArray.push(this.getLegalPersons())
        return Vue.myAll(axiosArray)
      },
      getLicenceTypeOptions () {
        this.licenceTypeOptions = [
          { value: 0, text: this.$tc('message.licenceLight', 1) },
          { value: 1, text: this.$tc('message.licenceFull', 1) }
        ]
      },
      getLegalPersons () {
        return Vue.myGet(this, '/persona_giuridica', 'persone').then(legalPersons => {
          this.legalPersonsOptions = legalPersons
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getStructure () {
        if (this.structureId !== 'new') {
          return Vue.myGet(this, `/struttura/${this.structureId}`, 'strutture').then((structure) => {
            this.input = structure
            return true
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        } else {
          this.input = {}
          return new Promise(resolve => resolve())
        }
      },
      exitCheck (itemChanged) {
        if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
        else this.$router.push(this.backPath)
      },
      save (pushBack) {
        const editedItem = this.parseInputItem(_.cloneDeep(this.input))
        Vue.patchOrPost(this.structureId !== 'new',
        `/struttura${this.structureId !== 'new' ? '/' + this.structureId : ''}`, editedItem).then(r => {
          Vue.success(this, `${this.$tc('message.structure', 1)} ${this.$t(this.structureId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          this.updateAllCalendars(this.structureId !== 'new' ? this.structureId : r.data.id, pushBack)
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      },
      updateAllCalendars (structureId, pushBack) {
        const axiosArray = []
        Vue.myGet(this, 'calendario', 'calendari').then(calendars => {
          Vue.myGet(this, 'anno_scolastico', 'anni').then(years => {
            years.forEach(year => {
              const foundedStructureYearCalendar = _.find(calendars, obj => parseInt(obj.id_struttura) === structureId && obj.id_anno === parseInt(year.id))
              if (!foundedStructureYearCalendar || calendars.length === 0) {
                const newCalendar = {
                  id_anno: year.id,
                  id_struttura: structureId,
                  descrizione: `Calendario standard ${year.anno}`,
                  note: null,
                  standard: true
                }
                axiosArray.push(this.postNewCalendar(newCalendar))
              }
            })
            Vue.myAll(axiosArray).then(() => {
              if (pushBack) this.$router.push(this.backPath)
              else this.$router.push({ name: 'RefreshPage', params: { id: structureId, name: 'EditStructure' } })
            })
          })
        })
      },
      postNewCalendar (obj) {
        return Vue.patchOrPost(false, '/calendario', obj).then(() => {
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      parseInputItem (item) {
        const editedItem = {}
        const intFields = ['tipo_licenza', 'id_persona_giuridica', 'offset_alert', 'id', 'cap']
        _.forOwn(item, (val, field) => {
          const mustBeInt = _.find(intFields, obj => obj === field)
          if (mustBeInt) {
            editedItem[field] = val === 0 ? 0 : (parseInt(val) || null)
          } else {
            editedItem[field] = val === 0 ? 0 : (val || null)
          }
        })
        return editedItem
      },
      checkSelectStyle (ref, groupChanged, valid, errors, required) {
        Vue.MySelectValidationClass(this, ref, groupChanged, valid, errors, required)
      }
    }
  }
</script>
