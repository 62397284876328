<template>
  <b-container class="mw-100">
    <b-row v-show="enabledLinks.length !== 0" align-h="center">
      <b-col cols="auto">
        <h6 class="d-inline-flex mb-0 c-l-gray" v-if="!isMobile && !hideLabel">{{enabledLinks.length === 1 ? $tc('message.shortcut', 1) : $tc('message.shortcut', 2)}}</h6>
        <i class="fas fa-hand-point-right c-l-gray fa-lg"/>
      </b-col>

      <b-col>
        <a v-for="link in enabledLinks" :key="link.index" class="">
          <h6 :class="`c-green hover-enabled h-c-green mb-0 ${isMobile ? 'd-block' : 'd-inline-flex'}`" @click="clickedLink(link)">{{link.title}}</h6>
          <div class="fas fa-circle fa-xs c-l-gray mr-2 ml-1 my-custom-dot" v-show="enabledLinks.length - 1 !== link.index && !isMobile"></div>
        </a>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import routeViews from '../../router-views'
import _ from 'lodash'
import Vue from 'vue'

export default {
  name: 'LinkList',
  props: ['value', 'hideLabel'],
  watch: {
    routeChanged (val) {
      if (!this.value) {
        this.enabledLinks = []
        this.to = val.to
        this.from = val.from
        if (val.to && val.to.meta && val.to.meta.links && val.to.meta.links.length !== 0) {
          this.enabledLinks = val.to.meta.links
        }
      }
    },
    value (newVal) {
      this.enabledLinks = newVal
    }
  },
  mounted () {
    this.$root.$on('change-links', links => {
      const section = Vue.getLocalStorage('session')
      const roleId = section && section.utente && section.utente.ruolo && section.utente.ruolo.id
      const clonedLinks = _.cloneDeep(links)
      const editedLinks = []
      const basicRouteViews = routeViews.sidebarItems(this, roleId)
      const allRoutes = this.getAllRoutes(basicRouteViews)
      if (clonedLinks && clonedLinks.length !== 0) {
        clonedLinks.forEach((link, index) => {
          const editedLink = link
          const foundedLink = !!allRoutes && allRoutes.length !== 0 ? _.find(allRoutes, obj => obj.href === link.href) : null
          editedLink.title = foundedLink && foundedLink.title ? foundedLink.title : ''
          editedLink.index = index
          editedLinks.push(editedLink)
        })
      }
      this.enabledLinks = editedLinks
      this.allRoutes = allRoutes
    })
    this.$root.$on('reset-links', () => {
      this.enabledLinks = []
    })
    this.$root.$on('reset-links', () => this.checkWindowWidth())
  },
  data () {
    return {
      lodash: _,
      allRoutes: [],
      enabledLinks: this.value || [],
      to: {},
      from: {},
      routeChanged: {},
      isMobile: false
    }
  },
  methods: {
    getAllRoutes (routes) {
      const result = []
      if (routes.length) {
        routes.forEach(route => {
          const innerRoutes = this.getAllRoutes(route)
          innerRoutes.forEach(innerRoute => {
            if (innerRoute.href) {
              result.push(innerRoute)
            }
          })
        })
      }
      if (routes.child) {
        routes.child.forEach(route => {
          if (route.href) {
            result.push(route)
          }
        })
      }
      return result
    },
    clickedLink (link) {
      this.$root.$emit('clicked-link', link)
    },
    checkWindowWidth () {
      let size = Vue.myGetWindowSize()
      this.isMobile = (size !== 'md' && size !== 'lg' && size !== 'xl')
    }
  }
}
</script>

<style scoped>
  .my-custom-dot {
    font-size: 8px;
    bottom: 3px;
    position: relative;
  }
</style>
