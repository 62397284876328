/* eslint-disable no-param-reassign */
const _ = require('lodash')
const { DateTime } = require('luxon')

const MyTableSorted = {
  install (Vue) {
    Vue.myTableSorted = (items, sortBy, sortDesc, isDate) => {
      let useEditedSortBy = false
      let formatDate = 0
      const editedItems = []
      items.forEach((item) => {
        const editedItem = item
        if (item[sortBy]) {
          if (isDate) {
            if (DateTime.fromSQL(item[sortBy]).isValid) {
              editedItem[sortBy] = DateTime.fromSQL(item[sortBy]).ts
              formatDate = 0
            } else if (DateTime.fromFormat(item[sortBy], 'dd-MM-yyyy').isValid) {
              editedItem[sortBy] = DateTime.fromFormat(item[sortBy], 'dd-MM-yyyy').ts
              formatDate = 3
            } else if (DateTime.fromFormat(item[sortBy], 'dd/MM/yyyy').isValid) {
              editedItem[sortBy] = DateTime.fromFormat(item[sortBy], 'dd/MM/yyyy').ts
              formatDate = 1
            } else if (DateTime.fromISO(item[sortBy]).isValid) {
              editedItem[sortBy] = DateTime.fromISO(item[sortBy]).ts
              formatDate = 2
            }
          } else if (typeof item[sortBy] === 'string') {
            editedItem[`${sortBy}Edited`] = item[sortBy].toLowerCase()
            useEditedSortBy = true
          } else if (Object.prototype.toString.call(item[sortBy]) === 'number') {
            editedItem[sortBy] = JSON.parse(item[sortBy])
          }
        }
        editedItems.push(editedItem)
      })
      if (isDate) {
        const orderedDates = _.orderBy(editedItems, sortBy, sortDesc ? 'desc' : 'asc')
        const parsedItems = []
        orderedDates.forEach((item) => {
          let editedItem = item
          if (item[sortBy]) {
            switch (formatDate) {
              case 0:
                editedItem[sortBy] = DateTime.fromMillis(item[sortBy]).toSQLDate()
                break

              case 1:
                editedItem[sortBy] = DateTime.fromMillis(item[sortBy]).toFormat('dd/MM/yyyy')
                break

              case 2:
                editedItem[sortBy] = DateTime.fromMillis(item[sortBy]).toISO()
                break

              case 3:
                editedItem[sortBy] = DateTime.fromMillis(item[sortBy]).toFormat('dd-MM-yyyy')
                break

              default:
                editedItem[sortBy] = DateTime.fromMillis(item[sortBy])
                break
            }
          }
          parsedItems.push(editedItem)
        })
        return parsedItems
      }
      return _.orderBy(editedItems, useEditedSortBy ? `${sortBy}Edited` : sortBy, sortDesc ? 'desc' : 'asc')
    }
  }
}

export default MyTableSorted
