<template>
    <b-container fluid>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <header class="modal-header">
                    <h5 class="my-modal-title">{{$tc('message.legalPerson', 1)}}</h5>
                </header>

                <div class="modal-body">
                    <ValidationObserver ref="form" v-slot="{ validate, invalid, pending }">
                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- Business name -->
                                <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.businessName', 1)} (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.businessName', 1)} (*)`">
                                        <b-form-input id="business-name"
                                                      type="text"
                                                      v-model="input.ragione_sociale"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Fiscal code -->
                                <ValidationProvider :rules="{ taxCode: true }" :name="`${$tc('message.taxCode', 1)}`"
                                                    ref="validationTaxCode"
                                                    v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.taxCode', 1)}`">
                                        <b-form-input id="fiscal-code"
                                                      v-model="input.codice_fiscale"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- VAT Number -->
                                <ValidationProvider :rules="{ max: 14 }" :name="`${$tc('message.VATNumber', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.VATNumber', 1)}`">
                                        <b-form-input id="vat-number"
                                                      v-model="input.partita_iva"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                                <!-- CAP -->
                                <ValidationProvider :rules="{ min: 5, max: 5 }" :name="`${$tc('message.cap', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.cap', 1)}`">
                                        <b-form-input id="cap"
                                                      type="number"
                                                      v-model="input.cap"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- City -->
                                <ValidationProvider :rules="{ max: 30 }" :name="`${$tc('message.city', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.city', 1)}`">
                                        <b-form-input id="city"
                                                      v-model="input.comune"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Country -->
                                <ValidationProvider :rules="{ max: 30 }" :name="`${$tc('message.province', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.province', 1)}`">
                                        <b-form-input id="province"
                                                      v-model="input.provincia"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-row align-h="end">
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="12" xl="12">
                                <!-- Address -->
                                <ValidationProvider :rules="{ max: 50 }" :name="`${$tc('message.address', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.address', 1)}`">
                                        <b-form-input id="address"
                                                      v-model="input.indirizzo"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-form-row class="border m-2"/>

                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="12" xl="6">
                                <!-- Phone -->
                                <ValidationProvider :rules="{ max: 20 }" :name="`${$tc('message.phone', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.phone', 1)}`">
                                        <b-form-input id="phone"
                                                      v-model="input.telefono"
                                                      type="number"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="12" xl="6">
                                <!-- Email 1 -->
                                <ValidationProvider :rules="{ max: 128, email: true }" :name="`${$tc('message.email', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.email', 1)}`">
                                        <b-form-input id="email-1"
                                                      v-model="input.email"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- Email 2 -->
                                <ValidationProvider :rules="{ max: 128, email: true }" :name="`${$tc('message.secondaryEmail', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.secondaryEmail', 1)}`">
                                        <b-form-input id="email-2"
                                                      v-model="input.email_secondaria"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col class="mt-1" cols="12" sm="12" md="12" lg="12" xl="6">
                                <!-- Email PEC -->
                                <ValidationProvider :rules="{ max: 128, email: true }" :name="`${$tc('message.pecMail', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.pecMail', 1)}`">
                                        <b-form-input id="email-2"
                                                      v-model="input.pec"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>

                                <!-- FAX -->
                                <ValidationProvider :rules="{ max: 20 }" :name="`${$tc('message.fax', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.fax', 1)}`">
                                        <b-form-input id="email-2"
                                                      v-model="input.fax"
                                                      :state="lodash.first(errors) ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-row class="my-border-top" align-h="end">
                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="save"
                                          type="button"
                                          class="float-right mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged"
                                          @click="save(false)"> {{$tc('message.save', 1)}}
                                </b-button>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="save-and-exit"
                                          type="button"
                                          class="float-right mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged"
                                          @click="save(true)"> {{$tc('message.saveClose', 1)}}
                                </b-button>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="cancel-button"
                                          type="button"
                                          class="float-right mt-2"
                                          @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'

  export default {
    name: 'EditLegalPerson',
    created () {
      this.fetchData().then(() => {
        this.addWatcher()
      })
    },
    mounted () {
      this.$root.$on('change-lang', () => {
        this.$nextTick(() => {
          if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
        })
      })
    },
    data () {
      const legalPersonId = this.$route.params.id
      const lodash = _
      const backPath = '/registry/legal_person'
      return {
        backPath,
        legalPersonId,
        lodash,
        input: {},
        watcher: null,
        itemChanged: false
      }
    },
    methods: {
      resetWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        this.itemChanged = false
      },
      addWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcher = this.$watch('input', () => {
          this.itemChanged = true
        }, { deep: true })
      },
      fetchData () {
        this.resetWatcher()
        const id = this.legalPersonId
        if (id !== 'new') {
          return Vue.myGet(this, `/persona_giuridica/${id}`, 'persone').then(result => {
            this.input = result
            return true
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        }
        return new Promise(resolve => resolve())
      },
      exitCheck (itemChanged) {
        if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
        else this.$router.push(this.backPath)
      },
      save (pushBack) {
        const editedItem = this.parseInputItem(_.cloneDeep(this.input))
        Vue.patchOrPost(this.legalPersonId !== 'new',
        `/persona_giuridica${this.legalPersonId !== 'new' ? '/' + this.legalPersonId : ''}`, editedItem).then(r => {
          Vue.success(this, `${this.$tc('message.legalPerson', 1)} ${this.$t(this.legalPersonId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          if (pushBack) this.$router.push(this.backPath)
          else this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditLegalPerson' } })
        }).catch(errorObj => {
          const error = errorObj.response
          if (error && error.data && error.data.constraint && error.data.constraint === 'codice_fiscale' &&
          this.$refs && this.$refs.validationTaxCode) {
            this.$refs.validationTaxCode.setErrors([this.$tc('constraints.codice_fiscale', 1)])
          }
          Vue.manageErrors(this, errorObj)
        })
      },
      parseInputItem (item) {
        const editedItem = {}
        const intFields = ['id_utente', 'id', 'cap']
        _.forOwn(item, (val, field) => {
          const mustBeInt = _.find(intFields, obj => obj === field)
          if (mustBeInt) {
            editedItem[field] = val === 0 ? 0 : (parseInt(val) || null)
          } else {
            editedItem[field] = val === 0 ? 0 : (val || null)
          }
        })
        return editedItem
      }
    }
  }
</script>
