import { render, staticRenderFns } from "./ChangeStructureChild.vue?vue&type=template&id=49e2d95a&scoped=true&"
import script from "./ChangeStructureChild.vue?vue&type=script&lang=js&"
export * from "./ChangeStructureChild.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e2d95a",
  null
  
)

export default component.exports