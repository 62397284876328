<template>
    <b-container fluid>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <header class="modal-header">
                    <h5 class="my-modal-title">{{$tc('message.section', 1)}}</h5>
                </header>

                <div class="modal-body">
                    <ValidationObserver ref="form" v-slot="{ validate, invalid, pending }">
                        <b-container>
                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Description -->
                                    <ValidationProvider :rules="{ required: true, max: 30 }" :name="`${$tc('message.description', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.description', 1)} (*)`">
                                            <b-form-input id="denomination"
                                                          type="text"
                                                          v-model="input.descrizione"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Structure -->
                                    <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.structure', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.structure', 1)} (*)`">
                                            <v-select :options="structureOptions"
                                                      ref="structure"
                                                      id="structure"
                                                      :class="checkSelectStyle('structure', itemChanged, valid, errors, true)"
                                                      :reduce="obj => obj.id"
                                                      label="den_sintetica"
                                                      v-model="input.id_struttura">
                                            </v-select>

                                            <div id="licence-inputLiveFeedback" v-if="errors.length !== 0" class="my-invalid-feedback">
                                                {{ lodash.first(errors) }}
                                            </div>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <!-- Notes -->
                                    <ValidationProvider :rules="{ max: 250 }" :name="`${$tc('message.note', 2)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.note', 2)}`">
                                            <b-form-textarea
                                                    id="note"
                                                    v-model="input.note"
                                                    rows="2"
                                                    :state="lodash.first(errors) ? false : null">
                                            </b-form-textarea>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="my-border-top" align-h="end">
                                <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">

                                    <b-button id="save"
                                              type="button"
                                              class="float-right ml-3 mt-2 b-c-green"
                                              :disabled="invalid || pending || !itemChanged"
                                              @click="save(false)"> {{$tc('message.save', 1)}}
                                    </b-button>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">

                                    <b-button id="save-and-exit"
                                              type="button"
                                              class="float-right ml-3 mt-2 b-c-green"
                                              :disabled="invalid || pending || !itemChanged"
                                              @click="save(true)"> {{$tc('message.saveClose', 1)}}
                                    </b-button>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                    <b-button id="cancel-button"
                                              type="button"
                                              class="float-right ml-3 mt-2"
                                              @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'

  export default {
    name: 'EditSection',
    created () {
      this.fetchData().then(() => {
        this.addWatcher()
      })
    },
    mounted () {
      this.$root.$on('change-lang', () => {
        this.$nextTick(() => {
          if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
        })
      })
    },
    data () {
      const sectionId = this.$route.params.id
      const lodash = _
      const backPath = '/registry/section'
      return {
        backPath,
        sectionId,
        lodash,
        input: {},
        watcher: null,
        itemChanged: false,
        structureOptions: []
      }
    },
    methods: {
      resetWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        this.itemChanged = false
      },
      addWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcher = this.$watch('input', () => {
          this.itemChanged = true
        }, { deep: true })
      },
      fetchData () {
        this.resetWatcher()
        return this.getOptions().then(() => {
          return this.getSection()
        })
      },
      getOptions () {
        const axiosArray = []
        axiosArray.push(this.getStructure())
        return Vue.myAll(axiosArray)
      },
      getStructure () {
        return Vue.myGet(this, '/struttura', 'strutture').then((structures) => {
          this.structureOptions = structures
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      getSection () {
        if (this.sectionId !== 'new') {
          return Vue.myGet(this, `/sezione/${this.sectionId}`, 'sezioni').then((section) => {
            this.input = section
            return true
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        } else {
          this.input = {}
          return new Promise(resolve => resolve())
        }
      },
      exitCheck (itemChanged) {
        if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
        else this.$router.push(this.backPath)
      },
      save (pushBack) {
        const editedItem = this.parseInputItem(_.cloneDeep(this.input))
        Vue.patchOrPost(this.sectionId !== 'new',
        `/sezione${this.sectionId !== 'new' ? '/' + this.sectionId : ''}`, editedItem).then(r => {
          Vue.success(this, `${this.$tc('message.section', 1)} ${this.$t(this.sectionId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          if (pushBack) this.$router.push(this.backPath)
          else this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditSection' } })
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      },
      parseInputItem (item) {
        const editedItem = {}
        _.forOwn(item, (val, field) => {
          editedItem[field] = val === 0 ? 0 : (val || null)
        })
        return editedItem
      },
      checkSelectStyle (ref, groupChanged, valid, errors, required) {
        Vue.MySelectValidationClass(this, ref, groupChanged, valid, errors, required)
      }
    }
  }
</script>

<style scoped>

</style>
