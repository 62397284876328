<template>
    <div>
        <ValidationObserver ref="form" v-slot="{ validate, invalid, pending }">
            <b-container fluid>
                <b-row>
                    <b-col class="mt-1" cols="12" sm="12" md="12" lg="12" xl="12">
                        <!-- Date start -->
                        <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.dateStart', 1)}`" v-slot="{ valid, errors }">
                            <b-form-group :label="`${$tc('message.dateStart', 1)} (*)`">
                                <b-input-group>
                                    <flat-pickr id="start-date-new-structure"
                                                ref="startDate"
                                                :class="getFlatPickrClass('startDate', valid, errors, itemChanged, true)"
                                                v-model="item.data_iscrizione"
                                                :config="newDateConfig">
                                    </flat-pickr>

                                    <div class="input-group-append">
                                        <b-button class="fas fa-trash"
                                                  @click="item.data_iscrizione = null"
                                                  :disabled="!item.data_iscrizione">
                                        </b-button>
                                    </div>

                                    <b-form-invalid-feedback id="inputLiveFeedback">
                                        {{ lodash.first(errors) }}
                                    </b-form-invalid-feedback>
                                </b-input-group>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col class="mt-1" cols="12" sm="12" md="12" lg="12" xl="12">
                        <!-- Discharge date -->
                        <ValidationProvider :rules="{ required: checkDateRanges(item.data_iscrizione) }" :name="`${$tc('message.discharge', 1)}`" v-slot="{ valid, errors }">
                            <b-form-group :label="`${$tc('message.discharge', 1)} ${ checkDateRanges(item.data_iscrizione) ? '(*)' : ''}`">
                                <b-input-group>
                                    <flat-pickr id="end-date-new-structure"
                                                ref="endDate"
                                                :class="getFlatPickrClass('endDate', valid, errors, itemChanged, checkDateRanges(item.data_iscrizione))"
                                                v-model="item.data_cancellazione"
                                                :config="newDischargeDateConfig">
                                    </flat-pickr>

                                    <div class="input-group-append">
                                        <b-button class="fas fa-trash"
                                                  @click="item.data_cancellazione = null"
                                                  :disabled="!item.data_cancellazione">
                                        </b-button>
                                    </div>
                                </b-input-group>

                                <div id="inputLiveFeedback" v-if="errors.length !== 0" class="my-invalid-feedback">
                                    {{ lodash.first(errors) }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col class="mt-1" cols="12" sm="12" md="12" lg="12" xl="12">
                        <!-- Structure -->
                        <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.structure', 1)} (*)`" v-slot="{ valid, errors }">
                            <b-form-group :label="`${$tc('message.structure', 1)} (*)`"
                                          :class="checkSelectStyle('newStructure', itemChanged, valid, errors, true)">
                                <v-select :options="structures"
                                          :reduce="obj => obj.id"
                                          ref="newStructure"
                                          label="den_sintetica"
                                          :disabled="!!item.id"
                                          :class="checkSelectStyle('newStructure', itemChanged, valid, errors, true)"
                                          v-model="item.id_struttura">
                                </v-select>

                                <div id="legalPerson-inputLiveFeedback" v-if="errors.length !== 0" class="my-invalid-feedback">
                                    {{ lodash.first(errors) }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                    </b-col>

                    <b-col class="mt-1" cols="12" sm="12" md="12" lg="12" xl="12">
                        <!-- Section -->
                        <b-form-group :label="`${$tc('message.section', 1)} `">
                            <v-select :options="customSectionOptions"
                                      ref="sectionFilter"
                                      :reduce="obj => obj.id"
                                      :disabled="!item.id_struttura"
                                      v-model="item.id_sezione"
                                      label="descrizione">
                            </v-select>
                        </b-form-group>
                    </b-col>
                </b-row>
            </b-container>

            <b-container class="p-0">
                <b-row class="my-border-top" align-h="end">
                    <b-col cols="auto">
                        <b-button id="nw-delete-continue"
                                  class="m-1 b-c-green"
                                  :disabled="invalid || pending || !itemChanged || !item.id_calendario"
                                  @click="saveStructureChild(item)">{{$t('message.saveClose')}}
                        </b-button>

                        <b-button id="nw-delete-close" class="m-1 mr-3" @click="hideChangeStructureModal()">{{$t('message.cancel')}}</b-button>
                    </b-col>
                </b-row>
            </b-container>
        </ValidationObserver>
    </div>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import { DateTime } from 'luxon'
  import { Italian } from 'flatpickr/dist/l10n/it.js'

  export default {
    name: 'ChangeStructureChild',
    props: ['childId', 'yearId', 'years', 'structures', 'calendars', 'sections', 'allStructureChild', 'currentStructureChild'],
    watch: {
      'item.id_struttura' (val) {
        this.customSectionOptions = this.getSectionOptions(this.currentStructureChild, this.sections, val, true)
        this.item.id_calendario = this.getCalendar(val)
        this.itemChanged = true
      },
      'item.data_iscrizione' (val) {
        this.itemChanged = true
        this.updateNewDateRange(val, this.item.data_cancellazione)
      },
      'item.data_cancellazione' (val) {
        this.itemChanged = true
        this.updateNewDateRange(this.item.data_iscrizione, val)
      },
      'item.id_sezione' () {
        this.itemChanged = true
      }
    },
    mounted () {
      this.updateData()
    },
    data () {
      const dateConfig = {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: '',
        maxDate: '',
        disable: []
      }
      const lodash = _
      return {
        lodash,
        DateTime,
        item: {
          id_struttura: null,
          id_anno_scolastico: null,
          id_calendario: null,
          data_cancellazione: null,
          data_iscrizione: null,
          id_bambino: null,
          id_sezione: null
        },
        customSectionOptions: [],
        structureOptions: [],
        itemChanged: false,
        newDateConfig: _.cloneDeep(dateConfig),
        newDischargeDateConfig: _.cloneDeep(dateConfig),
        allSections: []
      }
    },
    methods: {
      updateData () {
        if (this.currentStructureChild) {
          const editedItem = _.cloneDeep(this.currentStructureChild)
          editedItem.data_iscrizione = DateTime.fromISO(editedItem.data_iscrizione).toSQLDate()
          editedItem.data_cancellazione = editedItem.data_cancellazione
          ? DateTime.fromISO(editedItem.data_cancellazione).toSQLDate() : null
          this.item = editedItem
        }

        this.item.id_anno_scolastico = this.yearId
        this.item.id_bambino = this.childId
        this.allSections = this.sections

        this.updateNewDateRange(_.cloneDeep(this.item.data_iscrizione), _.cloneDeep(this.item.data_cancellazione))
      },
      updateNewDateRange (start, end) {
        const dateData = this.getDateData(this.years, this.item, this.allStructureChild, start, end)
        const disabledOptions = dateData.disabled
        const absoluteMin = dateData.absoluteMin
        const absoluteMax = dateData.absoluteMax
        const relativeStartMin = dateData.relativeStartMin
        const relativeStartMax = dateData.relativeStartMax
        const relativeEndMin = dateData.relativeEndMin
        const relativeEndMax = dateData.relativeEndMax

        this.newDateConfig.minDate = relativeStartMin || absoluteMin
        this.newDateConfig.maxDate = relativeStartMax || absoluteMax
        this.newDateConfig.disable = disabledOptions

        this.newDischargeDateConfig.minDate = relativeEndMin || absoluteMin
        this.newDischargeDateConfig.maxDate = relativeEndMax || absoluteMax
        this.newDischargeDateConfig.disable = disabledOptions
      },
      checkDateRanges (newStart) {
        const dateData = this.getDateData(this.years, this.item, this.allStructureChild)
        const disabledRanges = dateData.disabled && dateData.disabled.length !== 0 ? dateData.disabled : null
        const dischargeDateRequired = disabledRanges && newStart
        ? _.find(disabledRanges, obj =>
        obj.from && obj.to &&
        (DateTime.fromSQL(obj.from) >= DateTime.fromSQL(newStart) || DateTime.fromSQL(obj.to) >= DateTime.fromSQL(newStart)))
        : false
        return !!dischargeDateRequired
      },
      getDateData (years, currentAssociation, associations, start, end) {
        // This is a tested function, if is needed to change have a look in test
        const yearId = currentAssociation.id_anno_scolastico
        const foundedYear = _.find(years, obj => obj.id === yearId)
        const currentYear = foundedYear ? foundedYear.anno : DateTime.local().year
        const absoluteMin = `${currentYear}-09-01`
        const absoluteMax = `${currentYear + 1}-08-31`
        let relativeStartMin = absoluteMin
        let relativeStartMax = absoluteMax
        let relativeEndMin = absoluteMin
        let relativeEndMax = absoluteMax
        const disabledArrayDate = []

        if (associations && associations.length !== 0) {
          associations.forEach(item => {
            if (!currentAssociation || !currentAssociation.id || currentAssociation.id !== item.id) {
              const itemStart = DateTime.fromISO(item.data_iscrizione).isValid
              ? DateTime.fromISO(item.data_iscrizione) : DateTime.fromSQL(item.data_iscrizione)

              const itemEnd = item.data_cancellazione
              ? (DateTime.fromISO(item.data_cancellazione).isValid ? DateTime.fromISO(item.data_cancellazione) : DateTime.fromSQL(item.data_cancellazione))
              : DateTime.fromSQL(absoluteMax)

              disabledArrayDate.push({ from: itemStart.toSQLDate(), to: itemEnd.toSQLDate() })

              const startMin = end ? (itemEnd < DateTime.fromSQL(end) && itemEnd > DateTime.fromSQL(relativeStartMin) ? itemEnd.toSQLDate() : relativeStartMin) : relativeStartMin
              const startMax = end || relativeStartMax
              const endMin = start || relativeEndMin
              const endMax = start ? (itemStart > DateTime.fromSQL(start) ? itemStart.toSQLDate() : relativeEndMax) : relativeEndMax

              relativeStartMin = DateTime.fromSQL(relativeEndMin) > DateTime.fromSQL(startMin) ? relativeEndMin : startMin
              relativeStartMax = DateTime.fromSQL(relativeStartMax) < DateTime.fromSQL(startMax) ? relativeStartMax : startMax
              relativeEndMin = DateTime.fromSQL(relativeEndMin) > DateTime.fromSQL(endMin) ? relativeEndMin : endMin
              relativeEndMax = DateTime.fromSQL(relativeEndMax) < DateTime.fromSQL(endMax) ? relativeEndMax : endMax
            }
          })
        }
        return {
          disabled: disabledArrayDate,
          absoluteMin,
          absoluteMax,
          relativeStartMin,
          relativeStartMax,
          relativeEndMin,
          relativeEndMax
        }
      },
      getFlatPickrClass (ref, valid, errors, itemChanged, isRequired) {
        const nullClass = 'form-control'
        const falseClass = 'form-control is-invalid'
        const trueClass = 'form-control is-valid'
        const result = isRequired
        ? _.first(errors) && itemChanged ? falseClass : (valid ? (itemChanged ? trueClass : nullClass) : nullClass)
        : _.first(errors) ? falseClass : nullClass

        if (this.$refs && this.$refs[ref] && this.$refs[ref].$el && this.$refs[ref].$el.parentElement) {
          const el = this.$refs[ref].$el.parentElement.children && this.$refs[ref].$el.parentElement.children[1]
          el.className = result
        }

        return result
      },
      checkSelectStyle (ref, groupChanged, valid, errors, required) {
        Vue.MySelectValidationClass(this, ref, groupChanged, valid, errors, required)
      },
      hideChangeStructureModal () {
        this.$emit('close')
      },
      saveStructureChild (structureChild) {
        const editedItem = this.parseInputItem(_.cloneDeep(structureChild))
        this.$emit('save', editedItem)
      },
      parseInputItem (item) {
        const editedItem = {}
        const intFields = ['id', 'id_anno_scolastico', 'id_struttura', 'id_bambino', 'id_calendario', 'id_sezione']
        _.forOwn(item, (val, field) => {
          const mustBeInt = _.find(intFields, obj => obj === field)
          if (mustBeInt) {
            editedItem[field] = val === 0 ? 0 : (parseInt(val) || null)
          } else {
            editedItem[field] = val === 0 ? 0 : (val || null)
          }
        })
        return editedItem
      },
      getSectionOptions (currentAssociation, originalItems, structure, reset) {
        if (this.$refs.sectionFilter && reset) this.$refs.sectionFilter.clearSelection()
        const sections = _.filter(_.cloneDeep(originalItems), obj => obj.id_struttura === structure)
        if (currentAssociation && currentAssociation.id_sezione) {
          const foundedSection = _.find(sections, obj => obj.id === currentAssociation.id_sezione)
          if (foundedSection) this.item.id_sezione = foundedSection.id
        }
        return sections
      },
      getCalendar (structure) {
        const calendars = _.filter(this.calendars, obj => obj.id_struttura === structure)
        const foundedCalendar = calendars && calendars.length !== 0 ? _.find(calendars, obj => obj.standard) : null
        if (!foundedCalendar) Vue.warning(this, this.$tc('warnings.noCalendarForSelectedStructure', 1))
        return foundedCalendar ? foundedCalendar.id : null
      }
    }
  }
</script>

<style scoped>

</style>
