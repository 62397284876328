// router views
import SuperAdmin from '@/components/SuperAdmin'
import AdminHome from '@/components/AdminHome'
import Login from '@/components/Login'
import Page404 from '@/components/Page404'
import Logout from '@/components/Logout'
import RefreshPage from '@/components/RefreshPage'
import ResourcesUpdate from '@/components/ResourcesUpdate'

// registry folder
import LegalPerson from '@/components/registry/LegalPerson'
import EditLegalPerson from '@/components/registry/EditLegalPerson'
import Structure from '@/components/registry/Structure'
import EditStructure from '@/components/registry/EditStructure'
import Section from '@/components/registry/Section'
import EditSection from '@/components/registry/EditSection'
import Operator from '@/components/registry/Operator'
import EditOperator from '@/components/registry/EditOperator'
import Child from '@/components/registry/Child'
import EditChild from '@/components/registry/EditChild'
import Trip from '@/components/registry/Trip'
import EditTrip from '@/components/registry/EditTrip'
import Bus from '@/components/registry/Bus'
import EditBus from '@/components/registry/EditBus'

import _ from 'lodash'
import ReportTrip from '@/components/registry/ReportTrip'
import SchoolYear from '@/components/registry/SchoolYear.vue'
import EditSchoolYear from '@/components/registry/EditSchoolYear.vue'

const allItems = [
    {
        header: true,
        title: [
            { option: 'message.navigate' }
        ],
        hiddenOnCollapse: true
    },
    {
        title: 'Home',
        icon: 'fa fa-home',
        path: '/superadmin',
        component: SuperAdmin,
        componentName: 'SuperAdmin',
        meta: {
            roles: [1] // super admin
        }
    },
    {
        title: 'Home',
        icon: 'fa fa-home',
        path: '/admin',
        component: AdminHome,
        componentName: 'AdminHome',
        meta: {
            roles: [2] // admin
        }
    },
    {
        title: [
            { option: 'message.login', num: 1 }
        ],
        path: '/',
        component: Login,
        componentName: 'Login'
    },
    {
        title: [
            { option: 'message.registry', num: 2 }
        ],
        icon: 'fa fa-cogs',
        meta: {
            roles: [1, 2] // super admin, admin
        },
        child: [
          {
            title: [
              { option: 'message.schoolYears', num: 1 }
            ],
            path: '/registry/school_years',
            component: SchoolYear,
            componentName: 'SchoolYear',
            meta: {
              roles: [1] // super admin
            },
            child: [
              {
                title: [
                  { option: 'message.edit', num: 1 },
                  { option: 'message.schoolYears', num: 1 }
                ],
                path: '/registry/school_years/:id',
                component: EditSchoolYear,
                componentName: 'EditSchoolYear',
                meta: {
                  roles: [1] // super admin
                }
              }
            ]
          },
          {
                title: [
                    { option: 'message.legalPerson', num: 1 }
                ],
                path: '/registry/legal_person',
                component: LegalPerson,
                componentName: 'LegalPerson',
                meta: {
                    roles: [1] // super admin
                },
                child: [
                    {
                        title: [
                            { option: 'message.edit', num: 1 },
                            { option: 'message.legalPerson', num: 1 }
                        ],
                        path: '/registry/legal_person/:id',
                        component: EditLegalPerson,
                        componentName: 'EditLegalPerson',
                        meta: {
                            roles: [1] // super admin
                        }
                    }
                ]
            },
            {
                title: [
                    { option: 'message.structure', num: 1 }
                ],
                path: '/registry/structure',
                component: Structure,
                componentName: 'Structure',
                meta: {
                    roles: [1] // super admin
                },
                child: [
                    {
                        title: [
                            { option: 'message.edit', num: 1 },
                            { option: 'message.structure', num: 1 }
                        ],
                        path: '/registry/structure/:id',
                        component: EditStructure,
                        componentName: 'EditStructure',
                        meta: {
                            roles: [1] // super admin
                        }
                    }
                ]
            },
            {
                title: [
                    { option: 'message.section', num: 1 }
                ],
                path: '/registry/section',
                component: Section,
                componentName: 'Section',
                meta: {
                    roles: [1] // super admin
                },
                child: [
                    {
                        title: [
                            { option: 'message.edit', num: 1 },
                            { option: 'message.section', num: 1 }
                        ],
                        path: '/registry/section/:id',
                        component: EditSection,
                        componentName: 'EditSection',
                        meta: {
                            roles: [1] // super admin
                        }
                    }
                ]
            },
            {
                title: [
                    { option: 'message.operator', num: 1 }
                ],
                path: '/registry/operator',
                component: Operator,
                componentName: 'Operator',
                meta: {
                    roles: [1] // super admin
                },
                child: [
                    {
                        title: [
                            { option: 'message.edit', num: 1 },
                            { option: 'message.operator', num: 1 }
                        ],
                        path: '/registry/operator/:id',
                        component: EditOperator,
                        componentName: 'EditOperator',
                        meta: {
                            roles: [1] // super admin
                        }
                    }
                ]
            },
            {
                title: [
                    { option: 'message.child', num: 1 }
                ],
                path: '/registry/child',
                component: Child,
                componentName: 'Child',
                meta: {
                    roles: [2] // admin
                },
                child: [
                    {
                        title: [
                            { option: 'message.edit', num: 1 },
                            { option: 'message.child', num: 1 }
                        ],
                        path: '/registry/child/:id',
                        component: EditChild,
                        componentName: 'EditChild',
                        meta: {
                            roles: [2] // admin
                        }
                    }
                ]
            },
            {
                title: [
                    { option: 'message.trip', num: 1 }
                ],
                path: '/registry/trip',
                component: Trip,
                componentName: 'Trip',
                meta: {
                    roles: [2] // admin
                },
                child: [
                    {
                        title: [
                            { option: 'message.edit', num: 1 },
                            { option: 'message.trip', num: 1 }
                        ],
                        path: '/registry/trip/:id',
                        component: EditTrip,
                        componentName: 'EditTrip',
                        meta: {
                            roles: [2] // admin
                        }
                    }
                ]
            },
            {
                title: [
                    { option: 'message.bus', num: 1 }
                ],
                path: '/registry/bus',
                component: Bus,
                componentName: 'Bus',
                meta: {
                    roles: [1, 2] // superadmin and admin
                },
                child: [
                    {
                        title: [
                            { option: 'message.edit', num: 1 },
                            { option: 'message.bus', num: 1 }
                        ],
                        path: '/registry/bus/:id',
                        component: EditBus,
                        componentName: 'EditBus',
                        meta: {
                            roles: [1, 2] // superadmin and admin
                        }
                    }
                ]
            }
        ]
    },
    {
        title: [
            { option: 'message.report' }
        ],
        icon: 'fa fa-file',
        meta: {
            roles: [2] // admin
        },
        path: '/report',
        component: ReportTrip,
        componentName: 'ReportTrip'
    },
    {
        title: [
            { option: 'message.logout', num: 1 }
        ],
        icon: 'fas fa-sign-out-alt',
        path: '/logout',
        component: Logout,
        componentName: 'Logout'
    },
    {
        title: [
            { option: 'message.resource' }
        ],
        path: '/permessi',
        component: ResourcesUpdate,
        componentName: 'ResourcesUpdate',
        meta: {
            hideLink: true
        }
    }
]

const routes = []

allItems.forEach((group) => {
    if (!group.meta || (group.meta && !group.meta.hideRoute)) {
        getRoutes(group)
    }
})

routes.push({ path: '*', name: 'Page404', component: Page404 })
routes.push({ path: '/redirect', name: 'RefreshPage', component: RefreshPage })

function getRoutes (collection) {
    if (collection.path && collection.componentName && collection.component) {
        if (!collection.meta || (collection.meta && !collection.meta.hideRoute)) {
            routes.push({
                path: collection.path,
                name: collection.componentName,
                component: collection.component,
                meta: collection.meta ? collection.meta : {}
            })
        }
    }
    if (collection.child) {
        collection.child.forEach((child) => {
            getRoutes(child)
        })
    }
}

function getSidebarItems (it, collection, roleId) {
    const editedCollection = {}
    if (collection && collection.title) {
        let name = ''
        if (collection.title && Array.isArray(collection.title)) {
            collection.title.forEach((nameChunk, index) => {
                if (it._i18n) {
                    if (typeof nameChunk.num === 'number' && index === 0) {
                        name += `${it.$tc(nameChunk.option, nameChunk.num)} `
                    } else if (typeof nameChunk.num === 'number' && index !== 0) {
                        name += `${it.$tc(nameChunk.option, nameChunk.num).toLowerCase()} `
                    } else if (isNaN(nameChunk.num) && index !== 0 && !nameChunk.disableLowerCase) {
                        name += `${it.$t(nameChunk.option).toLowerCase()} `
                    } else {
                        name += `${it.$t(nameChunk.option)} `
                    }
                }
            })
        } else {
            name = collection.title
        }
        editedCollection.title = name
    }
    if (collection && collection.icon) {
        editedCollection.icon = collection.icon
    }
    if (collection && collection.path) {
        editedCollection.href = collection.path
    }
    if (collection && collection.header) {
        editedCollection.header = collection.header
    }
    if (collection && collection.hiddenOnCollapse) {
        editedCollection.hiddenOnCollapse = collection.hiddenOnCollapse
    }

    const children = []
    if (collection && collection.child) {
        collection.child.forEach((child) => {
            const foundedRole = child && child.meta && child.meta.roles ? !!_.find(child.meta.roles, obj => obj === roleId) : true
            if (!child.meta || (child.meta && !child.meta.hideRoute && !child.meta.hideLink && foundedRole)) {
                const foundedChild = getSidebarItems(it, child, roleId)
                if (foundedChild) {
                    children.push(foundedChild)
                }
            }
        })
    }
    if (children.length !== 0) {
        editedCollection.child = children
    }

    let isDynamicRoute = false
    if (collection && collection.path) {
        const pathSections = collection.path.split('/')
        pathSections.forEach((pathSection) => {
            if (pathSection === ':id') {
                isDynamicRoute = true
            }
        })
    }

    if (!isDynamicRoute && collection && collection.path !== '/') {
        if (collection.meta && collection.meta.roles) {
            const foundedRole = _.find(collection.meta.roles, obj => obj === roleId)
            if (!foundedRole) return null
        }
        return editedCollection.title ? editedCollection : null
    }
    return null
}

function getFooterButtons (it, allItems, roleId) {
    const buttons = []
    allItems.forEach((item) => {
        if (item.title && item.path && item.path !== '/') {
            const roles = item && item.meta && item.meta.roles
            const roleEnabled = roles ? _.find(roles, obj => obj === roleId) : null
            if (!item.meta || (item.meta && roleEnabled && !item.meta.hideRoute)) {
                buttons.push({ title: item.title, href: `${item.path}` })
            }
        }
    })
    return buttons
}

export default {
    allItems,
    routes,
    footerButtons: (it, roleId) => getFooterButtons(it, allItems, roleId),
    sidebarItems: (it, roleId) => {
        const results = []
        allItems.forEach((collection) => {
            if (!collection.meta || (collection.meta && !collection.meta.hideRoute && !collection.meta.hideLink)) {
                const result = getSidebarItems(it, collection, roleId)
                if (result) {
                    results.push(result)
                }
            }
        })
        return results
    }
}
