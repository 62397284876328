/* eslint-disable no-param-reassign */

const MyTableFilter = {
  install (Vue) {
    Vue.myTableFilter = (items, fields, filter) => {
      const editedItems = []
      items.forEach((item) => {
        let pushed = false
        fields.forEach((field) => {
          let itemValue = field.key && item[field.key] ? JSON.stringify(item[field.key]).toLowerCase() : ''
          if (itemValue.indexOf(filter.toLowerCase()) > -1 && !pushed) {
            editedItems.push(item)
            pushed = true
          }
        })
      })
      return editedItems
    }
  }
}

export default MyTableFilter
