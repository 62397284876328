<template>
  <b-container :class="containerClass">
    <b-row>
      <b-col :cols="innerBeforeSearch.cols" :sm="innerBeforeSearch.sm"
             :md="innerBeforeSearch.md" :lg="innerBeforeSearch.lg" :xl="innerBeforeSearch.xl">
        <slot name="before-search">
        </slot>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="innerRowBeforeSearch.cols"
             :sm="innerRowBeforeSearch.sm"
             :md="innerRowBeforeSearch.md"
             :lg="innerRowBeforeSearch.lg"
             :xl="innerRowBeforeSearch.xl"
             :order="innerRowBeforeSearch.order"
             :order-sm="innerRowBeforeSearch.orderSm"
             :order-md="innerRowBeforeSearch.orderMd"
             :order-lg="innerRowBeforeSearch.orderLg">
        <slot name="row-before-search"/>
      </b-col>

      <b-col :cols="innerRowSearch.cols"
             :sm="innerRowSearch.sm"
             :md="innerRowSearch.md"
             :lg="innerRowSearch.lg"
             :xl="innerRowSearch.xl"
             :order="innerRowSearch.order"
             :order-sm="innerRowSearch.orderSm"
             :order-md="innerRowSearch.orderMd"
             :order-lg="innerRowSearch.orderLg">
        <b-form-group>
          <b-input-group>
            <b-input-group-prepend class="border-0">
              <div class="b-c-green form-control search-icon">
                <i class="fa fa-search c-white"/>
              </div>
            </b-input-group-prepend>

            <b-form-input v-model="filter" class="border-left-0"
                          id="nw-search"
                          :placeholder="searchPlaceHolder || $tc('message.search', 1)">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''" class="b-c-green nw-clear-search">
                <i class="fa fa-trash c-white"/>
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col v-if="showSelectAll" cols="12" sm="12" md="12" lg="12" xl="12">
        <b-container>
          <b-row align-h="start" class="mb-1">
            <b-button v-on:click="selectAll(!allChecked)"
                      class="b-c-green mb-2"
                      v-if="showSelection">
              {{allChecked ? `${$t('message.unselect')} ${$t('message.all').toLowerCase()}` : `${$t('message.select')} ${$t('message.all').toLowerCase()}`}}
            </b-button>
          </b-row>
        </b-container>
      </b-col>

      <b-col :cols="innerRowAfterSearch.cols"
             :sm="innerRowAfterSearch.sm"
             :md="innerRowAfterSearch.md"
             :lg="innerRowAfterSearch.lg"
             :xl="innerRowAfterSearch.xl"
             :order="innerRowAfterSearch.order"
             :order-sm="innerRowAfterSearch.orderSm"
             :order-md="innerRowAfterSearch.orderMd"
             :order-lg="innerRowAfterSearch.orderLg"
             :order-xl="innerRowAfterSearch.orderXl">
        <slot name="row-after-search"/>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="innerAfterSearch.cols" :sm="innerAfterSearch.sm"
             :md="innerAfterSearch.md" :lg="innerAfterSearch.lg" :xl="innerAfterSearch.xl">
        <slot name="after-search">
        </slot>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <div class="tableContainer">
          <b-table :id="idTable"
                   responsive="true"
                   :ref="refTable"
                   :empty-text="$t('message.emptyText')"
                   :empty-filtered-text="$t('message.emptyFilteredText')"
                   data-filter-control="true"
                   striped
                   show-empty
                   fixed
                   :busy.sync="isBusy"
                   :items="componentItems"
                   :thead-tr-class="theadTrClass"
                   :stacked="stackedModeOn"
                   :fields="componentFields"
                   :current-page="currentPage"
                   :per-page="perPage"
                   :no-local-sorting="true"
                   :no-provider-paging="true"
                   :no-provider-sorting="true"
                   :no-provider-filtering="true"
                   @context-changed="checkItems"
                   @row-clicked="watchItem(componentItems)"
                   :sort-compare="null"
                   :class="stackedModeOn ? (size === 'cols' ? 'stackedStyleCols' : 'stackedStyle') : 'notStackedStyle'"
          >
            <template v-for="field in customFields || []" v-slot:[getCustomField(field)]="row">
              <slot :name="field.key" :item="row.item">
              </slot>
            </template>

            <template v-slot:cell(select)="row">

              <b-form-checkbox :disabled="disableAllCheckboxes" @click.native.stop v-on:change="itemSelected(row.item, $event)"
                               :size="stackedModeOn ? 'sm' : 'md'" v-model="row.item.selected">
              </b-form-checkbox>
            </template>

            <template v-if="componentItems.length > 0" v-slot:head(select)>
              <b-form-group
                            label-for="checkAllBox">
              <b-form-checkbox id="checkAllBox" :disabled="disableAllCheckboxes" @click.native.stop v-on:change="selectAll(!allChecked)" v-model="allChecked"
                               :size="stackedModeOn ? 'sm' : 'md'" ref="checkAllBox">
              </b-form-checkbox>
              </b-form-group>
            </template>
            <template v-slot:cell(edit)="row">
              <b-button :disabled="row.item.data !== undefined && new Date(row.item.data).setHours(0,0,0,0) < new Date().setHours(0,0,0,0)" id="nw-pencil" class="fas fa-pencil-alt" @click="$emit('rowEdit', row)">
              </b-button>
            </template>

            <template v-slot:cell(delete)="row">
              <b-button class="fas fa-trash-alt" @click="$emit('rowDelete', row)">
              </b-button>
            </template>

            <template v-slot:cell(info)="row">
              <b-button class="fas fa-eye" @click="$emit('rowInfo', row)">
              </b-button>
            </template>

            <template v-slot:cell(stacked-mode)="row">
              <b-button v-on:click="changeSelection(row.item.selected).then((result) => { row.item.selected = result })"
                        :true-value="row.item.selected ? 'true' : 'false'" v-if="showSelection" class="ml-1 select-button">
                {{row.item.selected ?  `${$t('message.unselect')}` :  `${$t('message.select')}`}}
              </b-button>

              <b-button class="mr-2 fas fa-pencil-alt" @click="$emit('rowEdit', row)" v-if="showEdit">
              </b-button>

              <b-button class="mr-2 fas fa-trash-alt" @click="$emit('rowDelete', row)" v-if="showDelete">
              </b-button>

              <b-button class="mr-2 fas fa-eye" @click="$emit('rowInfo', row)" v-if="showInfo">
              </b-button>
            </template>
          </b-table>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="innerBeforePagination.cols" :sm="innerBeforePagination.sm"
             :md="innerBeforePagination.md" :lg="innerBeforePagination.lg" :xl="innerBeforePagination.xl">
        <slot name="before-pagination">
        </slot>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="innerRowBeforePagination.cols" :sm="innerRowBeforePagination.sm"
             :md="innerRowBeforePagination.md" :lg="innerRowBeforePagination.lg" :xl="innerRowBeforePagination.xl">
        <slot name="row-before-pagination">
        </slot>
      </b-col>

      <b-col :cols="innerRowPagination.cols" :sm="innerRowPagination.sm"
             :md="innerRowPagination.md" :lg="innerRowPagination.lg" :xl="innerRowPagination.xl">
        <b-pagination id="table-pagination" :total-rows="componentItems.length" :per-page="perPage" v-model="currentPage" :size="stackedModeOn ? 'sm' : 'md'"
                      :class="noPaginationMargin ? 'm-0' : ''">
        </b-pagination>
      </b-col>

      <b-col :cols="innerRowBetweenPagination.cols" :sm="innerRowBetweenPagination.sm"
             :md="innerRowBetweenPagination.md" :lg="innerRowBetweenPagination.lg" :xl="innerRowBetweenPagination.xl">
        <slot name="row-between-pagination">
        </slot>
      </b-col>

      <b-col :cols="innerRowPerPage.cols" :sm="innerRowPerPage.sm"
             :md="innerRowPerPage.md" :lg="innerRowPerPage.lg" :xl="innerRowPerPage.xl">
        <b-form-group horizontal :label="`${$tc('message.row', 2)}:`" class="mb-0" :label-cols="5">
          <b-form-select :options="pageOptions" v-model="perPage"/>
        </b-form-group>
      </b-col>

      <b-col :cols="innerRowAfterPerPage.cols" :sm="innerRowAfterPerPage.sm"
             :md="innerRowAfterPerPage.md" :lg="innerRowAfterPerPage.lg" :xl="innerRowAfterPerPage.xl">
        <slot name="row-after-per-page">
        </slot>
      </b-col>
    </b-row>

    <b-row>
      <b-col :cols="innerAfterPagination.cols" :sm="innerAfterPagination.sm"
             :md="innerAfterPagination.md" :lg="innerAfterPagination.lg" :xl="innerAfterPagination.xl">
        <slot name="after-pagination">
        </slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
  import Vue from 'vue'
  const _ = require('lodash')

  export default {
    name: 'StandardTable',
    props: [
      // main table props
      'id-table', 'ref-table',
      'items', 'fields',
      'theadTrClass', 'fullWidth', 'noContainerPadding',
      'search-id', 'customFields', 'disableAutoUnSelection',
      'noPaginationMargin',
      // props before table
      'search-label-horizontal', 'search-place-holder',
      'before-search', 'row-before-search', 'row-search', 'row-after-search', 'after-search',
      // props after table
      'before-pagination', 'row-before-pagination', 'row-pagination', 'row-between-pagination',
      'row-per-page', 'row-after-per-page', 'after-pagination'
    ],
    created () {
      const editedFields = []
      this.fields.forEach((field) => {
        if (!field.hideInTable) {
          editedFields.push(field)
        }
      })
      this.originalItems = this.items
      this.originalFields = this.fields
      this.componentFields = this.getFields(editedFields, this.customFields)
      this.checkWindowSize()
      this.resetWatcher()
      this.addWatcher()
    },
    mounted () {
      this.$nextTick(() => {
        this.$emit('mounted')
      })
      this.componentItems = this.items
      this.checkItems({ sortBy: this.sortBy, sortDesc: this.sortDesc })
      this.containerClass = this.getContainerClass()
      this.$root.$on('reset-links', () => this.checkWindowSize())
      this.$root.$on('resize', () => this.checkWindowSize())
    },
    beforeDestroy () {
      this.resetWatcher()
    },

    watch: {
      filter (newVal) {
        this.filter = newVal
        this.componentItems = []
        if (newVal) {
          this.componentItems = Vue.myTableFilter(this.originalItems, this.originalFields, newVal)
        } else {
          this.componentItems = this.originalItems
        }
      },
      items (newVal) {
        this.componentItems = newVal
        this.originalItems = newVal
        this.checkWindowSize()
        if (this.filter) {
          this.componentItems = Vue.myTableFilter(this.originalItems, this.originalFields, this.filter)
        }
      },
      fields (newVal) {
        const editedFields = []
        newVal.forEach((field) => {
          if (!field.hideInTable) {
            editedFields.push(field)
          }
        })
        this.componentFields = this.getFields(editedFields, this.customFields)
        this.checkWindowSize()
      },
      perPage (newVal) {
        this.allChecked = Vue.checkAllSelected(this.componentItems, newVal, this.currentPage)
        if (this.filter) {
          this.componentItems = Vue.myTableFilter(this.originalItems, this.originalFields, this.filter)
        }
      },
      currentPage (newVal) {
        this.allChecked = Vue.checkAllSelected(this.componentItems, this.perPage, newVal)
      },
      beforeSearch (val) { this.innerBeforeSearch = { cols: val.cols || 12, sm: val.sm || 12, md: val.md || 12, lg: val.lg || 12, xl: val.xl || 12 } },
      rowBeforeSearch (val) {
        this.innerRowBeforeSearch = {
          cols: val.cols || 12,
          sm: val.sm || 12,
          md: val.md || 12,
          lg: val.lg || 12,
          xl: val.xl || 12,
          order: val.order || 1,
          orderSm: val.order || 1,
          orderMd: val.orderMd || 1,
          orderLg: val.orderLg || 1,
          orderXl: val.orderXl || 1
        }
      },
      rowSearch (val) {
        this.innerRowSearch = {
          cols: val.cols || 12,
          sm: val.sm || 12,
          md: val.md || 12,
          lg: val.lg || 12,
          xl: val.xl || 12,
          order: val.order || 2,
          orderSm: val.order || 2,
          orderMd: val.orderMd || 2,
          orderLg: val.orderLg || 2,
          orderXl: val.orderXl || 2
        }
      },
      rowAfterSearch (val) {
        this.innerRowAfterSearch = {
          cols: val.cols || 12,
          sm: val.sm || 12,
          md: val.md || 12,
          lg: val.lg || 12,
          xl: val.xl || 12,
          order: val.order || 3,
          orderSm: val.order || 3,
          orderMd: val.orderMd || 3,
          orderLg: val.orderLg || 3,
          orderXl: val.orderXl || 3
        }
      },
      afterSearch (val) { this.innerAfterSearch = { cols: val.cols || 12, sm: val.sm || 12, md: val.md || 12, lg: val.lg || 12, xl: val.xl || 12 } },
      beforePagination (val) { this.innerBeforePagination = { cols: val.cols || 12, sm: val.sm || 12, md: val.md || 12, lg: val.lg || 12, xl: val.xl || 12 } },
      rowBeforePagination (val) { this.innerRowBeforePagination = { cols: val.cols || 12, sm: val.sm || 12, md: val.md || 12, lg: val.lg || 12, xl: val.xl || 12 } },
      rowPagination (val) { this.innerRowPagination = { cols: val.cols || 10, sm: val.sm || 10, md: val.md || 8, lg: val.lg || 7, xl: val.xl || 7 } },
      rowBetweenPagination (val) { this.innerRowBetweenPagination = { cols: val.cols || 12, sm: val.sm || 12, md: val.md || 1, lg: val.lg || 1, xl: val.xl || 1 } },
      rowPerPage (val) { this.innerRowPerPage = { cols: val.cols || 12, sm: val.sm || 12, md: val.md || 4, lg: val.lg || 3, xl: val.xl || 3 } },
      rowAfterPerPage (val) { this.innerRowAfterPerPage = { cols: val.cols || 12, sm: val.sm || 12, md: val.md || 12, lg: val.lg || 12, xl: val.xl || 12 } },
      afterPagination (val) { this.innerAfterPagination = { cols: val.cols || 12, sm: val.sm || 12, md: val.md || 12, lg: val.lg || 12, xl: val.xl || 12 } }
    },
    data () {
      return {
        componentItems: [],
        originalItems: [],
        componentFields: [],
        originalFields: [],
        isBusy: false,
        filter: null,
        currentPage: 1,
        pageOptions: [5, 10, 25, 50],
        perPage: 10,
        allChecked: false,
        showSelectAll: false,
        size: '',
        watcher: null,
        containerClass: '',
        sortBy: null,
        sortDesc: null,
        stackedModeOn: true,
        showSelection: false,
        showEdit: false,
        showDelete: false,
        showInfo: false,
        innerBeforeSearch: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        innerRowBeforeSearch: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12, order: 1, orderSm: 1, orderMd: 1, orderLg: 1, orderXl: 1 },
        innerRowSearch: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12, order: 2, orderSm: 2, orderMd: 2, orderLg: 2, orderXl: 2 },
        innerRowAfterSearch: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12, order: 3, orderSm: 3, orderMd: 3, orderLg: 3, orderXl: 3 },
        innerAfterSearch: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        innerBeforePagination: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        innerRowBeforePagination: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        innerRowPagination: { cols: 10, sm: 10, md: 8, lg: 7, xl: 7 },
        innerRowBetweenPagination: { cols: 12, sm: 12, md: 1, lg: 1, xl: 1 },
        innerRowPerPage: { cols: 12, sm: 12, md: 4, lg: 3, xl: 3 },
        innerRowAfterPerPage: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 },
        innerAfterPagination: { cols: 12, sm: 12, md: 12, lg: 12, xl: 12 }
      }
    },
    methods: {
      resetWatcher () {
        if (this.watcher) {
          this.watcher()
        }
      },
      addWatcher () {
        this.watcher = this.$watch('componentItems', (newVal) => {
          this.allChecked = Vue.checkAllSelected(newVal, this.perPage, this.currentPage)
        }, { deep: true })
      },
      selectAll (allChecked) {
        this.resetWatcher()
        const newValue = Vue.mySelectAll(this.componentItems, allChecked, this.perPage, this.currentPage, this.disableAutoUnSelection)
        this.componentItems = []
        this.addWatcher()
        this.componentItems = newValue
        this.$emit('allSelected', newValue)
      },
      checkItems (ctx, newItems) {
        if (ctx.sortBy) {
          const foundedField = _.find(this.componentFields, obj => obj.key === ctx.sortBy)
          this.sortBy = ctx.sortBy
          this.sortDesc = ctx.sortDesc
          this.resetWatcher()
          const result = Vue.myTableSorted(newItems || this.componentItems, ctx.sortBy, ctx.sortDesc, foundedField && foundedField.isDate)

          this.componentItems = []

          this.allChecked = Vue.checkAllSelected(result, this.perPage, this.currentPage)

          this.componentItems = _.cloneDeep(result)
          this.addWatcher()
        }
      },
      checkWindowSize () {
        let size = Vue.myGetWindowSize()
        let activeStackedSelectAll = false
        const editedFields = []
        this.size = size
        this.showSelection = false
        this.showEdit = false
        this.showDelete = false
        this.showInfo = false
        const stackedModeOn = (size === 'sm' || size === 'cols' || size === 'md')
        this.stackedModeOn = stackedModeOn
        this.fields.forEach((field) => {
          if (field.key === 'selected' && stackedModeOn) {
            activeStackedSelectAll = true
            this.showSelection = true
          } else if (field.key === 'delete' && stackedModeOn) {
            this.showDelete = true
          } else if (field.key === 'edit' && stackedModeOn) {
            this.showEdit = true
          } else if (field.key === 'info' && stackedModeOn) {
            this.showInfo = true
          } else if (!field.hideInTable) {
            editedFields.push(field)
          }
        })
        if (activeStackedSelectAll) {
          this.showSelectAll = stackedModeOn
        }
        if (stackedModeOn) {
          editedFields.push({ key: 'stacked-mode', label: '' })
        }
        this.componentFields = this.getFields(editedFields, this.customFields)
      },
      watchItem (componentItems) {
        this.componentItems = []
        this.componentItems = componentItems
      },
      getContainerClass () {
        let classResult = ''
        classResult += this.fullWidth ? 'mw-100 ' : ''
        classResult += this.noContainerPadding ? 'p-0 ' : ''
        return classResult
      },
      changeSelection (selected) {
        return new Promise((resolve) => {
          return resolve(selected !== null ? !selected : false)
        })
      },
      checkSelected () {
        const stackedModeOn = this.stackedModeOn
        const checkedRows = stackedModeOn ? document.querySelectorAll('button[true-value="true"]') : document.querySelectorAll('div[true-value="true"]')
        const uncheckedRows = stackedModeOn ? document.querySelectorAll('button[true-value="false"]') : document.querySelectorAll('div[true-value="false"]')
        if (checkedRows && checkedRows.length !== 0) {
          checkedRows.forEach((checkbox) => {
            if (checkbox.parentNode) {
              const firstParent = checkbox.parentNode
              if (firstParent.parentNode) {
                const secondParent = firstParent.parentNode
                if (secondParent.parentNode) {
                  const rowIndex = checkbox.parentNode.parentNode.parentNode.getAttribute('aria-rowindex')
                  if (rowIndex) {
                    checkbox.parentNode.parentNode.parentNode.className = stackedModeOn ? (rowIndex % 2 === 0 ? 'c-green-opacity c-white' : 'b-c-green c-white') : ''
                  } else {
                    checkbox.parentNode.parentNode.parentNode.className = stackedModeOn ? 'b-c-green c-white' : ''
                  }
                }
              }
            }
          })
        }

        if (uncheckedRows && uncheckedRows.length !== 0) {
          uncheckedRows.forEach((checkbox) => {
            if (checkbox.parentNode) {
              const firstParent = checkbox.parentNode
              if (firstParent.parentNode) {
                const secondParent = firstParent.parentNode
                if (secondParent.parentNode) {
                  checkbox.parentNode.parentNode.parentNode.className = ''
                }
              }
            }
          })
        }

        this.checkTableRows()
      },
      checkTableRows () {
        const flexibleRow = document.querySelectorAll('td[data-label=""]')
        if (flexibleRow && flexibleRow.length !== 0) {
          flexibleRow.forEach((row) => {
            row.className = this.stackedModeOn ? 'flexible-row' : ''
          })
        }
      },
      getFields (componentFields, customFields) {
        let editedFields = componentFields
        if (customFields && customFields.length !== 0) {
          customFields.forEach((field) => {
            if (field.position) {
              const p1 = _.slice(editedFields, 0, field.position)
              const p2 = _.slice(editedFields, field.position, editedFields.length)
              p1.push(field)
              editedFields = _.concat(p1, p2)
            } else {
              editedFields.push(field)
            }
          })
        }

        return editedFields
      },
      excel (fileName, customFields, customItems) {
        return Vue.excel(customFields || this.fields, customItems || this.componentItems, fileName)
      },
      resetSearch () {
        this.filter = null
        this.componentItems = this.originalItems
      },
      itemSelected (item, val) {
        item.selected = val
        this.$emit('rowSelected', item)
        this.allChecked = Vue.checkAllSelected(this.componentItems, this.perPage, this.currentPage)
        this.checkSelected()
      },
      getCustomField (field) {
        return `cell(${field.key})`
      }
    }
  }
</script>

<style scoped>
  .search-icon {
    height: 100%;
    width: 100%;
    display: inline-table;
    align-items: center;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  .nw-clear-search {
    height: 100%;
    width: 100%;
    display: inline-table;
    align-items: center;
  }
</style>
