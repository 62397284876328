<template>
    <b-container class="bus">
        <b-card class="mt-4 mb-4">
            <standard-table ref="table"
                            :items="items"
                            :fields="getFields()"
                            :theadTrClass="'first-2-table-cols'"

                            :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                            :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                            :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                            :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                            :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                            :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                            :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"

                            fullWidth="true"
                            noContainerPadding="true"
                            @rowEdit="pushToEdit"
                            @rowDelete="deleteItem">

                <b-container slot="row-before-search" class="mw-100 p-0">
                    <b-row align-h="end">
                        <b-col cols="auto">
                            <b-button v-on:click="pushToEdit()" class="mb-3 b-c-blue" id="add-bus">
                                {{$t('message.add')}}
                            </b-button>
                        </b-col>
                    </b-row>
                </b-container>

                <b-container slot="row-after-per-page" class="mw-100 p-0">
                    <b-row>
                        <b-col>
                            <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button" @click="$refs.table.excel($tc('message.bus', 2))">Excel</b-button>
                        </b-col>
                    </b-row>
                </b-container>
            </standard-table>
        </b-card>
    </b-container>
</template>

<script>
  import Vue from 'vue'

  export default {
    name: 'Bus',
    created () {
      this.fetchData()
    },
    updated () {
      this.$root.$emit('checkExcel')
    },
    data () {
      return {
        items: []
      }
    },
    methods: {
      fetchData () {
        this.$root.$emit('activeLoader', true)
        this.getBus()
      },
      getBus () {
        this.items = []
        Vue.myGet(this, '/bus/mezzo', 'mezzi').then((buses) => {
          this.items = buses
          this.$root.$emit('activeLoader', false)
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      },
      getFields () {
        return [
          { key: 'edit', label: '' },
          { key: 'delete', label: '' },
          { key: 'targa', label: `${this.$tc('message.plateNumber', 1)}`, sortable: true, sortDirection: 'desc' },
          { key: 'descrizione', label: `${this.$tc('message.description', 1)}`, sortable: true, sortDirection: 'desc' }
        ]
      },
      pushToEdit (event) {
        const item = event && event.item
        item && item.id ? this.$router.push({
          name: 'EditBus',
          params: { id: item.id }
        }) : this.$router.push({
          name: 'EditBus',
          params: { id: 'new' }
        })
      },
      deleteItem (event) {
        const item = event && event.item
        if (item && item.id) {
          this.$root.$emit('deleteItem', { description: item.targa, path: `/bus/mezzo/${item.id}`, redirect: this.$route.path })
        }
      }
    }
  }
</script>
