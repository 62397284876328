<template>
  <b-container fluid :class="isMobile ? 'p-0 m-0' : ''">
    <div class="modal-dialog" :class="!showStopEditing ? 'modal-xl' : ''"
         :style="showStopEditing ? 'min-width: 100%' :  ''">
      <div class="modal-content">
        <header class="modal-header">
          <b-container fluid class=" p-0 m-0 ">
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="9" xl="9">
                <h5 class="my-modal-title">{{ $tc('message.trip', 1) }}</h5>
              </b-col>
            </b-row>
          </b-container>
        </header>

        <div :class="isMobile ? 'modal-body p-1' : 'modal-body'">
          <b-container fluid>
            <ValidationObserver ref="mainForm" v-slot="{ validate, invalid, pending }">
              {{ checkTripSave(invalid, pending, !itemChanged, showStopEditing) }}
              <b-row>
                <b-col cols="12" sm="12" md="6" lg="4" xl="4"
                       order="1" order-sm="1" order-md="1" order-lg="1">
                  <!-- Name -->
                  <ValidationProvider :rules="{ required: true, max: 30 }" :name="`${$tc('message.name', 1)} (*)`"
                                      v-slot="{ valid, errors }">
                    <b-form-group :label="`${$tc('message.name', 1)} (*)`">
                      <b-form-input id="name"
                                    type="text"
                                    v-model="input.nome"
                                    :disabled="!!showStopEditing"
                                    :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                      </b-form-input>

                      <b-form-invalid-feedback id="inputLiveFeedback">
                        {{ lodash.first(errors) }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12" sm="12" md="12" lg="5" xl="5"
                       order="2" order-sm="2" order-md="3" order-lg="2">
                  <!-- Description -->
                  <ValidationProvider :rules="{ required: true, max: 50 }"
                                      :name="`${$tc('message.description', 1)} (*)`" v-slot="{ valid, errors }">
                    <b-form-group :label="`${$tc('message.description', 1)} (*)`">
                      <b-form-input id="description"
                                    type="text"
                                    :disabled="!!showStopEditing"
                                    v-model="input.descrizione"
                                    :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                      </b-form-input>

                      <b-form-invalid-feedback id="inputLiveFeedback">
                        {{ lodash.first(errors) }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>

                <b-col cols="12" sm="12" md="6" lg="3" xl="3" class="text-center"
                       order="3" order-sm="3" order-md="2" order-lg="3">
                  <b-form-group :label="$tc('message.tripStatus', 1)"
                                label-for="input-executed">
                    <switch-button v-model="input.attiva"
                                   :color="{checked: '#007A79', unchecked: '#dc3545'}"
                                   :sync="true"
                                   :width="150"
                                   :height="32"
                                   :disabled="!!showStopEditing"
                                   :font-size="15.5"
                                   :speed="500"
                                   :labels="true">
                      <template slot="checked">
                        {{ $tc('message.enabled', 0) }}
                      </template>

                      <template slot="unchecked">
                        {{ $tc('message.disabled', 0) }}
                      </template>
                    </switch-button>
                  </b-form-group>
                </b-col>
              </b-row>
            </ValidationObserver>

            <ValidationObserver ref="stopForm" v-slot="{ validate, invalid, pending }">
              <b-row v-if="tripId !== 'new'">
                <transition :name="squeezeStopList ? 'slide-right' : 'slide-left'" mode="out-in">
                  <b-col class="border mt-3"
                         cols="12" sm="12" md="12"
                         :lg="squeezeStopList ? '4' : '12'"
                         :xl="squeezeStopList ? '4' : '12'">
                    <b-container fluid class="m-0 p-0">
                      <b-row class="modal-header d-flex align-items-center">
                        <h5 class="my-modal-title d-inline-flex">{{ $tc('message.stop', 2) }}</h5>

                        <b-button class="b-c-blue float-right" id="add-trip-stop" @click="showStopEditingSide(null, 'edit')">
                          {{ $t('message.add') }}
                        </b-button>
                      </b-row>

                      <b-row>
                        <b-col class="p-2">
                          <b-list-group class="mt-3 mb-3" >
                            <draggable v-model="tripStopOption"
                                       v-bind="{ animation: 350 }"
                                       @start="handleDragStart"
                                       :disabled="!enableDrag"
                                       @end="handleDragStop">
                              <transition-group id="trans-group" type="transition" :name="!isDragging ? 'flip-list' : null">
                                <b-list-group-item v-for="(element, index) in tripStopOption"
                                                   v-on:mouseover="enableDrag = true"
                                                   :id="'list-group-item-' + index"
                                                   :key="element.id" class="d-flex"
                                                   :class="isDragging && element.id === isDragging ? 'b-c-l-gray' : ''">
                                  <b-container class="px-1" id="list-container">
                                    <b-row align-h="center" id="stop-list">
                                      <b-col cols="auto" sm="6" md="8"
                                             :lg="squeezeStopList ? '5' : '10'"
                                             :xl="squeezeStopList ? '6' : '10'"
                                             class="mr-0 pl-0 pr-0 mr-auto">
                                        <div class="stop-details-row">
                                          <div class="stop-details-col">
                                            <b-badge class="b-c-green p-2">{{ updateTripStopIdx(element, index) }}
                                            </b-badge>
                                            <div class="stop-details-text"></div>

                                            <b-badge
                                                class="b-c-green d-flex mx-1 py-1"
                                                v-if="element.fermata.id_struttura && isMobile"
                                                v-b-popover.hover.top="element.structureText">
                                              <i class="fas fa-building p-1 fa-lg"/>
                                            </b-badge>
                                            <div class="stop-details-text"
                                                 v-if="isMobile">

                                            </div>

                                            <h6 class="stop-details-text">
                                              {{ element.fermata.nome }}
                                            </h6>

                                            <div class="stop-details-text" v-if="!isMobile">
                                              <b-badge class="b-c-green d-flex mx-1 py-1"
                                                       v-if="element.fermata.id_struttura"
                                                       v-b-popover.hover.top="element.structureText">
                                                <i class="fas fa-building p-1 fa-lg"/>
                                              </b-badge>
                                            </div>

                                            <h6 class="stop-details-text" v-if="!isMobile">
                                              {{ element.structureText ? `${element.structureText}` : '' }}
                                            </h6>

                                            <h6 class="stop-details-text" v-if="!squeezeStopList && !isMobile">
                                              {{ element.fermata.comune ? `, ${element.fermata.comune}` : '' }}
                                              {{ element.fermata.indirizzo ? `, ${element.fermata.indirizzo}` : '' }}
                                              {{ element.fermata.civico ? `, ${element.fermata.civico}` : '' }}
                                            </h6>
                                          </div>
                                        </div>
                                      </b-col>

                                      <b-col cols="auto" class="m-0 p-0 ml-auto" id="stop-buttons">
                                        <b-button id="user-button" class="d-inline-flex fas fa-user-alt align-self-end mr-2"
                                                  size="sm" @click="showStopEditingSide(element, 'association')">
                                        </b-button>

                                        <b-button id="edit-button" class="d-inline-flex fas fa-pencil-alt align-self-end mr-2"
                                                  size="sm" @click="showStopEditingSide(element, 'edit')">
                                        </b-button>

                                        <b-button id="delete-stop-button" class="d-inline-flex fas fa-trash-alt align-self-end"
                                                  size="sm" @click="showStopEditingSide(element)">
                                        </b-button>
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-list-group-item>
                              </transition-group>
                            </draggable>
                          </b-list-group>
                        </b-col>
                      </b-row>
                    </b-container>
                  </b-col>
                </transition>

                <b-col id="select-children" :class="!!showStopEditing ? `border mt-3 ${isMobile ? '' : ' border-left-0'}` : ''"
                       cols="12" sm="12" md="12" lg="8" xl="8">
                  <transition :name="!showStopEditing ? 'slide-right' : 'slide-left'" mode="out-in"
                              v-on:before-enter="squeezeStopList = true"
                              v-on:after-leave="squeezeStopList = false">
                    <b-container id="trip-stop-modal" fluid class="m-0 p-0" v-show="!!showStopEditing">
                      <b-row v-if="showStopEditing === 'edit'">
                        <b-col>
                          <b-container fluid class="m-0 p-0">
                            <b-row align-v="center" class="modal-header border-bottom-0">
                              <h5 class="my-modal-title">
                                {{ stopData.id ? $tc('message.edit', 1) : $tc('message.add', 1) }}
                                {{ $tc('message.stop', 1) }}</h5>

                              <b-button id="header-cancel-button-stop"
                                        type="button"
                                        class="float-right"
                                        @click="hideStopEditingSide()">
                                {{ $tc('message.close', 1) }} {{ $tc('message.stop', 1) }}
                              </b-button>
                            </b-row>

                            <b-row class="my-border-top mt-0"/>

                            <b-row>
                              <b-col cols="12" sm="12" md="12" lg="6" xl="5">
                                <!-- Name -->
                                <ValidationProvider ref="name-validator" :rules="{ required: true }"
                                                    :name="`${$tc('message.name', 1)} (*)`" v-slot="{ valid, errors }">
                                  <b-form-group :label="`${$tc('message.name', 1)} (*)`">
                                    <v-select :options="getEditStopOptions(stopOption, 'nome')"
                                              :maxHeight="'100px'"
                                              ref="name"
                                              id="trip-stop-name"
                                              taggable
                                              :reduce="obj => obj.nome || obj"
                                              label="nome"
                                              :class="checkSelectStyle('name', stopChanged, valid, errors, true, stop.nome, 30)"
                                              v-model="stop.nome">
                                    </v-select>

                                    <div v-if="errors.length !== 0" class="my-invalid-feedback">
                                      {{ lodash.first(errors) }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>

                              <b-col cols="12" sm="12" md="12" lg="6" xl="4">
                                <!-- Structure -->
                                <b-form-group :label="`${$tc('message.structure', 1)}`">
                                  <v-select :options="structureOptions"
                                            :reduce="obj => obj.id || obj"
                                            id="trip-stop-structure"
                                            label="den_sintetica"
                                            v-model="stop.id_struttura">
                                  </v-select>
                                </b-form-group>
                              </b-col>

                              <b-col cols="12" sm="12" md="12" lg="6" xl="3">
                                <!-- Common -->
                                <ValidationProvider ref="common-validator" :name="`${$tc('message.common', 1)}`"
                                                    v-slot="{ valid, errors }">
                                  <b-form-group :label="`${$tc('message.common', 1)}`">
                                    <v-select :options="getEditStopOptions(stopOption, 'comune')"
                                              ref="common"
                                              taggable
                                              :reduce="obj => obj.comune || obj"
                                              label="comune"
                                              :class="checkSelectStyle('common', stopChanged, valid, errors, false, stop.comune, 30)"
                                              v-model="stop.comune">
                                    </v-select>

                                    <div v-if="errors.length !== 0" class="my-invalid-feedback">
                                      {{ lodash.first(errors) }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>

                              <b-col cols="12" sm="12" md="12" lg="8" xl="8">
                                <!-- Address -->
                                <ValidationProvider ref="address-validator"
                                                    :rules="{ required: true }"
                                                    :name="`${$tc('message.address', 1)}`" v-slot="{ valid, errors }">
                                  <b-form-group :label="`${$tc('message.address', 1)} (*)`">
                                    <v-select :options="getEditStopOptions(stopOption, 'indirizzo')"
                                              ref="address"
                                              id="trip-stop-address"
                                              taggable
                                              label="indirizzo"
                                              :reduce="obj => obj.indirizzo || obj"
                                              :class="checkSelectStyle('address', stopChanged, valid, errors, true, stop.indirizzo, 50)"
                                              v-model="stop.indirizzo">
                                    </v-select>

                                    <div v-if="errors.length !== 0" class="my-invalid-feedback">
                                      {{ lodash.first(errors) }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>

                              <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                                <!-- Civic number -->
                                <ValidationProvider ref="civic-validator" :name="`${$tc('message.civicNumber', 1)}`"
                                                    :rules="{ required: true }" v-slot="{ valid, errors }">
                                  <b-form-group :label="`${$tc('message.civicNumber', 1)} (*)`">
                                    <v-select :options="getEditStopOptions(stopOption, 'civico')"
                                              ref="civic"
                                              id="trip-stop-civic"
                                              taggable
                                              label="civico"
                                              :reduce="obj => obj.civico || obj"
                                              :class="checkSelectStyle('civic', stopChanged, valid, errors, true, stop.civico, 8)"
                                              v-model="stop.civico">
                                    </v-select>

                                    <div v-if="errors.length !== 0" class="my-invalid-feedback">
                                      {{ lodash.first(errors) }}
                                    </div>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>

                              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                <!-- Description -->
                                <ValidationProvider :rules="{ max: 50 }"
                                                    :name="`${$tc('message.description', 1)}`"
                                                    v-slot="{ valid, errors }">
                                  <b-form-group :label="`${$tc('message.description', 1)}`">
                                    <b-form-input id="trip-stop-description"
                                                  type="text"
                                                  v-model="stop.descrizione"
                                                  :state="lodash.first(errors) ? false : null">
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputLiveFeedback">
                                      {{ lodash.first(errors) }}
                                    </b-form-invalid-feedback>
                                  </b-form-group>
                                </ValidationProvider>
                              </b-col>
                            </b-row>
                          </b-container>
                        </b-col>
                      </b-row>

                      <b-row v-if="showStopEditing === 'association'">
                        <b-col>
                          <b-container fluid class="m-0 p-0 mt-3">
                            <standard-table
                                ref="childrenlist"
                                id="children-list"
                                :noContainerPadding="true"
                                :fullWidth="true"
                                :items="getChildrenForTable(structureFilter, sectionFilter, statusFilter)"
                                :fields="getChildFields()"
                                :customFields="getCustomFields()"
                                :theadTrClass="'first-2-table-cols'"
                                :disableAutoUnSelection="true"
                                :noPaginationMargin="true"
                                @rowSelected="handleTableSelection"
                                @allSelected="handleAllSelected"
                                @rowDelete="deleteItem"
                                :row-before-search="{cols: 12,sm: 12,md: 12,lg: 12,xl: 12,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                                :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                                :row-after-search="{cols: 12, sm: 12, md: 12, lg: 12, xl: 6, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"

                                :row-pagination="{cols: 12, sm: 12, md: 12, lg: 6, xl: 6}"
                                :row-between-pagination="{cols: 12, sm: 4, md: 6, lg: 1, xl: 3}"
                                :row-per-page="{cols: 12, sm: 8, md: 6, lg: 5, xl: 3}">
                              <b-container slot="row-after-search" class="mw-100 p-0">
                                <b-row>
                                  <b-col cols="auto">
                                    <b-button v-on:click="resetFilters()" class="b-c-green" id="reset-filters">
                                      {{ $t('message.resetFilters') }}
                                    </b-button>
                                  </b-col>

                                  <b-col cols="auto" class="mb-3">
                                    <b-button @click="showCollapse = !showCollapse"
                                              :class="showCollapse ? 'b-c-red' : 'b-c-green'" id="show-filters">
                                      <span v-show="!showCollapse"
                                            class="when-opened">{{
                                          $t('message.show')
                                        }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                                      <span v-show="showCollapse"
                                            class="when-closed">{{
                                          $t('message.hide')
                                        }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                                    </b-button>
                                  </b-col>
                                </b-row>
                              </b-container>
                              <b-container slot="after-search" class="mw-100 p-0">
                                <b-row>
                                  <b-col cols="12" sm="12" md="12" lg="4" xl="4">
                                    <!-- Structure -->
                                    <b-form-group
                                        :label="`${$tc('message.selectLabel', 1)} ${$tc('message.status', 1).toLowerCase()} per i selezionati`">
                                      <v-select :options="statusOptions"
                                                :reduce="obj => obj.value"
                                                label="text"
                                                placeholder="Seleziona"
                                                v-model="statusFilter">
                                      </v-select>
                                    </b-form-group>
                                  </b-col>
                                </b-row>
                                <b-collapse id="my-collapse" v-model="showCollapse">
                                  <b-container class="mw-100 m-0 p-0">
                                    <b-row>
                                      <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                        <!-- Structure -->
                                        <b-form-group :label="`${$tc('message.structure', 1)}`">
                                          <v-select :options="structureOptions"
                                                    :reduce="obj => obj.id"
                                                    label="den_sintetica"
                                                    v-model="structureFilter">
                                          </v-select>
                                        </b-form-group>
                                      </b-col>

                                      <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                        <!-- Section -->
                                        <b-form-group :label="`${$tc('message.section', 1)}`">
                                          <v-select :options="getSectionOptions(sectionOptions, structureFilter)"
                                                    :reduce="obj => obj.id"
                                                    label="descrizione"
                                                    :disabled="!structureFilter"
                                                    v-model="sectionFilter">
                                          </v-select>
                                        </b-form-group>
                                      </b-col>
                                    </b-row>
                                  </b-container>
                                </b-collapse>
                              </b-container>

                              <slot slot="up&down" slot-scope="row">
                                <transition name="fade" :duration="{ enter: 330, leave: 1 }">
                                  <i class="fas fa-ban fa-lg c-ls-gray hover-not-allowed"
                                     v-if="!row.item.selected"/>
                                </transition>

                                <transition name="fade"
                                            :duration="{ enter: 330, leave: 1 }">
                                  <i class="fas fa-arrow-up fa-lg c-green hover-enabled"
                                     @click="handleUpAndDown(row.item, false)"
                                     v-b-popover.hover.right="$tc('message.up', 1)"
                                     v-if="row.item.salita && row.item.selected">
                                  </i>
                                </transition>

                                <transition name="fade" :duration="{ enter: 330, leave: 1 }">
                                  <i class="fas fa-arrow-down fa-lg c-red hover-enabled"
                                     @click="handleUpAndDown(row.item, true)"
                                     v-b-popover.hover.right="$tc('message.down', 1)"
                                     v-if="!row.item.salita && row.item.selected"/>
                                </transition>

                                {{
                                  isMobile ? (row.item.selected ? (row.item.salita ? $tc('message.up', 1) : $tc('message.down', 1)) : '') : ''
                                }}
                              </slot>
                            </standard-table>
                          </b-container>
                        </b-col>
                      </b-row>

                      <b-row class="my-border-top mb-3">
                        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                          <b-container fluid class=" m-0 p-0">
                            <b-row align-h="end">
                              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="save-stop"
                                          type="button"
                                          class="float-right ml-3 mt-2 b-c-green"
                                          :disabled="invalid || pending || !stopChanged"
                                          @click="saveStop()">
                                  {{ $tc('message.save', 1) }} {{ $tc('message.stop', 1) }}
                                </b-button>
                              </b-col>

                              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="footer-cancel-button-stop"
                                          type="button"
                                          class="float-right ml-3 mt-2"
                                          @click="hideStopEditingSide()">
                                  {{ $tc('message.close', 1) }} {{ $tc('message.stop', 1) }}
                                </b-button>
                              </b-col>
                            </b-row>
                          </b-container>
                        </b-col>
                      </b-row>
                    </b-container>
                  </transition>
                </b-col>
              </b-row>
            </ValidationObserver>

            <transition :name="showStopEditing ? 'fade-down' : 'fade-up'" mode="out-in">
              <b-row v-show="!showStopEditing">
                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <b-container fluid class="m-0 p-0 mt-2">
                    <b-row align-h="end">
                      <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                        <b-button id="save"
                                  type="button"
                                  class="float-right b-c-green"
                                  :disabled="!!enableTripSaveButtons"
                                  @click="save(false)"> {{ $tc('message.save', 1) }}
                        </b-button>
                      </b-col>

                      <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                        <b-button id="save-and-exit"
                                  type="button"
                                  class="float-right b-c-green"
                                  :disabled="!!enableTripSaveButtons"
                                  @click="save(true)"> {{ $tc('message.saveClose', 1) }}
                        </b-button>
                      </b-col>

                      <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                        <b-button id="cancel-button"
                                  type="button"
                                  class="float-right"
                                  @click="exitCheck(itemChanged)"> {{ $tc('message.close', 1) }}
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-container>
                </b-col>
              </b-row>
            </transition>
          </b-container>
        </div>
      </div>
    </div>

    <b-modal ref="deleteModal" id="deleteModal" :title="`${$t('message.caution')}!`">
      <b-container fluid>
        <b-row>
          <h5>{{ $t('message.deleteCheck') }} "{{ stopData && stopData.fermata && stopData.fermata.nome }}"?</h5>
        </b-row>

        <b-row v-if="lodash.find(childOptions, obj => obj.selected)">
          <h6 class="c-red">{{ $tc('message.willBeRemoved', 1) }}</h6>
        </b-row>

        <b-row v-for="item in childOptions" :key="item.id">
          <h6 v-if="item.selected">- {{ item.cognome }} {{ item.nome }}, {{ $tc('message.structure', 1) }}:
            {{ item.structureText }} </h6>
        </b-row>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button id="nw-delete-close" class="m-1 b-c-green" @click="hideDeleteModal()">{{ $t('message.cancel') }}
        </b-button>

        <b-button id="nw-delete-continue" class="m-1 b-c-green" @click="deleteItem()">{{ $t('message.continue') }}
        </b-button>
      </div>
    </b-modal>
    <b-modal ref="warningDeleteModal" id="warningDeleteModal" :title="`${$t('message.caution')}!`">
      <b-container fluid>
        <b-row>
          <h5>{{ $t('message.deleteWarning') }} </h5>
        </b-row>
      </b-container>
      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button id="nw-delete-continue" class="m-1 b-c-green" @click="$refs.warningDeleteModal.hide()">{{ $t('message.continue') }}
        </b-button>
      </div>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _, { isNil } from 'lodash'
import { DateTime } from 'luxon'

export default {
  name: 'EditTrip',
  created () {
    this.fetchData()
  },
  mounted () {
    this.$root.$on('change-lang', () => {
      this.$nextTick(() => {
        if (this && this.$refs && this.$refs.mainForm) this.$refs.mainForm.validate()
        if (this && this.$refs && this.$refs.stopForm && !!this.showStopEditing) this.$refs.stopForm.validate()
      })
    })
    this.$root.$on('resize', () => this.checkWindowSize())
    this.$nextTick(() => this.checkWindowSize())
  },
  data () {
    const tripId = this.$route.params.id
    const lodash = _
    const backPath = '/registry/trip'
    const yearId = Vue.getLocalStorage('year')
    const tripManagerId = parseInt(Vue.getLocalStorage('trip-manager'))
    return {
      backPath,
      tripId,
      lodash,
      DateTime,
      yearId,
      tripManagerId,
      isMobile: false,
      input: {},
      stop: {},
      stopData: {},
      structureOptions: [],
      sectionOptions: [],
      originalChildOptions: [],
      childOptions: [],
      filterableChildOptions: [],
      stopOption: [],
      tripStopOption: [],
      statusOptions: [
        { text: 'Salita', value: true },
        { text: 'Discesa', value: false }
      ],
      statusFilter: null,
      childrenTripStop: [],
      watcher: null,
      itemChanged: false,
      stopWatcher: null,
      stopChanged: false,
      showStopEditing: null,
      squeezeStopList: false,
      isDragging: false,
      showCollapse: false,
      structureFilter: null,
      sectionFilter: null,
      enableTripSaveButtons: false,
      enableDrag: false,
      firstStopIsStructure: false,
      ascentNextValue: [],
      allStopsOptions: []
    }
  },
  watch: {
    showStopEditing () {
      this.resetStopWatcher()
      this.addStopWatcher()
    }
  },
  methods: {
    // Watcher functions
    checkWindowSize () {
      let size = Vue.myGetWindowSize()
      this.isMobile = (size === 'sm' || size === 'cols' || size === 'md')
    },
    resetWatcher () {
      this.$root.$emit('activeLoader', true)
      if (this.watcher) this.watcher()
      this.itemChanged = false
    },
    addWatcher () {
      this.$root.$emit('activeLoader', false)
      this.watcher = this.$watch('input', () => {
        this.itemChanged = true
      }, { deep: true })
    },
    resetStopWatcher () {
      this.$root.$emit('activeLoader', true)
      if (this.stopWatcher) this.stopWatcher()
      this.stopChanged = false
    },
    addStopWatcher () {
      this.$root.$emit('activeLoader', false)
      this.stopWatcher = this.$watch('stop', () => {
        this.stopChanged = true
      }, { deep: true })
    },

    // Getting data
    fetchData () {
      this.resetWatcher()
      this.input = { attiva: true }
      return this.getOptions().then(() => {
        return this.getTrip().then(() => {
            this.addWatcher()
            this.getAllTripStop()
            this.$root.$emit('activeLoader', false)
            return true
        })
      })
    },
    async getOptions () {
      const axiosArray = []
      axiosArray.push(this.getStructures())
      axiosArray.push(this.getSections())
      axiosArray.push(this.getChildren())
      axiosArray.push(this.getAllStop())
      axiosArray.push(await this.getAllTripStop())
      axiosArray.push(this.getTripChildren())
      return Vue.myAll(axiosArray)
    },
    getStructures () {
      return Vue.myGet(this, '/struttura', 'strutture').then(structures => {
        this.structureOptions = _.orderBy(structures, 'den_sintetica')
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getSections () {
      return Vue.myGet(this, '/sezione', 'sezioni').then(sections => {
        this.sectionOptions = sections
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getChildren () {
      return Vue.myGet(this, `/struttura_bambino?anno=${this.yearId}`, 'associazioni').then(structureChildren => {
        const editedItems = []
        structureChildren.forEach(item => {
          const foundedStructure = _.find(this.structureOptions, obj => obj.id === item.id_struttura)
          const foundedSection = _.find(this.sectionOptions, obj => obj.id === item.id_sezione)
          const editedItem = {
            id: item.id,
            nome: item.bambino.nome,
            cognome: item.bambino.cognome,
            codice_fiscale: item.bambino.codice_fiscale,
            structureId: item.id_struttura,
            structureText: foundedStructure ? foundedStructure.den_sintetica : this.$tc('message.emptyValue', 1),
            sectionId: item.id_sezione,
            sectionText: foundedSection ? foundedSection.descrizione : this.$tc('message.emptyValue', 1)
          }
          if (!item.data_cancellazione || (item.data_cancellazione && DateTime.fromISO(item.data_cancellazione) > DateTime.local())) {
            editedItems.push(editedItem)
          }
        })
        this.originalChildOptions = editedItems
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getAllStop () {
      return Vue.myGet(this, '/bus/fermata', 'fermate').then(stops => {
        this.stopOption = stops
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    getAllTripStop () {
      if (!isNaN(this.tripId)) {
        this.showStopEditing = false
        return Vue.myGet(this, `/bus/gita_fermata?gita=${this.tripId}`, 'fermate').then(stops => {
          const editedTrips = []
          const allTripsStops = []
          stops.forEach(trip => {
            const editedTrip = trip
            const foundedStructure = _.find(this.structureOptions, obj => obj.id === trip.fermata.id_struttura)
            if (foundedStructure) editedTrip.structureText = foundedStructure.den_sintetica
            if (!trip.removed) {
              editedTrips.push(editedTrip)
            }
            allTripsStops.push(editedTrip)
          })
          if (!isNil(_.find(this.tripStopOption, stop => !isNil(stop.fermata.id_struttura)))) {
            this.firstStopIsStructure = !_.isNil(_.find(stops, stop => stop.idx === 1 && !_.isNil(stop.fermata.id_struttura)))
          } else {
            this.firstStopIsStructure = null
          }
          this.tripStopOption = _.orderBy(editedTrips, 'idx')
          this.allStopsOptions = allTripsStops
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      }
      return true
    },
    getTripChildren () {
      if (!isNaN(this.tripId)) {
        return Vue.myGet(this, `/bus/gita_bambino?gita=${this.tripId}`, 'bambini').then(items => {
          this.childrenTripStop = items
          this.originalChildOptions.forEach(child => {
            const childDescent = _.find(this.childrenTripStop, tripStop => !_.isNil(_.find(this.tripStopOption, trip => trip.id === tripStop.id_gita_fermata)) && tripStop.id_struttura_bambino === child.id && tripStop.salita === false)
            const childAscent = _.find(this.childrenTripStop, tripStop => !_.isNil(_.find(this.tripStopOption, trip => trip.id === tripStop.id_gita_fermata)) && tripStop.id_struttura_bambino === child.id && tripStop.salita === true)
            if (!_.isUndefined(childDescent) && !_.isUndefined(childAscent)) {
              this.ascentNextValue[child.id] = null
            } else if ((!_.isUndefined(childDescent) && _.isUndefined(childAscent)) || (_.isUndefined(childDescent) && _.isUndefined(childAscent))) {
              this.ascentNextValue[child.id] = true
            } else if (_.isUndefined(childDescent) && !_.isUndefined(childAscent)) {
              this.ascentNextValue[child.id] = false
            }
          })
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      }
      return true
    },
    getTrip () {
      if (this.tripId !== 'new') {
        return Vue.myGet(this, `/bus/gita/${this.tripId}`, 'gite').then((trip) => {
          this.input = trip
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      } else {
        return new Promise(resolve => resolve())
      }
    },
    getChildFields () {
      return [
        { key: 'select', label: '' },
        { key: 'nome', label: `${this.$tc('message.name', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'cognome', label: `${this.$tc('message.surname', 1)}`, sortable: true, sortDirection: 'desc' },
        {
          key: 'codice_fiscale',
          label: `${this.$tc('message.taxCode', 1)}`,
          sortable: true,
          sortDirection: 'desc',
          hideInTable: true
        },
        { key: 'structureText', label: `${this.$tc('message.structure', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'sectionText', label: `${this.$tc('message.section', 1)}`, sortable: true, sortDirection: 'desc' }
      ]
    },
    getCustomFields () {
      return [
        { key: 'up&down', label: `${this.$tc('message.status', 1)}`, position: 1 }
      ]
    },
    getEditStopOptions (options, field) {
      const editedItem = []
      options.forEach(option => {
        if (option[field] && !_.find(editedItem, obj => obj[field] === option[field])) {
          editedItem.push(option)
        }
      })
      return _.orderBy(editedItem, field)
    },
    getSectionOptions (options, structure) {
      return _.filter(options, obj => obj.id_struttura === structure)
    },
    getChildrenForTable (structure, section, statusFilter) {
      let editedItems = _.cloneDeep(this.filterableChildOptions)
      if (structure) editedItems = _.filter(editedItems, obj => obj.structureId === structure)
      if (section) editedItems = _.filter(editedItems, obj => obj.sectionId === section)
      if (statusFilter) {
        editedItems.forEach((child) => {
          if (child.selected) {
            child.salita = statusFilter
          }
        })
      }
      editedItems = editedItems.sort((a, b) => {
        if (this.$refs.childrenlist && this.$refs.childrenlist.sortDesc) {
          if (a.cognome.toLowerCase() < b.cognome.toLowerCase()) { return 1 }
          if (a.cognome.toLowerCase() > b.cognome.toLowerCase()) { return -1 }
          return 0
        } else {
                if (a.cognome.toLowerCase() < b.cognome.toLowerCase()) { return -1 }
                if (a.cognome.toLowerCase() > b.cognome.toLowerCase()) { return 1 }
                return 0
              }
        }
      )
      return editedItems
    },

    // Style functions
    checkSelectStyle (ref, groupChanged, valid, errors, required, value, maxLength) {
      if (this.$refs[ref] && !this.$refs[ref].otherCheck) {
        Vue.MySelectValidationClass(this, ref, groupChanged, valid, errors, required, value, maxLength)
        this.checkCustomErrors(ref, value, maxLength)
      }
    },
    checkCustomErrors (ref, value, maxLength) {
      this.$refs[ref].otherCheck = true
      if (value && maxLength && `${value}`.length > maxLength) {
        const invalidClass = 'vs__dropdown-toggle form-control is-invalid'
        const message = `${this.$tc('errors.genericMaxLength1', 1)} ${maxLength} ${this.$tc('errors.genericMaxLength2', 1)}`
        const select = this.$refs[ref] && this.$refs[ref].$el && this.$refs[ref].$el.children && _.first(this.$refs[ref].$el.children)
        if (this.$refs[`${ref}-validator`]) {
          this.$refs[`${ref}-validator`].setErrors([message])
          select.className = invalidClass
        }
      }
      this.$nextTick(() => {
        this.$refs[ref].otherCheck = false
      })
    },

    // Trip functions
    checkTripSave (invalid, pending, dataNotChanged, showStopEditing) {
      this.enableTripSaveButtons = invalid || pending || dataNotChanged || showStopEditing
    },
    save (pushBack, skipNotice) {
      const editedItem = _.cloneDeep(this.input)
      editedItem.id_gestore_gita = this.tripManagerId
      editedItem.forza_nfc = false
      Vue.patchOrPost(this.tripId !== 'new',
          `/bus/gita${this.tripId !== 'new' ? '/' + this.tripId : ''}`, editedItem).then(r => {
        if (!skipNotice) Vue.success(this, `${this.$tc('message.trip', 1)} ${this.$t(this.tripId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
        if (pushBack) {
          this.$router.push(this.backPath)
        } else {
          this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditTrip' } })
        }
      }).catch(errorObj => {
        Vue.manageErrors(this, errorObj)
      })
    },
    saveStop () {
      this.resetFilters()
      const stopObj = this.parseStopObj(_.cloneDeep(this.stop))
      const alreadyExist = _.find(this.stopOption, obj => {
        let isEqual = true
        _.forOwn(stopObj, (val, field) => {
          if (isEqual) isEqual = val === obj[field]
        })
        return isEqual ? obj : null
      })
      const stopData = _.cloneDeep(this.stopData)
      const childrenTripStop = this.$refs.childrenlist ? _.cloneDeep(this.$refs.childrenlist.componentItems) : _.cloneDeep(this.childOptions)
      if (alreadyExist || (stopData && stopData.fermata && stopData.fermata.id)) {
        if (!alreadyExist) {
          Vue.patchOrPost(!!stopData.fermata.id, `/bus/fermata/${stopData.fermata.id}`, stopObj).then(() => {
            this.saveTripStop(stopData, childrenTripStop).then(() => {
              this.fetchData()
            }).catch(error => {
              Vue.manageErrors(this, error)
              return false
            })
          }).catch(error => {
            Vue.manageErrors(this, error)
            return false
          })
        } else {
          stopData.id_fermata = alreadyExist.id
          this.saveTripStop(stopData, childrenTripStop).then(() => {
            this.fetchData()
          }).catch(error => {
            Vue.manageErrors(this, error)
            return false
          })
        }
      } else {
        Vue.patchOrPost(false, '/bus/fermata', stopObj).then(stop => {
          const tripStop = { id_gita: parseInt(this.tripId), id_fermata: stop.data.id, idx: stopData.idx }
          this.saveTripStop(tripStop, childrenTripStop).then(() => {
            this.fetchData()
          }).catch(error => {
            Vue.manageErrors(this, error)
            return false
          })
        }).catch(error => {
          Vue.manageErrors(this, error)
          return false
        })
      }
    },
    exitCheck (itemChanged) {
      if (itemChanged) {
        this.$root.$emit('exit-check', { redirect: this.backPath })
      } else {
        this.$router.push(this.backPath)
      }
    },

    // Trip-stop functions
    saveTripStop (item, childrenTripStop) {
      return Vue.patchOrPost(!!item.id, `/bus/gita_fermata${item.id ? '/' + item.id : ''}`, item).then(tripStop => {
        if (_.isNil(item.id)) {
          Vue.success(this, `${this.$tc('message.trip', 1)} ${this.$t(this.tripId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          this.$router.push({ name: 'RefreshPage', params: { id: this.input.id, name: 'EditTrip' } })
        } else if (childrenTripStop && childrenTripStop.length !== 0) {
          const axiosArray = []
          childrenTripStop.forEach(child => {
            if ((child.salita && ((item.fermata.id_struttura !== null && this.firstStopIsStructure) || (item.fermata.id_struttura === null && !this.firstStopIsStructure))) ||
                ((!child.salita || _.isUndefined(child.salita)) && ((item.fermata.id_struttura === null && this.firstStopIsStructure) || (item.fermata.id_struttura !== null && !this.firstStopIsStructure)))) {
              const editedItem = {
                id: child.tripChildId || null,
                id_gita_fermata: item.id || tripStop.data.id,
                id_struttura_bambino: child.id,
                salita: _.isUndefined(child.salita) ? false : child.salita
              }
              if (child.selected) {
                axiosArray.push(this.updateChildTrip(editedItem))
              } else if (child.tripChildId) {
                axiosArray.push(this.deleteChildTrip(editedItem))
              }
            }
          })
          return Vue.myAll(axiosArray).then(() => {
            Vue.success(this, `${this.$tc('message.trip', 1)} ${this.$t(this.tripId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
            this.fetchData()
            return true
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        } else {
          return true
        }
      }).catch(error => {
        throw error
      })
    },
    parseStopObj (obj) {
      return {
        nome: obj.nome || null,
        descrizione: obj.descrizione || null,
        indirizzo: obj.indirizzo || null,
        civico: obj.civico || null,
        id_struttura: obj.id_struttura || null,
        comune: obj.comune || null,
        id_gestore_gita: this.tripManagerId
      }
    },
    updateTripStopIdx (item, newIndex) {
      item.draggableIndex = newIndex + 1
      return item.draggableIndex
    },
    handleDragStart (item) {
      const foundedItem = _.find(this.tripStopOption, obj => obj.idx === item.oldIndex + 1)
      this.isDragging = foundedItem.id
    },
    handleDragStop (event) {
      this.enableDrag = false
      this.isDragging = false
      this.$root.$emit('activeLoader', true)
      const items = _.cloneDeep(this.tripStopOption)
      const foundedItem = _.find(items, obj => obj.draggableIndex === event.newIndex + 1)
      const switchedToLastTripStop = _.cloneDeep(foundedItem)
      switchedToLastTripStop.idx = items.length + 1

      this.saveTripStop(switchedToLastTripStop).then(() => {
        this.sortTripStop(items, foundedItem).then(() => {
          foundedItem.idx = foundedItem.draggableIndex
          this.saveTripStop(foundedItem).then(() => {
            this.fetchData().then(() => {
              this.$root.$emit('activeLoader', false)
            })
          })
        })
      }).catch(error => {
        Vue.manageErrors(this, error)
        this.$root.$emit('activeLoader', false)
      })
    },
    sortTripStop (tripArray, foundedItem, i, isDelete) {
      const shiftLeft = foundedItem.draggableIndex > foundedItem.idx
      const switchRange = {
        from: shiftLeft ? foundedItem.idx : foundedItem.draggableIndex,
        to: shiftLeft ? foundedItem.draggableIndex : foundedItem.idx
      }
      const constEditedIndex = !isNaN(i) ? i : (shiftLeft ? 0 : tripArray.length - 1)
      const indexToCheck = shiftLeft ? constEditedIndex + 1 : constEditedIndex - 1
      const nextItemExist = !isNaN(indexToCheck) && indexToCheck >= 0 && tripArray[indexToCheck]
      const item = _.cloneDeep(tripArray[constEditedIndex])
      const isInRange = item && switchRange.from <= item.draggableIndex && item.draggableIndex <= switchRange.to

      if (isInRange && item.id !== foundedItem.id) {
        item.idx = isDelete ? item.draggableIndex - 1 : item.draggableIndex
        return this.saveTripStop(item).then(() => {
          if (nextItemExist) return this.sortTripStop(tripArray, foundedItem, indexToCheck, isDelete)
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          this.$root.$emit('activeLoader', false)
          return false
        })
      }

      if (nextItemExist) return this.sortTripStop(tripArray, foundedItem, indexToCheck, isDelete)
      return new Promise(resolve => resolve())
    },
    hideDeleteModal () {
      this.$refs.deleteModal.hide()
      this.childOptions = []
      this.filterableChildOptions = []
      this.stopData = {
        idx: this.allStopsOptions.length + 1,
        id_gita: parseInt(this.tripId),
        id_gestore_gita: this.tripManagerId
      }
    },
    deleteItem () {
      const secondStop = _.find(this.tripStopOption, stop => stop.idx === 2)
      if (!this.firstStopIsStructure && !isNil(secondStop) && !isNil(secondStop.structureText)) {
        this.$refs.deleteModal.hide()
        this.$refs.warningDeleteModal.show()
      } else {
        const axiosArray = []
        const items = _.cloneDeep(this.tripStopOption)
        const itemToDelete = this.stopData
        const childrenToDelete = _.filter(this.childOptions, obj => obj.selected)
        this.$root.$emit('activeLoader', true)
        childrenToDelete.forEach(item => {
          const editedItem = { id: item.tripChildId }
          axiosArray.push(this.deleteChildTrip(editedItem))
        })

        Vue.myAll(axiosArray).then(() => {
          this.deleteTripStop(itemToDelete).then(() => {
            itemToDelete.draggableIndex = items.length + 1
            this.sortTripStop(items, itemToDelete, null, true).then(() => {
              this.fetchData().then(() => {
                this.hideDeleteModal()
                this.$root.$emit('activeLoader', false)
                Vue.success(this, `${this.$tc('message.delete')} ${this.$tc('message.done', 2).toLowerCase()}`)
              })
            })
          })
        })
      }
    },
    deleteTripStop (item) {
      return Vue.myDelete(`/bus/gita_fermata/${item.id}`).then(r => r).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },

    // Trip-stop-child functions
    showStopEditingSide (item, type) {
      this.statusFilter = null
      this.enableDrag = false
      this.resetStopWatcher()
      if (this.$refs.stopForm) this.$refs.stopForm.reset()
      if (item && item.fermata) {
        this.stop = _.cloneDeep(item.fermata)
      } else {
        this.stop = { id_gestore_gita: this.tripManagerId }
      }
      this.checkAvailableChildren(item)
      this.addStopWatcher()
      this.showStopEditing = type
      if (!type) this.$refs.deleteModal.show()
    },
    checkAvailableChildren (item) {
      const allTripStop = _.cloneDeep(this.tripStopOption)
      const originalChildrenTripStop = _.cloneDeep(this.childrenTripStop)
      const originalChildOptions = _.cloneDeep(this.originalChildOptions)
      let remainingChildOption = _.cloneDeep(this.originalChildOptions)
      let foundedItem = null
      if (!_.isNil(allTripStop)) {
        allTripStop.forEach(tripStop => {
          const itemToShow = !_.isNil(item) && !_.isNil(tripStop) && tripStop.id === item.id
          const editedTripStop = tripStop
          editedTripStop.children = []
          const allChildrenTripStop = _.filter(originalChildrenTripStop, childStop => childStop.id_gita_fermata === tripStop.id)
          if (allChildrenTripStop && allChildrenTripStop.length !== 0) {
            allChildrenTripStop.forEach(childTripStop => {
              const foundedChild = _.find(originalChildOptions, obj => obj.id === childTripStop.id_struttura_bambino && childTripStop.id_gita_fermata === tripStop.id)
              if (!_.isNil(foundedChild) && ((isNil(_.find(this.tripStopOption, stop => !isNil(stop.fermata.id_struttura))) && isNil(this.firstStopIsStructure)) ||
                  (childTripStop.salita &&
                      // gita di andata --> tutte le fermate in cui si può salire (quelle con associata una struttura)
                      ((!_.isNil(item) && this.firstStopIsStructure === false && _.isNil(item.structureText)) ||
                          // gita di ritorno --> prima fermata tutte le fermate in cui si può salire (quelle con associata una struttura)
                          (!_.isNil(tripStop) && !_.isNil(item) && tripStop.id === item.id && this.firstStopIsStructure && !_.isNil(item.structureText)))) ||
                  (!childTripStop.salita &&
                      // gita di andata ---> solo ultima fermata e le altre a cui non è associata una struttura, in cui si può solo scendere
                      ((!_.isNil(tripStop) && !_.isNil(item) && tripStop.id === item.id && this.firstStopIsStructure === false && !_.isNil(item.structureText)) ||
                          // gita di ritorno --> fermate successive alla prima che non sono associate alla struttura e in cui si può solo scendere
                          (!_.isNil(item) && this.firstStopIsStructure && _.isNil(item.structureText)))))) {
                const editedChild = _.cloneDeep(foundedChild)
                editedChild.tripChildId = childTripStop.id
                editedChild.selected = itemToShow
                editedChild.salita = childTripStop.salita
                editedTripStop.children.push(editedChild)
                const foundedChildNotGoneUpDefined = _.find(remainingChildOption, obj => obj.id === editedChild.id)
                if (!_.isUndefined(foundedChildNotGoneUpDefined)) {
                  remainingChildOption = _.filter(remainingChildOption, obj => obj !== foundedChildNotGoneUpDefined)
                }
              }
              // gita di andata ---> elimino dalle fermate associate a struttura i bambini che sono già scesi in precedenza
              if (!_.isNil(tripStop) && !_.isNil(item) &&
                  !this.firstStopIsStructure && !_.isNil(item.structureText)) {
                const descendedChild = _.find(allChildrenTripStop, child => childTripStop.id_struttura_bambino === child.id_struttura_bambino && child.salita === false && childTripStop.id !== item.id)
                if (!_.isUndefined(descendedChild)) {
                  const foundedDescendedChild = _.find(remainingChildOption, obj => obj.id === descendedChild.id_struttura_bambino)
                  if (!_.isUndefined(foundedDescendedChild)) {
                    remainingChildOption = _.filter(remainingChildOption, obj => obj !== foundedDescendedChild)
                  }
                }
              }
              // gita di ritorno --> elimino dalle fermate associate a struttura i bambini che sono già saliti in precedenza
              if (!_.isNil(tripStop) && !_.isNil(item) && this.firstStopIsStructure && !_.isNil(item.structureText) && childTripStop.id !== item.id) {
                const ascendedChild = _.find(allChildrenTripStop, child => childTripStop.id_struttura_bambino === child.id_struttura_bambino && child.salita === true && childTripStop.id !== item.id && tripStop.idx < item.idx)
                if (!_.isUndefined(ascendedChild)) {
                  const foundedAscendedChild = _.find(remainingChildOption, obj => obj.id === ascendedChild.id_struttura_bambino)
                  if (!_.isUndefined(foundedAscendedChild)) {
                    remainingChildOption = _.filter(remainingChildOption, obj => obj !== foundedAscendedChild)
                  }
                }
              }
            })
          }
          if (itemToShow) foundedItem = editedTripStop
        })
      }
      if (foundedItem && foundedItem.children && foundedItem.children.length !== 0) {
        foundedItem.children.forEach(child => {
          remainingChildOption.push(child)
        })
      }
      this.childOptions = _.orderBy(remainingChildOption, 'cognome')
      this.filterableChildOptions = _.cloneDeep(this.childOptions)

      this.stopData = item || {
        idx: this.allStopsOptions.length + 1,
        id_gita: parseInt(this.tripId),
        id_gestore_gita: this.tripManagerId
      }
    },
    hideStopEditingSide () {
      this.showStopEditing = false
    },
    resetFilters () {
      if (!isNil(this.$refs.childrenlist)) {
        this.$refs.childrenlist.resetSearch()
      }
    },
    deleteChildTrip (item) {
      return Vue.myDelete(`/bus/gita_bambino/${item.id}`).then(r => r).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    updateChildTrip (item) {
      if (!item.id) delete item.id
      if (this.ascentNextValue.at(item.id_struttura_bambino) === item.salita || _.isUndefined(item.salita)) {
        return Vue.patchOrPost(!!item.id, item.id ? `/bus/gita_bambino/${item.id}` : '/bus/gita_bambino', item).then(r => {
          return r
        }).catch(error => {
          Vue.manageErrors(this, error)
          return false
        })
      }
    },
    handleAllSelected (items) {
      this.stopChanged = true
      items.forEach(item => {
        item.selected = !item.selected
        this.handleTableSelection(item)
      })
    },
    handleUpAndDown (item, val) {
      const clonedIndex = _.findIndex(this.childOptions, obj => obj.id === item.id)
      const originalIndex = _.findIndex(this.childOptions, obj => obj.id === item.id)
      this.childOptions[originalIndex].salita = val
      this.filterableChildOptions[clonedIndex].salita = val
      this.stopChanged = true
      item.salita = val
    },
    handleTableSelection (item) {
      this.stopChanged = true
      const originalIndex = _.findIndex(this.childOptions, obj => obj.id === item.id)
      const clonedIndex = _.findIndex(this.childOptions, obj => obj.id === item.id)
      this.childOptions[originalIndex].selected = item.selected
      this.filterableChildOptions[clonedIndex].selected = item.selected
    }
  }
}
</script>

<style scoped>
.stop-details-row {
  display: grid;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
}

.stop-details-col {
  min-width: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.stop-details-text {
  flex-grow: 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 0;
  align-self: center;
  margin-left: 0.1em;
}
</style>
