/* eslint-disable no-param-reassign */
import XLSX from 'xlsx'
import _ from 'lodash'

const MyExcelFromDate = {
  install (Vue) {
    Vue.excel = (fields, items, filename, counters) => {
      const mainArray = []
      const fieldsArray = []
      const fieldsTitle = []
      const colums = []
      const counter = {}
      const skipFields = ['actions', 'view', 'select', 'selected', 'info', 'edit', 'delete']

      if (counters) {
        counters.forEach((obj) => {
          counter[obj.key] = 0
        })
      }

      fields.forEach((field) => {
        const skipCurrentField = _.find(skipFields, obj => obj === field.key)
        if (!skipCurrentField && !field.skipInExcel) {
          fieldsArray.push(field.key)
          fieldsTitle.push(field.label)
          colums.push({ wch: (field.label && field.label.length) || 1 })
        }
      })

      mainArray.push(fieldsTitle)

      items.forEach((item) => {
        const row = []
        fieldsArray.forEach((field, fieldIndex) => {
          const skipCurrentField = _.find(skipFields, obj => obj === field.key)
          if (!skipCurrentField) {
            const value = item[`${field}`]
            row.push(value)
            if (counters) {
              counters.forEach((obj) => {
                counter[obj.key] += (obj.key === field && obj.increaseValue === value) ? 1 : 0
              })
            }
            const currentMaxLength = colums[fieldIndex].wch
            const newMaxLength = (value && value.length) || 1
            if (newMaxLength > currentMaxLength) {
              colums[fieldIndex].wch = newMaxLength
            }
          }
        })
        mainArray.push(row)
      })

      if (counters) {
        counters.forEach((obj) => {
          mainArray.push([])
          mainArray.push([`${[obj.label]}`], `${counter[obj.key]}`)
        })
      }

      const ws = XLSX.utils.aoa_to_sheet(mainArray)

      ws['!cols'] = colums

      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, filename)
      XLSX.writeFile(wb, `${filename}.xlsx`)
      return mainArray
    }
  }
}

export default MyExcelFromDate
