<template>
    <b-container fluid>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <header class="modal-header">
                    <b-container class="p-0 m-0 mw-100">
                        <b-row>
                            <b-col cols="12" sm="12" md="12" lg="9" xl="9">
                                <h5 class="my-modal-title">{{$tc('message.operator', 1)}}</h5>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="3" xl="3">
                                <b-container class="h-100">
                                    <b-row align-h="center" align-v="center" class="h-100">
                                        <b-badge v-if="originalUser.id" :variant="originalUser.sospeso ? 'danger' : 'success'">
                                            <legend class="col-form-label pt-0">
                                                {{$tc('message.user', 1)}}
                                                {{this.$tc(originalUser.sospeso ? 'message.disabled' : 'message.enabled', 0).toLowerCase()}}
                                            </legend>
                                        </b-badge>
                                    </b-row>
                                </b-container>
                            </b-col>
                        </b-row>
                    </b-container>
                </header>

                <div class="modal-body">
                    <ValidationObserver ref="form" v-slot="{ validate, invalid, pending }">
                        <b-container>
                            <b-row>
                                <b-col cols="12" sm="12" md="4" lg="4" xl="4">
                                    <!-- Registration number -->
                                    <ValidationProvider :rules="{ max: 10 }" :name="`${$tc('message.registrationNumber', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.registrationNumber', 1)}`">
                                            <b-form-input id="registration-number"
                                                          type="text"
                                                          v-model="input.matricola"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                                    <!-- Surname -->
                                    <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.surname', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.surname', 1)} (*)`">
                                            <b-form-input id="surname"
                                                          type="text"
                                                          v-model="input.cognome"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                                    <!-- Name -->
                                    <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.name', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.name', 1)} (*)`">
                                            <b-form-input id="name"
                                                          type="text"
                                                          v-model="input.nome"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="7" lg="4" xl="4">
                                    <!-- Tax code -->
                                    <ValidationProvider :rules="{ required: true, taxCode: true }" :name="`${$tc('message.taxCode', 1)} (*)`"
                                                        ref="validationTaxCode"
                                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.taxCode', 1)} (*)`">
                                            <b-form-input id="tax-code"
                                                          type="text"
                                                          v-model="input.codice_fiscale"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                                    <!-- Province residence -->
                                    <ValidationProvider :rules="{ required: true, max: 30 }" :name="`${$tc('message.provinceResidence', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.provinceResidence', 1)} (*)`">
                                            <b-form-input id="province-residence"
                                                          type="text"
                                                          v-model="input.provincia_residenza"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                                    <!-- Common residence -->
                                    <ValidationProvider :rules="{ required: true, max: 30 }" :name="`${$tc('message.commonResidence', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.commonResidence', 1)} (*)`">
                                            <b-form-input id="common-residence"
                                                          type="text"
                                                          v-model="input.comune_residenza"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <!-- Residence address -->
                                    <ValidationProvider :rules="{ required: true, max: 50 }" :name="`${$tc('message.residenceAddress', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.residenceAddress', 1)} (*)`">
                                            <b-form-input id="residence-address"
                                                          type="text"
                                                          v-model="input.indirizzo_residenza"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <!-- Official email -->
                                    <ValidationProvider :rules="{ max: 50, email: true, required: true }" :name="`${$tc('message.officialEmail', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.officialEmail', 1)} (*)`">
                                            <b-form-input id="official-email"
                                                          v-model="input.email_ufficiale"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Secondary email -->
                                    <ValidationProvider :rules="{ max: 50, email: true }" :name="`${$tc('message.secondaryEmail', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.secondaryEmail', 1)}`">
                                            <b-form-input id="secondary-email"
                                                          v-model="input.email_secondaria"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row>
                                <b-col cols="12" sm="12" md="6" lg="6" xl="6">
                                    <!-- Official phone -->
                                    <ValidationProvider :rules="{ min: 0, max: 20, required: true }" :name="`${$tc('message.officialPhone', 1)} (*)`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.officialPhone', 1)} (*)`">
                                            <b-form-input id="official-phone"
                                                          type="number"
                                                          min="0"
                                                          v-model="input.telefono_principale"
                                                          :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                                    <!-- Secondary phone -->
                                    <ValidationProvider :rules="{ min: 0, max: 20 }" :name="`${$tc('message.secondaryPhone', 1)}`" v-slot="{ valid, errors }">
                                        <b-form-group :label="`${$tc('message.secondaryPhone', 1)}`">
                                            <b-form-input id="secondary-phone"
                                                          type="number"
                                                          min="0"
                                                          v-model="input.telefono_secondario"
                                                          :state="lodash.first(errors) ? false : null">
                                            </b-form-input>

                                            <b-form-invalid-feedback id="inputLiveFeedback">
                                                {{ lodash.first(errors) }}
                                            </b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>

                            <b-row class="my-border-top">
                                <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                                    <b-button id="user-button"
                                              v-if="operatorId !== 'new'"
                                              type="button"
                                              :disabled="itemChanged"
                                              class="float-left mt-2 mr-2"
                                              @click="openUserModal()">{{$tc('message.manageUser', 1)}}
                                    </b-button>
                                </b-col>

                                <b-col cols="12" sm="12" md="6" lg="8" xl="8">
                                    <b-container class="m-0 p-0">
                                        <b-row align-h="end">
                                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                                <b-button id="save"
                                                          type="button"
                                                          class="float-right ml-3 mt-2 b-c-green"
                                                          :disabled="invalid || pending || !itemChanged"
                                                          @click="save(false)"> {{$tc('message.save', 1)}}
                                                </b-button>
                                            </b-col>

                                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                                <b-button id="save-and-exit"
                                                          type="button"
                                                          class="float-right ml-3 mt-2 b-c-green"
                                                          :disabled="invalid || pending || !itemChanged"
                                                          @click="save(true)"> {{$tc('message.saveClose', 1)}}
                                                </b-button>
                                            </b-col>

                                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                                <b-button id="cancel-button"
                                                          type="button"
                                                          class="float-right ml-3 mt-2"
                                                          @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </b-col>
                            </b-row>
                        </b-container>
                    </ValidationObserver>
                </div>
            </div>
        </div>

        <b-modal ref="userModal" id="userModal" :title="`${$tc('message.manageUser', 1)}`" @hide="checkClosingUserModal" hide-footer>
            <ValidationObserver ref="userForm" v-slot="{ validate, invalid, pending }">
                <b-container fluid>
                    <b-row align-h="center">
                        <b-col cols="12" sm="12" md="12" lg="10" xl="10">
                            <!-- Username -->
                            <ValidationProvider :rules="{ min: 6, max: 50, required: true }" :name="`${$tc('message.username', 1)} (*)`" v-slot="{ valid, errors }">
                                <b-form-group :label="`${$tc('message.username', 1)} (*)`">
                                    <b-form-input id="username"
                                                  type="text"
                                                  v-model="userInput.username"
                                                  :state="lodash.first(errors) && userItemChanged ? false : (valid ? (userItemChanged ? true : null) : null)">
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputLiveFeedback">
                                        {{ lodash.first(errors) }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col cols="12" sm="12" md="12" lg="10" xl="10">
                            <!-- Password -->
                            <ValidationProvider :rules="{ required: true, min: 6 }" vid="mainPassword"
                                                :name="`${$tc('message.password', 1)} (*)`" v-slot="{ valid, errors }">
                                <b-form-group :label="`${$tc('message.password', 1)} (*)`">
                                    <b-form-input id="password"
                                                  type="password"
                                                  v-model="userInput.password"
                                                  :state="lodash.first(errors) && userItemChanged ? false : (valid ? true : null)">
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputLiveFeedback">
                                        {{ lodash.first(errors) }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col cols="12" sm="12" md="12" lg="10" xl="10">
                            <!-- Password check -->
                            <ValidationProvider :rules="{ required: true, passwordCheck: [passwordCheck, userInput.password, '', ''] }"
                                                :name="`${$tc('message.passwordCheck', 1)}`" v-slot="{ valid, errors }">
                                <b-form-group :label="`${$tc('message.passwordCheck', 1)} (*)`">
                                    <b-form-input id="password-confirm"
                                                  type="password"
                                                  v-model="passwordCheck"
                                                  :state="lodash.first(errors) && userItemChanged ? false : (valid ? true : null)">
                                    </b-form-input>

                                    <b-form-invalid-feedback id="inputLiveFeedback">
                                        {{ lodash.first(errors) }}
                                    </b-form-invalid-feedback>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                        <b-col cols="12" sm="12" md="12" lg="10" xl="10">
                            <!-- Password check -->
                            <ValidationProvider :rules="{ required: true }" :name="`${$tc('message.role', 1)}`" v-slot="{ valid, errors }">
                                <b-form-group :label="`${$tc('message.role', 1)} (*)`">
                                    <v-select :options="roleOptions"
                                              ref="role"
                                              id="role"
                                              :reduce="obj => obj.id"
                                              :class="checkSelectStyle('role', userItemChanged, valid, errors, true)"
                                              label="nome"
                                              v-model="userInput.ruolo.id">
                                    </v-select>

                                    <div id="legalPerson-inputLiveFeedback" v-if="errors.length !== 0" class="my-invalid-feedback">
                                        {{ lodash.first(errors) }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                    </b-row>
                </b-container>

                <b-row class="my-border-top">
                    <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                        <b-button id="user-status"
                                  type="button"
                                  v-if="userInput.id"
                                  :class="`float-left ml-3 mt-2 ${userInput.sospeso ? 'b-c-green' : 'b-c-red'}`"
                                  @click="changeUserStatus(!userInput.sospeso)"> {{userInput.sospeso ? $tc('message.enable', 1) : $tc('message.disable', 1)}}
                        </b-button>
                    </b-col>

                    <b-col cols="12" sm="12" md="6" lg="8" xl="8">
                        <b-container class="m-0 p-0">
                            <b-row align-h="end">
                                <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                    <b-button id="user-save"
                                              type="button"
                                              class="float-right ml-3 mt-2 b-c-green"
                                              :disabled="invalid || pending || !userItemChanged"
                                              @click="saveUser(true)"> {{$tc('message.save', 1)}}
                                    </b-button>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                    <b-button id="user-cancel-button"
                                              type="button"
                                              class="float-right ml-3 mt-2"
                                              @click="$refs.userModal.hide('button')"> {{$tc('message.close', 1)}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-col>
                </b-row>
            </ValidationObserver>
        </b-modal>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'
  import { DateTime } from 'luxon'

  export default {
    name: 'EditOperator',
    created () {
      this.fetchData()
    },
    mounted () {
      this.$root.$on('change-lang', () => {
        this.$nextTick(() => {
          if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
        })
      })
    },
    data () {
      const operatorId = this.$route.params.id
      const lodash = _
      const backPath = '/registry/operator'
      return {
        backPath,
        operatorId,
        lodash,
        DateTime,
        input: {},
        originalUser: { ruolo: {} },
        userInput: { ruolo: {} },
        watcher: null,
        itemChanged: false,
        userWatcher: null,
        userItemChanged: false,
        roleOptions: [],
        passwordCheck: null
      }
    },
    methods: {
      resetWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        this.itemChanged = false
      },
      addWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcher = this.$watch('input', () => {
          this.itemChanged = true
        }, { deep: true })
      },
      resetUserWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        this.userItemChanged = false
      },
      addUserWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcher = this.$watch('userInput', () => {
          this.userItemChanged = true
        }, { deep: true })
      },
      fetchData () {
        this.resetWatcher()
        this.input = {}
        this.userInput = { ruolo: {} }
        return this.getOperator().then(() => {
          const session = Vue.getLocalStorage('session')
          const roleId = session && session.utente && session.utente.ruolo && session.utente.ruolo.id
          if (roleId) this.getTripManager(roleId) // superadmin
          this.addWatcher()
          return true
        })
      },
      getTripManager (roleId) {
        if (roleId === 1) {
          Vue.myGet(this, '/bus/gestore_gita', 'gestore').then(tripManagers => {
            if (tripManagers.length !== 0) this.input.id_gestore_gita = _.first(tripManagers).id
            else Vue.error(this, '')
          })
        }
      },
      getOperator () {
        if (this.operatorId !== 'new') {
          return Vue.myGet(this, `/operatore/${this.operatorId}`, 'operatori').then((operator) => {
            this.input = operator
            if (operator.id_utente) {
              return this.getRoleOptions().then(() => {
                return this.getUsers(operator.id_utente)
              })
            }
            return this.getRoleOptions()
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        } else {
          return new Promise(resolve => resolve())
        }
      },
      getRoleOptions () {
        this.roleOptions = []
        return Vue.myGet(this, '/ruolo', 'ruoli').then((roles) => {
          const editedRoles = []
          roles.forEach(role => {
            if (role.id !== 1) {
              editedRoles.push(role)
            }
          })
          this.roleOptions = editedRoles
          return true
        })
      },
      getUsers (id) {
        this.originalUser = { ruolo: {} }
        return Vue.myGet(this, `/utente/${id}`, 'utenti').then(user => {
          this.originalUser = user
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      },
      exitCheck (itemChanged) {
        if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
        else this.$router.push(this.backPath)
      },
      save (pushBack, skipNotice) {
        const editedItem = this.parseInputItem(_.cloneDeep(this.input))
        Vue.patchOrPost(this.operatorId !== 'new',
        `/operatore${this.operatorId !== 'new' ? '/' + this.operatorId : ''}`, editedItem).then(r => {
          if (!skipNotice) Vue.success(this, `${this.$tc('message.operator', 1)} ${this.$t(this.operatorId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          if (pushBack) this.$router.push(this.backPath)
          else this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditOperator' } })
        }).catch(errorObj => {
          const error = errorObj.response
          if (error && error.data && error.data.constraint && error.data.constraint === 'codice_fiscale' &&
          this.$refs && this.$refs.validationTaxCode) {
            this.$refs.validationTaxCode.setErrors([this.$tc('constraints.codice_fiscale', 1)])
          }
          Vue.manageErrors(this, errorObj)
        })
      },
      parseInputItem (item) {
        const editedItem = {}
        const intFields = ['id_utente', 'id']
        _.forOwn(item, (val, field) => {
          const mustBeInt = _.find(intFields, obj => obj === field)
          if (mustBeInt) {
            editedItem[field] = val === 0 ? 0 : (parseInt(val) || null)
          } else {
            editedItem[field] = val === 0 ? 0 : (val || null)
          }
        })
        return editedItem
      },
      checkSelectStyle (ref, groupChanged, valid, errors, required) {
        Vue.MySelectValidationClass(this, ref, groupChanged, valid, errors, required)
      },
      openUserModal () {
        this.userInput = _.cloneDeep(this.originalUser) || { ruolo: {} }
        this.passwordCheck = null
        this.$refs.userModal.show()
        this.resetUserWatcher()
        this.addUserWatcher()
      },
      checkClosingUserModal (obj) {
        if (obj.trigger && this.userItemChanged) {
          obj.preventDefault()
          this.$root.$emit('exit-check', { modal: this.$refs.userModal })
        }
      },
      saveUser () {
        const user = _.cloneDeep(this.userInput)
        user.scadenza_psw = -1
        user.sospeso = true
        Vue.patchOrPost(user.id, `/utente${user.id ? '/' + user.id : ''}`, user).then(r => {
          Vue.success(this, `${this.$tc('message.user', 1)} ${this.$t(user.id ? 'success.putSuccess' : 'success.postSuccess')}`)
          this.input.id_utente = user.id || r.data.id
          this.save(false, true)
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      },
      changeUserStatus (newVal) {
        const user = _.cloneDeep(this.userInput)
        user.sospeso = newVal
        Vue.patchOrPost(user.id, `/utente${user.id ? '/' + user.id : ''}`, user).then(r => {
          Vue.success(this, `${this.$tc('message.user', 1)} ${this.$tc(newVal ? 'message.disabled' : 'message.enabled', 0).toLowerCase()}`)
          this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditOperator' } })
        }).catch(error => {
          Vue.manageErrors(this, error)
        })
      }
    }
  }
</script>
