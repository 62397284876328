<template>
  <b-container style="max-width: 90% !important;">
    <b-card class="mt-4 mb-4">
      <standard-table ref="table"
                      :items="items"
                      :fields="fields"
                      :row-before-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 1,orderMd: 1,orderLg: 3,orderXl: 3}"
                      :row-search="{cols: 12,sm: 12,md: 12,lg: 4,xl: 4,order: 1,orderSm: 2,orderMd: 2,orderLg: 1,orderXl: 1}"
                      :row-after-search="{cols: 12, sm: 12, md: 12, lg: 4, xl: 4, order: 2, orderSm: 3, orderMd: 3, orderLg: 2, orderXl: 2}"
                      :row-pagination="{cols: 12, sm: 12, md: 6, lg: 5, xl: 5}"
                      :row-between-pagination="{cols: 12, sm: 12, md: 1, lg: 1, xl: 4}"
                      :row-per-page="{cols: 12, sm: 12, md: 5, lg: 3, xl: 2}"
                      :row-after-per-page="{cols: 12, sm: 12, md: 12, lg: 3, xl: 1}"
                      fullWidth="true"
                      noContainerPadding="true">
        <b-container slot="row-after-per-page" class="mw-100 p-0">
          <b-row>
            <b-col>
              <b-button class="float-right mt-3 mt-md-0 mt-lg-0 mt-xl-0" id="excel-button"
                        @click="$refs.table.excel($tc('message.trip', 1), customFields, itemsForExcel)">Excel
              </b-button>
            </b-col>
          </b-row>
        </b-container>

        <b-container slot="after-search" class="mw-100 p-0">
          <b-collapse id="my-collapse" v-model="showCollapse">
            <b-container class="mw-100 m-0 p-0">
              <b-row>
                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <b-form-group :label="`${$tc('message.from', 1).toLowerCase()}`">
                    <b-input-group>
                      <flat-pickr id="start"
                                  ref="start"
                                  v-model="startFilter"
                                  :config="startConfig">
                      </flat-pickr>

                      <div class="input-group-append">
                        <b-button class="fas fa-trash"
                                  :disabled="true">
                        </b-button>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" md="6" lg="6" xl="3">
                  <b-form-group :label="`${$tc('message.to', 1).toLowerCase()}`">
                    <b-input-group>
                      <flat-pickr id="end"
                                  ref="end"
                                  v-model="endFilter"
                                  :config="endConfig">
                      </flat-pickr>

                      <div class="input-group-append">
                        <b-button class="fas fa-trash"
                                  :disabled="true">
                        </b-button>
                      </div>
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </b-collapse>
        </b-container>

        <b-container slot="row-after-search" class="mw-100 p-0">
          <b-row>
            <b-col>
              <b-button @click="showCollapse = !showCollapse"
                        :class="showCollapse ? 'b-c-red mb-2 mr-2' : 'b-c-green mb-2 mr-2'" id="show-filters">
                <span v-show="!showCollapse"
                      class="when-opened">{{ $t('message.show') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
                <span v-show="showCollapse"
                      class="when-closed">{{ $t('message.hide') }} {{ $tc('message.filter', 2).toLowerCase() }}</span>
              </b-button>
            </b-col>
          </b-row>
        </b-container>
      </standard-table>
    </b-card>
    <b-modal
        ref="tripChoiceModal"
        id="tripChoiceModal"
        no-close-on-backdrop
        no-close-on-esc
        :ok-disabled="tripFilter === null"
        ok-only
        hide-header-close
        @ok="choiceOk = true; fetchData()"
    >
      <b-container>
        <h5>Scegli una gita</h5>
        <b-form-group :label="`${$tc('message.trip', 1)}`">
          <v-select :options="tripOptions"
                    label="nome"
                    placeholder="Seleziona gita"
                    v-model="tripFilter">
          </v-select>
        </b-form-group>
      </b-container>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _, { isNil } from 'lodash'
import { DateTime } from 'luxon'
import { Italian } from 'flatpickr/dist/l10n/it'

export default {
  name: 'ReportTrip',
  created () {
    this.yearNumber = Vue.getLocalStorage('yearNumber')
    this.firstDate = new Date(this.yearNumber, 8, 1)
    this.lastDate = DateTime.local(this.yearNumber + 1, 8, 30)
    this.startConfig.minDate = new Date(new Date(this.endFilter).setMonth(new Date(this.endFilter).getMonth() - 3)) > new Date(this.firstDate)
        ? new Date(this.endFilter).setMonth(new Date(this.endFilter).getMonth() - 3) : this.firstDate
    this.endConfig.maxDate = new Date(new Date(this.startFilter).setMonth(new Date(this.startFilter).getMonth() + 3)) <= new Date() &&
    new Date(new Date(this.startFilter).setMonth(new Date(this.startFilter).getMonth() + 3)) <= new Date(this.lastDate)
        ? new Date(new Date(this.startFilter).setMonth(new Date(this.startFilter).getMonth() + 3))
        : new Date(new Date(this.startFilter).setMonth(new Date(this.startFilter).getMonth() + 3)) > new Date(this.lastDate) ? new Date(this.lastDate)
            : new Date()
    this.fetchTripsName()
  },
  data () {
    const yearId = Vue.getLocalStorage('year')
    const startFilter = `${DateTime.local().toISODate()}`
    const endFilter = `${DateTime.local().toISODate()}`
    return {
      yearId,
      startFilter,
      endFilter,
      firstDate: null,
      lastDate: null,
      yearNumber: null,
      items: [],
      tripOptions: [],
      tripFilter: null,
      fields: [
        {
          key: 'data',
          label: `${this.$tc('message.date', 1)}`,
          sortable: true,
          sortDirection: 'desc'
        },
        { key: 'bambino', label: `${this.$tc('message.child', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'stato', label: `${this.$tc('message.status', 1)}`, sortable: true, sortDirection: 'desc' },
        { key: 'gita', label: `${this.$tc('message.trip', 1)}`, sortable: true, sortDirection: 'desc' }
      ],
      customFields: [],
      itemsForExcel: [],
      datesString: [],
      showCollapse: false,
      startConfig: {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: null,
        maxDate: endFilter,
        disable: []
      },
      endConfig: {
        dateFormat: 'Y-m-d',
        mode: 'single',
        altFormat: 'D j M Y',
        altInput: true,
        locale: Italian,
        minDate: startFilter,
        maxDate: null,
        disable: []
      },
      choiceOk: false,
      childrenOfThisYear: []
    }
  },
  methods: {
    fetchTripsName () {
      this.$root.$emit('activeLoader', true)
      Vue.myGet(this, '/bus/gita', 'gite').then((trips) => {
        trips.forEach(trip => {
          this.tripOptions.push({ nome: trip.nome, id: trip.id })
        })
            Vue.myGet(this, '/struttura_bambino?anno=' + this.yearId, 'associazioni').then((structureChildren) => {
              structureChildren.forEach((child) => {
                this.childrenOfThisYear.push(child)
              })
              this.$root.$emit('activeLoader', false)
              this.$refs.tripChoiceModal.show()
            }).catch(error => {
              Vue.manageErrors(this, error)
            })
      }).catch(error => {
        Vue.manageErrors(this, error)
      })
    },
    fetchData () {
      this.datesString = []
      this.items = []
      this.customFields = [
        { key: 'bambino', label: `${this.$tc('message.nominative', 1)}` }
      ]
      this.itemsForExcel = []
      let lastItemExcel = {
        bambino: 'Totale bambini'
      }
      this.$root.$emit('activeLoader', true)
      const dates = this.getDatesInRange(!isNil(this.startFilter)
          ? new Date(this.startFilter)
          : new Date(), !isNil(this.endFilter)
          ? new Date(this.endFilter)
          : !isNil(this.startFilter)
              ? new Date(this.startFilter) : new Date())
      dates.forEach((item) => {
        this.datesString.push(this.formatDate(item))
        this.customFields.push({ key: this.formatDate(item), label: this.formatDate(item) })
      })
      this.customFields.push({ key: 'totalePresenze', label: `${this.$tc('message.totalChildAttendance', 1)}` })
      var url = '/bus/gita_bambino'
      if (!_.isNil(this.tripFilter)) {
        url = url + '?gita=' + this.tripFilter.id
      }
      Vue.myGet(this, url, 'bambini').then((childrenTrip) => {
        url = '/bus/registro_fermata?'
        if (!_.isNil(this.tripFilter)) {
          url = url + 'gita=' + this.tripFilter.id + '&'
        }
        if (!_.isNil(this.startFilter)) {
          url += 'da=' + this.startFilter + '&'
          var filter = new Date(new Date(this.endFilter).setDate(new Date(this.endFilter).getDate() + 1)).toISOString().split('T')[0]
          if (_.isNil(this.endFilter)) {
            this.endFilter = this.startFilter
            filter = new Date(new Date(this.startFilter).setDate(new Date(this.startFilter).getDate() + 1)).toISOString().split('T')[0]
          }
          url += 'a=' + filter
        }
        Vue.myGet(this, url, 'registro_fermate').then((childrenTripStop) => {
          if (childrenTripStop.length > 0) {
            childrenTripStop.forEach(childTripStop => {
              childTripStop.bambini.forEach((child) => {
                const foundedChildOnThisYear = _.find(this.childrenOfThisYear, childStructure => childStructure.id === child.id_struttura_bambino)
                if (!isNil(foundedChildOnThisYear)) {
                  const date = this.formatDate(childTripStop.data)
                  const foundedChild = _.find(this.items, item => item.bambino === child.nome)
                  if (isNil(foundedChild)) {
                    this.items.push({
                      bambino: child.nome,
                      data: date,
                      stato: 'Presente',
                      gita: _.find(this.tripOptions, (trip) => trip.id === childTripStop.id_gita).nome
                    })
                  }

                  const foundedItem = _.find(this.itemsForExcel, item => item.id_struttura_bambino === child.id_struttura_bambino)
                  const newChild = {
                    id_struttura_bambino: child.id_struttura_bambino,
                    bambino: child.nome.trim(),
                    totalePresenze: 0
                  }
                  if (!isNil(foundedItem)) {
                    if (child.salita && foundedItem[date] !== 'X') {
                      foundedItem[date] = 'X'
                      foundedItem['totalePresenze'] += 1
                    }
                  } else {
                    newChild['totalePresenze'] += 1
                    newChild[date] = 'X'
                    this.itemsForExcel.push(newChild)
                  }
                }
              })
            })
          }
          childrenTrip.forEach((childTrip) => {
            const foundedChildOnThisYear = _.find(this.childrenOfThisYear, childStructure => childStructure.id === childTrip.id_struttura_bambino)
            if (!isNil(foundedChildOnThisYear)) {
              const foundedItem = _.find(this.itemsForExcel, item => item.id_struttura_bambino === childTrip.id_struttura_bambino)
              let newChild = {
                id_struttura_bambino: childTrip.id_struttura_bambino,
                bambino: (childTrip.bambino.nome + ' ' + childTrip.bambino.cognome).trim(),
                totalePresenze: 0
              }
              if (!isNil(foundedItem)) {
                newChild = _.cloneDeep(foundedItem)
              }
              this.datesString.forEach((date) => {
                if (isNil(_.find(this.items, item => item.data === date && item.bambino === (childTrip.bambino.nome + ' ' + childTrip.bambino.cognome).trim()))) {
                  this.items.push(
                      {
                        bambino: (childTrip.bambino.nome + ' ' + childTrip.bambino.cognome).trim(),
                        data: date,
                        stato: 'Assente',
                        gita: !isNil(_.find(this.tripOptions, (trip) => trip.id === this.tripFilter.id))
                            ? _.find(this.tripOptions, (trip) => trip.id === this.tripFilter.id).nome : ''
                      }
                  )
                  newChild[date] = ''
                }
              })
              if (isNil(foundedItem)) {
                this.itemsForExcel.push(newChild)
              }
            }
          })
          this.items = this.sortFormattedDate(this.items)
          this.customFields.forEach((field) => {
            if (field.key !== 'bambino' && field.key !== 'totalePresenze') {
              lastItemExcel[field.key] = this.itemsForExcel.filter(item => item[field.key] === 'X').length
            }
          })
          this.itemsForExcel.push(lastItemExcel)
          this.$root.$emit('activeLoader', false)
        })
      }).catch(error => {
        Vue.manageErrors(this, error)
      })
    },
    formatDate (date) {
      const day = new Date(date).getDate().toString().length === 1 ? '0' + new Date(date).getDate().toString() : new Date(date).getDate().toString()
      const month = (new Date(date).getMonth() + 1).toString().length === 1 ? '0' + (new Date(date).getMonth() + 1).toString() : (new Date(date).getMonth() + 1).toString()
      const year = new Date(date).getFullYear()
      return day + '/' +
          month + '/' +
          year
    },
    sortFormattedDate (items) {
      return items.sort(function (firstDate, secondDate) {
        let firstDateArray = firstDate.data.split('/')
        let convertFirstDateArray = new Date(firstDateArray[2] + '/' + firstDateArray[1] + '/' + firstDateArray[0])
        let secondDateArray = secondDate.data.split('/')
        let convertSecondDateArray = new Date(secondDateArray[2] + '/' + secondDateArray[1] + '/' + secondDateArray[0])
        return new Date(convertFirstDateArray) - new Date(convertSecondDateArray)
      })
    },
    resetFilters () {
      this.tripFilter = null
      this.$refs.table.resetSearch()
    },
    getDatesInRange (startDate, endDate) {
      const date = new Date(startDate.getTime())

      const dates = []

      // eslint-disable-next-line no-unmodified-loop-condition
      while (date <= endDate) {
        dates.push(new Date(date))
        date.setDate(date.getDate() + 1)
      }

      return dates
    }
  },
  watch: {
    tripFilter () {
      if (this.tripFilter !== null && this.choiceOk) {
        this.fetchData()
      }
    },
    startFilter () {
      this.endConfig.minDate = this.startFilter
      this.endConfig.maxDate = new Date(new Date(this.startFilter).setMonth(new Date(this.startFilter).getMonth() + 3)) <= new Date() &&
      new Date(new Date(this.startFilter).setMonth(new Date(this.startFilter).getMonth() + 3)) <= new Date(this.lastDate)
          ? new Date(new Date(this.startFilter).setMonth(new Date(this.startFilter).getMonth() + 3))
          : new Date(new Date(this.startFilter).setMonth(new Date(this.startFilter).getMonth() + 3)) > new Date(this.lastDate) ? new Date(this.lastDate)
              : new Date()
      this.fetchData()
    },
    endFilter () {
      this.startConfig.maxDate = this.endFilter
      this.startConfig.minDate = new Date(new Date(this.endFilter).setMonth(new Date(this.endFilter).getMonth() - 3)) > new Date(this.firstDate)
          ? new Date(this.endFilter).setMonth(new Date(this.endFilter).getMonth() - 3) : this.firstDate
      this.fetchData()
    }
  }
}
</script>

<style scoped>

</style>
