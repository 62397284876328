<template>
    <div class="login-image pb-5 pt-5" :style="loginImage">
        <b-container class="h-100">
            <b-row align-h="center" align-v="center" class="h-75">
                <b-button v-show="input.password === 'develop'" @click="input = {username: 'superadmin', password: 'superadmin'}">SA</b-button>

                <b-button v-show="input.password === 'develop'" @click="input = {username: 'admin_awtech', password: 'awtech2019'}">A</b-button>

                <b-col cols="12" sm="12" md="6" lg="4" xl="4">
                    <b-card class="mt-4 b-c-yellow-opacity">
                        <b-form id="login-form">
                            <b-row align-h="center" class="mb-4">
                                <i class="login-logo"/>
                            </b-row>

                            <b-row align-h="center">
                                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <!-- User -->
                                    <b-form-group>
                                        <b-form-input v-model="input.username"
                                                      autocomplete="current-username"
                                                      id="input-username"
                                                      :placeholder="$tc('message.user', 1)">
                                        </b-form-input>
                                        <i class="fas fa-user mt-2 form-icon c-green"/>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                                    <!-- Password -->
                                    <b-form-group>
                                        <b-form-input v-model="input.password"
                                                      autocomplete="current-password"
                                                      id="input-password"
                                                      type="password"
                                                      :placeholder="$t('message.password')">
                                        </b-form-input>
                                        <i class="fas fa-lock mt-2 form-icon c-green"/>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="showYear">
                                    <b-container class="p-0 m-0 form-control mt-2">
                                        <b-row>
                                            <b-col cols="auto">
                                                <i class="fas fa-calendar-alt mt-2 form-icon c-green my-login-icon"/>
                                            </b-col>

                                            <b-col>
                                                <!-- Year -->
                                                <b-form-group class="my-v-select">
                                                    <v-select :options="yearOptions"
                                                              id="input-year"
                                                              :reduce="obj => obj.id"
                                                              label="descrizione"
                                                              v-model="yearFilter">
                                                    </v-select>
                                                </b-form-group>
                                            </b-col>
                                        </b-row>
                                    </b-container>
                                </b-col>
                            </b-row>

                            <b-row align-h="center" class="mt-2">
                                <b-col>
                                    <b-button id="login-button" v-on:click="setLocalStorage(showYear)" class="w-100 b-c-green" :disabled="checkLoginButton()">
                                        {{$tc('message.login', 1)}}
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-form>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'

  export default {
    name: 'Login',
    created () {
      this.showYear = false
    },
    watch: {
      'input.username' () {
        this.showYear = false
      },
      'input.password' () {
        this.showYear = false
      }
    },
    mounted () {
      this.getLoginImage()
      this.$root.$on('resize', () => {
        this.getLoginImage()
      })
    },
    data () {
      return {
        loginImage: '',
        input: {},
        showYear: false,
        yearOptions: [],
        yearFilter: null
      }
    },
    methods: {
      getLoginImage () {
        const size = Vue.myGetWindowSize()
        let backGround = ''

        switch (size) {
          case 'cols':
            backGround = 'background: url("/img/bbbus-login-720x1280.jpg")'
            break

          case 'sm':
            backGround = 'background: url("/img/bbbus-login-720x1280.jpg")'
            break

          case 'md':
            backGround = 'background: url("/img/bbbus-login-1024x683.jpg")'
            break

          default:
            backGround = 'background: url("/img/bbbus-login-1920x1280.jpg")'
            break
        }
        this.loginImage = backGround
      },
      checkLoginButton () {
        return !this.input.username || !this.input.password || (this.showYear ? !this.yearFilter : false)
      },
      setLocalStorage (showYear) {
        if (showYear) {
          const session = Vue.getLocalStorage('session')
          const roleId = session && session.utente && session.utente.ruolo && session.utente.ruolo.id

          Vue.setLocalStorage('year', this.yearFilter)
          const year = _.find(this.yearOptions, year => year.id === this.yearFilter)
          Vue.setLocalStorage('yearNumber', year.anno)

          this.logIn(roleId, true)
        } else {
          Vue.myLogin(this, this.input.username, this.input.password).then(r => {
            const roleId = r && r.data && r.data.utente && r.data.utente.ruolo && r.data.utente.ruolo.id
            this.logIn(roleId)
          })
        }
      },
      logIn (roleId, changeView) {
        switch (roleId) {
          case 1: // super admin role Id
            this.$router.push('/superadmin').catch(() => {})
            Vue.success(this, `${this.$tc('message.login')} ${this.$tc('message.done', 1).toLowerCase()}`)
            break

          case 2: // admin role Id
            if (changeView) this.$router.push('/admin').catch(() => {})
            else this.showYearAndStructureOptions()
            break

          default:
            Vue.error(this, this.$tc('warnings.roleNotEnabled'))
            Vue.deleteLocalStorage('session')
            Vue.deleteLocalStorage('year')
            Vue.deleteLocalStorage('structure')
            break
        }
      },
      showYearAndStructureOptions () {
        this.showYear = true
        this.fetchData()
      },
      fetchData () {
        this.getOptions()
      },
      getOptions () {
        const axiosArray = []
        axiosArray.push(this.getYears())
        return Vue.myAll(axiosArray)
      },
      getYears () {
        return Vue.myGet(this, '/anno_scolastico', 'anni').then(years => {
          this.yearOptions = _.orderBy(years, 'anno', 'desc')
          const session = Vue.getLocalStorage('session')
          const roleId = session && session.utente && session.utente.ruolo && session.utente.ruolo.id
          if (roleId && roleId !== 1) { // superadmin
            const userId = session.utente.id
            Vue.myGet(this, `/operatore?utente=${userId}`, 'operatori').then(operators => {
              Vue.setLocalStorage('trip-manager', _.first(operators).id_gestore_gita)
            })
          }
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      }
    }
  }
</script>

<style scoped>
    .login-logo {
        height: calc(100px + 5 * ((90vw - 100px) / 100));
        width: calc(100px + 5 * ((90vw - 100px) / 100));
        background: url("../../public/logo.svg") no-repeat center;
        background-size: cover;
        position: relative;
    }

    .login-logo::before{
        left: 18px;
        top: 10px;
        position: relative;
    }

    .form-icon {
        position: absolute;
        top: 2px;
        left: 28px;
    }

    #input-username, #input-password {
        padding-left: 40px;
    }

    .card {
        background: rgba(255, 255, 255, 0.6);
    }
</style>
