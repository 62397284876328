<template>
    <b-container fluid>
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <header class="modal-header">
                    <h5 class="my-modal-title">{{$tc('message.bus', 1)}}</h5>
                </header>

                <div class="modal-body">
                    <ValidationObserver ref="form" v-slot="{ validate, invalid, pending }">
                        <b-row>
                            <b-col cols="12" sm="12" md="12" lg="3" xl="3">
                                <!-- Plate number -->
                                <ValidationProvider :rules="{ required: true, max: 9 }" :name="`${$tc('message.plateNumber', 1)} (*)`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.plateNumber', 1)} (*)`">
                                        <b-form-input id="plate-number"
                                                      type="text"
                                                      v-model="input.targa"
                                                      :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="9" xl="9">
                                <!-- Description -->
                                <ValidationProvider :rules="{ max: 50 }" :name="`${$tc('message.description', 1)}`" v-slot="{ valid, errors }">
                                    <b-form-group :label="`${$tc('message.description', 1)}`">
                                        <b-form-input id="denomination"
                                                      type="text"
                                                      v-model="input.descrizione"
                                                      :state="lodash.first(errors) && itemChanged ? false : null">
                                        </b-form-input>

                                        <b-form-invalid-feedback id="inputLiveFeedback">
                                            {{ lodash.first(errors) }}
                                        </b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </b-col>
                        </b-row>

                        <b-row class="my-border-top" align-h="end">
                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="save"
                                          type="button"
                                          class="float-right mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged"
                                          @click="save(false)"> {{$tc('message.save', 1)}}
                                </b-button>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="save-and-exit"
                                          type="button"
                                          class="float-right mt-2 b-c-green"
                                          :disabled="invalid || pending || !itemChanged"
                                          @click="save(true)"> {{$tc('message.saveClose', 1)}}
                                </b-button>
                            </b-col>

                            <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                                <b-button id="cancel-button"
                                          type="button"
                                          class="float-right mt-2"
                                          @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                                </b-button>
                            </b-col>
                        </b-row>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </b-container>
</template>

<script>
  import Vue from 'vue'
  import _ from 'lodash'

  export default {
    name: 'EditBus',
    created () {
      this.fetchData().then(() => {
        this.addWatcher()
      })
    },
    mounted () {
      this.$root.$on('change-lang', () => {
        this.$nextTick(() => {
          if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
        })
      })
    },
    data () {
      const busId = this.$route.params.id
      const lodash = _
      const backPath = '/registry/bus'
      return {
        backPath,
        busId,
        lodash,
        input: {},
        watcher: null,
        itemChanged: false
      }
    },
    methods: {
      resetWatcher () {
        this.$root.$emit('activeLoader', true)
        if (this.watcher) this.watcher()
        this.itemChanged = false
      },
      addWatcher () {
        this.$root.$emit('activeLoader', false)
        this.watcher = this.$watch('input', () => {
          this.itemChanged = true
        }, { deep: true })
      },
      fetchData () {
        this.resetWatcher()
        const id = this.busId
        if (id !== 'new') {
          return Vue.myGet(this, `/bus/mezzo/${id}`, 'mezzi').then(result => {
            this.input = result
            return true
          }).catch(error => {
            Vue.manageErrors(this, error)
            return true
          })
        } else {
          const session = Vue.getLocalStorage('session')
          const roleId = session && session.utente && session.utente.ruolo && session.utente.ruolo.id
          this.getTripManager(roleId)
        }
        return new Promise(resolve => resolve())
      },
      getTripManager (roleId) {
        if (roleId === 1) {
          Vue.myGet(this, '/bus/gestore_gita', 'gestore').then(tripManagers => {
            if (tripManagers.length !== 0) this.input.id_gestore_gita = _.first(tripManagers).id
            else Vue.error(this, '')
          })
        } else {
          this.input.id_gestore_gita = parseInt(Vue.getLocalStorage('trip-manager'))
        }
      },
      exitCheck (itemChanged) {
        if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
        else this.$router.push(this.backPath)
      },
      save (pushBack) {
        const editedItem = this.parseInputItem(_.cloneDeep(this.input))
        Vue.patchOrPost(this.busId !== 'new',
        `/bus/mezzo${this.busId !== 'new' ? '/' + this.busId : ''}`, editedItem).then(r => {
          Vue.success(this, `${this.$tc('message.bus', 1)} ${this.$t(this.busId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
          if (pushBack) this.$router.push(this.backPath)
          else this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditBus' } })
        }).catch(errorObj => {
          Vue.manageErrors(this, errorObj)
        })
      },
      parseInputItem (item) {
        const editedItem = {}
        const intFields = ['id_utente', 'id', 'cap']
        _.forOwn(item, (val, field) => {
          const mustBeInt = _.find(intFields, obj => obj === field)
          if (mustBeInt) {
            editedItem[field] = val === 0 ? 0 : (parseInt(val) || null)
          } else {
            editedItem[field] = val === 0 ? 0 : (val || null)
          }
        })
        return editedItem
      }
    }
  }
</script>
