<template>
  <b-container fluid>
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <header class="modal-header">
          <h5 class="my-modal-title">{{$tc('message.schoolYear', 1)}}</h5>
        </header>

        <div class="modal-body">
          <ValidationObserver ref="form" v-slot="{ validate, invalid, pending }">
            <b-row>
              <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">

                <!-- Year -->
                <ValidationProvider :rules="{ max: 4, required: true, min_value: 1, min: 4}" :name="`${$tc('message.year', 1)}`" v-slot="{ valid, errors }">
                  <b-form-group :label="`${$tc('message.year', 1)} (*)`">
                    <b-form-input id="vat-number"
                                  v-model="input.anno"
                                  type="number"
                                  :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                    </b-form-input>

                    <b-form-invalid-feedback id="inputLiveFeedback">
                      {{ lodash.first(errors) }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <!-- Description -->
                <b-col class="mt-1" cols="12" sm="12" md="12" lg="6" xl="6">
                <ValidationProvider :rules="{ max: 30 }" :name="`${$tc('message.description', 1)}`" v-slot="{ valid, errors }">
                  <b-form-group :label="`${$tc('message.description', 1)} (*)`">
                    <b-form-input id="description"
                                  v-model="input.descrizione"
                                  :state="lodash.first(errors) && itemChanged ? false : (valid ? (itemChanged ? true : null) : null)">
                    </b-form-input>

                    <b-form-invalid-feedback id="inputLiveFeedback">
                      {{ lodash.first(errors) }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </ValidationProvider>
              </b-col>
            </b-row>

            <b-row class="my-border-top" align-h="end">
              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                <b-button id="save"
                          type="button"
                          class="float-right mt-2 b-c-green"
                          :disabled="invalid || pending || !itemChanged"
                          @click="save(false)"> {{$tc('message.save', 1)}}
                </b-button>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                <b-button id="save-and-exit"
                          type="button"
                          class="float-right mt-2 b-c-green"
                          :disabled="invalid || pending || !itemChanged"
                          @click="save(true)"> {{$tc('message.saveClose', 1)}}
                </b-button>
              </b-col>

              <b-col cols="12" sm="12" md="12" lg="auto" xl="auto">
                <b-button id="cancel-button"
                          type="button"
                          class="float-right mt-2"
                          @click="exitCheck(itemChanged)"> {{$tc('message.close', 1)}}
                </b-button>
              </b-col>
            </b-row>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import Vue from 'vue'
import _ from 'lodash'

export default {
  name: 'EditSchoolYear',
  created () {
    this.fetchData().then(() => {
      this.addWatcher()
    })
  },
  mounted () {
    this.$root.$on('change-lang', () => {
      this.$nextTick(() => {
        if (this && this.$refs && this.$refs.form) this.$refs.form.validate()
      })
    })
  },
  data () {
    const schoolYearId = this.$route.params.id
    const lodash = _
    const backPath = '/registry/school_years'
    return {
      backPath,
      schoolYearId,
      lodash,
      input: {},
      structures: [],
      watcher: null,
      itemChanged: false
    }
  },
  methods: {
    resetWatcher () {
      this.$root.$emit('activeLoader', true)
      if (this.watcher) this.watcher()
      this.itemChanged = false
    },
    addWatcher () {
      this.$root.$emit('activeLoader', false)
      this.watcher = this.$watch('input', () => {
        this.itemChanged = true
      }, { deep: true })
    },
    fetchData () {
      this.resetWatcher()
      this.getStructure()
      const id = this.schoolYearId
      if (id !== 'new') {
        return Vue.myGet(this, `/anno_scolastico/${id}`, 'anni').then(result => {
          this.input = result
          return true
        }).catch(error => {
          Vue.manageErrors(this, error)
          return true
        })
      }
      return new Promise(resolve => resolve())
    },
    getStructure () {
      Vue.myGet(this, '/struttura', 'strutture').then((structures) => {
        this.structures = structures
        this.$root.$emit('activeLoader', false)
      }).catch(error => {
        Vue.manageErrors(this, error)
      })
    },
    updateAllCalendars (year) {
      const axiosArray = []
      this.structures.forEach((structure) => {
        const newCalendar = {
          id_anno: year.id,
          id_struttura: structure.id,
          descrizione: `Calendario standard ${year.anno}`,
          note: null,
          standard: true
        }
        axiosArray.push(this.postNewCalendar(newCalendar))
      })
    },
    postNewCalendar (obj) {
      return Vue.patchOrPost(false, '/calendario', obj).then(() => {
        return true
      }).catch(error => {
        Vue.manageErrors(this, error)
        return true
      })
    },
    exitCheck (itemChanged) {
      if (itemChanged) this.$root.$emit('exit-check', { redirect: this.backPath })
      else this.$router.push(this.backPath)
    },
    save (pushBack) {
      this.input.anno = parseInt(this.input.anno, 10)
      Vue.patchOrPost(this.schoolYearId !== 'new',
        `/anno_scolastico${this.schoolYearId !== 'new' ? '/' + this.schoolYearId : ''}`, this.input).then(async r => {
        await this.updateAllCalendars(r.data)
        Vue.success(this, `${this.$tc('message.schoolYear', 1)} ${this.$t(this.schoolYearId !== 'new' ? 'success.putSuccess' : 'success.postSuccess')}`)
        if (pushBack) this.$router.push(this.backPath)
        // eslint-disable-next-line standard/object-curly-even-spacing
        else this.$router.push({ name: 'RefreshPage', params: { id: this.input.id || r.data.id, name: 'EditSchoolYear' } })
      }).catch(errorObj => {
         Vue.manageErrors(this, errorObj)
      })
    }
  }
}
</script>
