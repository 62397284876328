<template>
  <div class="page_container">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">

    <div class="main_page_container">
      <Header class="header"/>

      <MainContent class="content"/>
    </div>

    <Footer class="footer"/>

    <b-modal ref="mainErrorMessageModal" id="mainErrorMessageModal" :title="`${$t('message.caution')}!`"
             no-close-on-backdrop no-close-on-esc hide-header-close
             @hide="checkCloseCloseMessageModal">
      <b-container fluid>
        <h5> {{message}}!</h5>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button id="nw-ok-error-message" class="m-1 b-c-green" @click="hideMainErrorMessageModal('close')">Ok</b-button>
      </div>
    </b-modal>

    <b-modal ref="deleteModal" id="deleteModal" :title="`${$t('message.caution')}!`">
      <b-container fluid>
        <h5>{{$t('message.deleteCheck')}} {{deleteItem.description}}?</h5>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button id="nw-delete-close" class="m-1 b-c-green" @click="hideDeleteModal()">{{$t('message.cancel')}}</b-button>

        <b-button id="nw-delete-continue" class="m-1 b-c-green" @click="deleteContinue(deleteItem.path)">{{$t('message.continue')}}</b-button>
      </div>
    </b-modal>

    <b-modal ref="infoModal" id="infoModal" :title="`${$t('message.info')}`"
             no-close-on-backdrop no-close-on-esc hide-header-close>
      <b-container fluid>
        <h5>{{infoMessage}}</h5>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button class="b-c-green" @click="$refs.infoModal.hide()"> Ok </b-button>
      </div>
    </b-modal>

    <b-modal ref="exitCheckModal" id="exitCheckModal" :title="`${$t('message.caution')}!`"
             no-close-on-backdrop no-close-on-esc hide-header-close>
      <b-container fluid>
        <h5>{{$tc('warnings.exitCheck')}}</h5>
      </b-container>

      <div slot="modal-footer" class="w-100 justify-content-end d-flex">
        <b-button class="b-c-green m-1" id="exit-continue" @click="exitCheckContinue(exitCheck)"> {{$tc('message.continue', 1)}} </b-button>

        <b-button class="b-c-green m-1" @click="exitCheckGoBack(exitCheck)"> {{$tc('message.remain', 1)}} </b-button>
      </div>
    </b-modal>

    <!-- Loader -->
    <div class="loader-opacity" v-if="loaderActive"></div>

    <div class="loader-container" v-if="loaderActive">
      <moon-loader :color="'#007A79'"/>
      <div class="loader-label mt-2"></div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import Header from './fragments/Header'
  import MainContent from './fragments/MainContent'
  import Footer from './fragments/Footer'
  import { MoonLoader } from 'vue-spinner/dist/vue-spinner'

  export default {
    components: {
      Footer,
      MainContent,
      Header,
      MoonLoader
    },
    name: 'app',
    props: ['idle'],
    watch: {
      idle (isIdle) {
        const tabs = Vue.getLocalStorage('tabs')
        let inactive = Vue.getLocalStorage('inactive')
        const session = Vue.getLocalStorage('session')
        const token = session && session.token
        const readExpulsionMessage = Vue.getLocalStorage('readExpulsionMessage')
        const isExpelled = Vue.getLocalStorage('isExpelled')
        const message = this.$t('message.sessionExpired')
        if (isIdle) {
          inactive += 1
          Vue.setLocalStorage('inactive', inactive)
        }
        if (!isIdle) {
          if (inactive && inactive >= 1) {
            inactive -= 1
            Vue.setLocalStorage('inactive', inactive)
          } else if (inactive && inactive < 1) {
            Vue.setLocalStorage('inactive', 0)
          }
        }
        if (isIdle && token && tabs && inactive && tabs === inactive) {
          this.$root.$emit('sessionExpired', message)
        }
        if (isExpelled && !token && readExpulsionMessage) {
          this.hideMainErrorMessageModal('close')
        } else if (isExpelled && !token && !readExpulsionMessage) {
          this.$root.$emit('sessionExpired', message)
        }
      }
    },
    created () {
      this.checkDevice()
    },
    data () {
      return {
        message: '',
        constraint: '',
        objName: '',
        route: {},
        deleteItem: {},
        modalObj: {
          modalItSelf: '',
          modalBeforeItSelf: ''
        },
        disableExcel: false,
        loaderActive: false,
        infoMessage: '',
        exitCheck: {}
      }
    },
    beforeMount () {
      this.checkDevice()
    },
    mounted () {
      this.$root.$on('sessionExpired', (message) => {
        const refs = this.$refs
        if (refs && refs.mainErrorMessageModal) {
          refs.mainErrorMessageModal.show()
          this.message = ''
          this.message = message
        }
        Vue.deleteLocalStorage('readExpulsionMessage')
        Vue.deleteLocalStorage('session')
        Vue.setLocalStorage('isExpelled', true)
        this.$router.push('/').catch(() => {})
      })
      this.$root.$on('mustBeDisconnected', (message) => {
        Vue.error(this, message)
        this.logout()
      })
      this.$root.$on('showErrorMessage', (obj) => {
        const message = obj && obj.message
        const mustBeExpelled = obj && obj.mustBeExpelled
        const refs = this.$refs
        if (refs && refs.mainErrorMessageModal) {
          refs.mainErrorMessageModal.show()
          this.message = ''
          this.message = message
        }
        if (mustBeExpelled) {
          Vue.deleteLocalStorage('session')
          Vue.setLocalStorage('isExpelled', true)
          this.$router.push('/').catch(() => {})
        }
      })
      this.$root.$on('deleteItem', (obj) => {
        this.deleteItem = obj
        if (this.$refs && this.$refs.deleteModal) {
          this.$refs.deleteModal.show()
        }
      })
      this.$root.$on('activeLoader', (active) => {
        this.loaderActive = active
      })
      this.$root.$on('info-modal', (infoMessage) => {
        this.infoMessage = infoMessage
        if (this.$refs.infoModal) {
          this.$refs.infoModal.show()
        }
      })
      this.$root.$on('exit-check', (checkObj) => {
        this.exitCheck = checkObj
        if (this.$refs.exitCheckModal) {
          this.$refs.exitCheckModal.show()
        }
      })
      window.onfocus = () => {
        const session = Vue.getLocalStorage('session')
        const token = session && session.token
        const readExpulsionMessage = Vue.getLocalStorage('readExpulsionMessage')
        const isExpelled = Vue.getLocalStorage('isExpelled')
        if (isExpelled && !token && readExpulsionMessage) {
          this.hideMainErrorMessageModal('close')
        } else if (isExpelled && !token && !readExpulsionMessage) {
          const message = 'La sessione risulta inattiva da più di 15 minuti, eseguire nuovamente l\'accesso'
          this.$root.$emit('sessionExpired', message)
        }
      }
      window.onresize = () => {
        this.$root.$emit('resize')
      }
      window.onscroll = () => {
        this.$root.$emit('scroll')
      }
    },
    beforeDestroy () {
      window.onfocus = null
      window.onresize = null
      window.onscroll = null
    },
    methods: {
      checkDevice () {
        if (navigator.userAgent.match(/iPhone/i) ||
                navigator.userAgent.match(/iPad/i) ||
                navigator.userAgent.match(/iPod/i)) {
          this.disableExcel = true
        } else if (navigator &&
                navigator.userAgent &&
                !navigator.userAgent.match(/iPhone/i) &&
                !navigator.userAgent.match(/iPad/i) &&
                !navigator.userAgent.match(/iPod/i)) {
          this.disableExcel = false
        }
        this.checkExcel()
      },
      checkExcel () {
        let excelButton = document.getElementById('excel-button')
        if (this.disableExcel && excelButton) {
          excelButton.style.display = 'none'
        } else if (excelButton) {
          excelButton.style.display = 'block'
        }
      },
      hideMainErrorMessageModal (exception) {
        const isExpelled = Vue.getLocalStorage('isExpelled')
        if (isExpelled) {
          Vue.deleteLocalStorage('readExpulsionMessage')
          Vue.setLocalStorage('readExpulsionMessage', true)
        }
        if (exception === 'close') {
          this.$refs.mainErrorMessageModal.hide('button')
          this.message = ''
          this.constraint = ''
          this.objName = ''
        } else if (exception.cancelable) {
          exception.preventDefault()
        }
      },
      checkCloseCloseMessageModal (obj) {
        if (obj.trigger !== 'button') {
          obj.preventDefault()
        }
      },
      deleteContinue (path) {
        Vue.myDelete(path).then(() => {
          Vue.success(this, `${this.$tc('message.delete')} ${this.$tc('message.done', 2).toLowerCase()}`)
          const deleteParams = this.deleteItem
          if (deleteParams) this.$router.push({ name: 'RefreshPage', params: deleteParams }).catch(() => {})
          this.hideDeleteModal()
        }).catch(error => {
          if (path.toString().includes('/bus/gita/') === true) {
            Vue.manageErrors(this, error, null, 'Rimuovere tutte le fermate per poter eliminare la gita')
            this.hideDeleteModal()
          } else {
            Vue.manageErrors(this, error)
            this.hideDeleteModal()
          }
        })
      },
      hideDeleteModal () {
        this.$refs.deleteModal.hide()
        this.deleteItem = {}
      },
      logout () {
        Vue.deleteLocalStorage('session')
        this.$router.push('/').catch(() => {})
      },
      exitCheckContinue (obj) {
        if (obj.modal) obj.modal.hide()
        if (obj.redirect) this.$router.push(obj.redirect)
        if (this.$refs.exitCheckModal) {
          this.$refs.exitCheckModal.hide()
        }
      },
      exitCheckGoBack (obj) {
        if (this.$refs.exitCheckModal) {
          this.$refs.exitCheckModal.hide()
        }
        if (obj.modal) obj.modal.show()
      }
    }
  }
</script>

<style>
  /* main components */
  @font-face {
    font-family: standardFont;
    src: url(../public/LilyScriptOne-Regular.ttf);
  }
  .footer h5, .footer h4, .header, #my-breadcrumb, .my-modal-title {
    font-family: 'standardFont', Fallback, sans-serif !important;
  }
  input[type="date"], .flatpickr-mobile {
    display: flex !important;
    flex-grow: 1 !important;
    background-color: white !important;
    border: 1px solid rgba(0, 0, 0, .125) !important;
  }
  .my-modal-title {
    color: #007A79 !important;
    margin: 0 !important;
  }
  #my-breadcrumb {
    font-size: 18px !important;
  }
  html, body {
    height: 100%;
  }
  .login-image {
    height: 100% !important;
    width: 100% !important;
    background-color: #cccccc !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative !important;
  }
  .bbbus-icon {
    background: url("../public/img/icons/bbbus-icon.svg");
    height: 1.75rem;
    width: 1.75rem;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    margin: 0!important;
  }
  #my-collapse {
    margin-bottom: 1rem !important;
  }
  .input-group-append > .fa-trash {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
  }
  .input-group > input[readonly="readonly"] {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
  }
  .vs__selected {
    color: #495057 !important;
  }
  .vs__selected-options {
    overflow: hidden !important;
  }
  .vs__dropdown-toggle {
    background-color: #fff !important;
  }
  .vs__search, .vs__search:focus {
    --vs-search-input-placeholder-color: gray;
  }
  ul.vs__dropdown-menu {
    max-height: 150px !important;
  }
  .my-v-select > div > div > .vs__dropdown-toggle {
    border: none !important;
    margin-top: 4px !important;
    margin-bottom: 0 !important;
  }
  .modal-body > .container > .row > div > div {
    width:100%;
    display:flex;
    flex-direction:row;
    flex-wrap:nowrap;
    border-top: 1px solid #dee2e6;
  }
  .modal-body > .container > .row:nth-child(1) > div:nth-child(1) > div {
    border-top: 0 !important;
  }
  #deleteModal > .modal-dialog {
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-width: 40%;
  }
  .modal-body > .container > .row > div > div > h6:nth-child(1)  {
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-width: 40%;
    max-width: 40%;
  }
  .modal-body > .container > .row > div > div > h6:nth-child(2)  {
    word-wrap: break-word;
    overflow-wrap: break-word;
    min-width: 40%;
    max-width: 55%;
  }
  .table.b-table.b-table-stacked-md > tbody > tr > [data-label] > div {
    max-width: calc(25px + 7 * ((60vw - 70px) / 10)) !important;
    word-wrap: break-word;
    overflow-wrap: break-word;
  }
  .modal-body > .container > .row > div > div > h6 {
    padding-top: .5rem;
  }
  .col-form-label {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .form-group {
    margin-bottom: 0.5rem !important;
  }
  .my-invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
  }
  .page_container{
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .header {
    background-color: rgba(0, 122, 121, 0.8);
    box-shadow: 0 1px 10px rgba(0,0,0,.1);
  }
  .content {
    display: flex;
    flex: 1 1 auto;
  }
  .footer {
    background-color: rgba(0, 122, 121, 0.8);
    color: azure;
  }
  .main_page_container {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    max-width: 100%;
  }
  .show {
    align-items: center;
    display: flex;
  }
  .modal-header {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .modal-footer {
    padding: 0.5rem 0.5rem !important;
  }
  .modal-dialog {
    width: 100%;
    margin: 0 !important;
    margin-right: auto !important;
    margin-left: auto !important;
    padding: .5rem !important;
  }
  .lang-it > label::after {
    background-color: #007A79;
    color: white;
    content: "Cerca";
  }
  .lang-en label::after {
    background-color: #007a79;
    color: white;
    content: "Browse";
  }
  .my-label {
    font-variant: small-caps;
    font-size: 14px
  }

  .br-tr {
    border-top-right-radius: 0.25rem !important;
  }
  .br-br {
    border-bottom-right-radius: 0.25rem !important;
  }
  .br-tl {
    border-top-left-radius: 0.25rem !important;
  }
  .br-bl {
    border-bottom-left-radius: 0.25rem !important;
  }

  .my-border-top {
    border-top: 1px solid #dee2e6 !important;
    padding-top: 0.5rem !important;
    margin-top: 1rem !important;
  }

  /* Main colors */
  .b-c-green {
    background-color: #007A79 !important;
  }
  .c-green {
    color: #007A79 !important;
  }
  .border-c-green {
    border-color: #007A79 !important;
  }
  .h-c-green:hover {
    color: #007A79;
  }
  .b-c-green-opacity {
    background-color: rgba(0, 122, 121, 0.8) !important;
  }
  .c-green-opacity {
    color: rgba(0, 122, 121, 0.8) !important;
  }

  .b-c-yellow {
    background-color: #FAB034 !important;
  }
  .c-yellow {
    color: #FAB034 !important;
  }

  .b-c-yellow-opacity {
    background-color: rgba(250, 176, 52, 0.8) !important;
  }
  .c-yellow-opacity {
    color: rgba(250, 176, 52, 0.8) !important;
  }
  .border-c-yellow {
    border-color: #FAB034 !important;
  }
  .h-c-yellow:hover {
    color: #FAB034;
  }
  .c-yellow-opacity {
    background-color: rgba(245, 245, 0, 0.6) !important;
  }

  .b-c-red {
    background-color: #dc3545 !important;
  }
  .c-red {
    color: #dc3545 !important;
  }

  .b-c-blue {
    background-color: #4285f4 !important;
  }
  .c-blue {
    color: #4285f4 !important;
  }
  .h-b-c-blue:hover {
    background-color: #4285f4 !important;
  }
  .h-c-blue:hover {
    color: #4285f4 !important;
  }

  .b-c-azure {
    background-color: #60B0F9 !important;
  }
  .c-azure {
    color: #60B0F9 !important;
  }
  .h-b-c-azure:hover {
    background-color: #60B0F9 !important;
  }
  .h-c-azure:hover {
    color: #60B0F9 !important;
  }

  .b-c-black {
    background-color: #1e1e21;
  }
  .c-black {
    color: #1e1e21;
  }
  .h-b-c-black:hover {
    background-color: #1e1e21;
  }
  .c-gray {
    color: #2a2a2e;
  }
  .b-c-gray {
    background-color: #2a2a2e;
  }
  .c-m-gray {
    color: #36363b;
  }
  .b-c-m-gray {
    background-color: #36363b;
  }
  .c-ls-gray {
    color: lightslategray;
  }
  .b-c-ls-gray {
    background-color: lightslategray;
  }
  .c-l-gray {
    color: #adb5bd;
  }
  .b-c-l-gray {
    background-color: #adb5bd !important;
  }
  .c-s-white {
    color: #e6e6e6;
  }
  .b-c-s-white {
    background-color: #e6e6e6;
  }
  .c-white {
    color: white !important;
  }

  /* Plugin components */
  .breadcrumb {
    margin-bottom: 0 !important;
  }
  .v-sidebar-menu {
    height: auto !important;
    display: flex;
    flex-direction: column;
  }
  .v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1,
  .v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon,
  .vsm--icon {
    background-color: #007a79 !important;
  }
  .v-sidebar-menu .vsm--mobile-bg, .vsm--link_level-1:hover .vsm--icon, .vsm--icon:hover {
    background-color: #007a79 !important;
  }

  .v-sidebar-menu .vsm--header {
    text-transform: none !important;
    font-size: 22px !important;
  }
  .vsm--link_active {
    font-weight: 200 !important;
  }

  .vsm--header {
    color: #FAB034 !important;
  }

  .v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active, .v-sidebar-menu .vsm--link_level-1.vsm--link_active {
    -webkit-box-shadow: 3px 0 0 0 #007a79 inset !important;
    box-shadow: 3px 0 0 0 #007a79 inset !important;
  }
  .collapse-btn {
    order: 1 !important;
    position: relative !important;
  }
  .vsm-list, .vsm--list {
    order: 2 !important;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }
  .collapse-btn:after {
    content: "\f0c9" !important;
  }
  .vsm-collapsed > div:nth-child(2) {
    width: 250px !important;
  }
  .vsm-dropdown {
    overflow-x: hidden !important;
    max-height: calc(16px + 7 * ((60vw - 70px) / 10)) !important
  }
  .v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1,
  .v-sidebar-menu.vsm_expanded .vsm--item_open .vsm--link_level-1 .vsm--icon {
    background-color: #007a79 !important;
  }
  .v-sidebar-menu .vsm--mobile-bg, .vsm--link_level-1:hover .vsm--icon, .vsm--icon:hover  {
    background-color: #007a79 !important;
  }

  .v-sidebar-menu .vsm--link_level-1.vsm--link_exact-active, .v-sidebar-menu .vsm--link_level-1.vsm--link_active {
    -webkit-box-shadow: 3px 0 0 0 #007a79 inset !important;
    box-shadow: 3px 0 0 0 #007a79 inset !important;
  }

  .custom-file-label::after {
    font-family: "Font Awesome 5 Free",serif; font-weight: 900; content: "\f002" !important;
  }

  .loader-opacity {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1100;
    opacity: .75;
    background-color: black;
  }

  .loader-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 1150;
    overflow: hidden;
    outline: 0;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .loader-label {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    text-align: center;
    font-size: calc(20px + 6 * ((100vw - 150px) / 500)) !important;
    font-family: 'ArchitectsDaughter',serif;
  }

  /* Table style */
  .first-table-cols > th:nth-child(1),
  .first-2-table-cols > th:nth-child(1),
  .first-2-table-cols > th:nth-child(2),
  .first-3-table-cols > th:nth-child(1),
  .first-3-table-cols > th:nth-child(2),
  .first-3-table-cols > th:nth-child(3),
  .first-4-table-cols > th:nth-child(1),
  .first-4-table-cols > th:nth-child(2),
  .first-4-table-cols > th:nth-child(3) {
    width: 60px !important;
  }

  .first-4-table-cols > th:nth-child(4) {
    width: 100px !important;
  }
  .stackedStyle > tbody > tr {
    border-top: 1px solid #dee2e6;
    font-size: 14px;
  }
  .stackedStyle > tbody > tr > td {
    padding: 3px;
    border-top: 0;
  }
  .stackedStyle > tbody > tr > td > div {
    overflow: auto;
  }
  .stackedStyleCols > tbody > tr > [data-label] > div {
    max-width: 50vw !important;
  }
  .stackedStyle > tbody > tr > td > div > button {
    line-height: 15px;
    padding: 5px;
    margin: 0 5px 0 0;
    float: right;
  }
  .stackedStyle > tbody > tr > td > div > div > .custom-control-label::before,
  .stackedStyle > tbody > tr > td > div > div > .custom-control-label::after,
  .stackedStyle > .custom-control-label:after,
  .stackedStyle > .custom-control-label:before {
    width: 27px;
    height: 27px;
    top: 0;
  }
  .notStackedStyle > tbody > tr > .flexible-row > div {
    display: block !important;
  }
  .page-item.active .page-link {
    background-color: #007a79 !important;
    border-color: #007a79 !important;
  }
  .page-link {
    color: #212529 !important;
  }
  .page-item.active > a {
    color: white !important;
  }
  /* main style */
  .mw-100 {
    max-width: 100% !important;
  }
  .custom-control-input:checked~.custom-control-label::before {

    border-color: #007A79 !important;
    background-color: #007A79 !important;
  }
  .BBB-link, .nav-link, a {
    color: #007A79;
  }
  .BBB-link:hover {
    color: #FAB034 !important;
    font-weight: bold !important;
  }
  .form-control[disabled='tue'] {
    background-color: #6c757d !important;
    cursor: not-allowed;
  }
  .form-control[disabled='false'] {
    background-color: white !important;
  }
  .nav-link:hover, a:hover {
    color: #FAB034 !important;
  }
  .breadcrumb-item > a[href='#'] {
    color: #6c757d !important;
    text-decoration: none;
  }
  .breadcrumb-item > a {
    color: #007A79 !important;
    text-decoration: none;
  }
  .breadcrumb-item > a[href='#']:hover, .breadcrumb-item > span {
    cursor: not-allowed;
  }
  .flexible-row {
    display: flex !important;
  }
  .flexible-row::before {
    display: none !important;
  }
  .flexible-row > div {
    display: flex !important;
    width: 100%;
  }
  .hover-enabled, .vue-slider {
    cursor: pointer;
  }
  .hover-not-allowed {
    cursor: not-allowed;
  }
  .underline:hover {
    text-decoration: underline !important;
  }
  .dropdown-menu {
    min-width: 0 !important;
  }
  .tinyCard {
    display: flex;
    justify-content: center;
    max-width: 700px;
    margin: auto;
  }
  .tiny-label {
    margin: 0;
    font-size: medium;
  }
  .tab-content {
    margin: 15px;
  }
  .form-control[readonly] {
    background-color: white !important;
  }
  .form-control:disabled {
    background-color: #e9ecef !important;
    cursor: not-allowed;
  }
  .ellipse > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .disabled {
    cursor: not-allowed !important;
  }

  .my-invalid-feedback {
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
  }

  .form-control::-webkit-input-placeholder { color: lightgray !important; }  /* WebKit, Blink, Edge */
  .form-control:-moz-placeholder { color: lightgray !important; }  /* Mozilla Firefox 4 to 18 */
  .form-control::-moz-placeholder { color: lightgray !important; }  /* Mozilla Firefox 19+ */
  .form-control:-ms-input-placeholder { color: lightgray !important; }  /* Internet Explorer 10-11 */
  .form-control::-ms-input-placeholder { color: lightgray !important; }

  /* Transitions */
  .slide-right-enter-active {
    transition: all .3s ease;
  }
  .slide-right-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-right-enter {
    transform: translateX(-10px);
    opacity: 0;
  }
  .slide-right-leave-to {
    transform: translateX(10px);
    opacity: 0;
  }
  .slide-left-enter-active {
    transition: all .3s ease;
  }
  .slide-left-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-left-enter {
    transform: translateX(10px);
    opacity: 0;
  }
  .slide-left-leave-to {
    transform: translateX(-10px);
    opacity: 0;
  }
  .flip-list-move {
    transition: transform 1.5s;
  }
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
    position: absolute !important;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .fade-down-enter-active {
    transition: all .5s ease;
  }
  .fade-down-leave-active {
    transition: all .5s
  }
  .fade-down-enter {
    transform: translateY(-30px);
    opacity: 0;
  }
  .fade-down-leave-to {
    transform: translateY(30px);
    opacity: 0;
  }
  .fade-up-enter-active {
    transition: all .5s ease;
  }
  .fade-up-leave-active {
    transition: all .5s revert;
  }
  .fade-up-enter {
    transform: translateY(30px);
    opacity: 0;
  }
  .fade-up-leave-to {
    transform: translateY(-30px);
    opacity: 0;
  }
</style>
